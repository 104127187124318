var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{staticClass:"fields",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`flight_${_vm.flight.id}_total_price`)
              ? 'filled'
              : 'filled'},[_vm._v("\n          Valor total do aéreo (R$)\n        ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `flight_${_vm.flight.id}_total_price`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `flight_${flight.id}_total_price`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor total","disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":18}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`flight_${_vm.flight.id}_total_price_written`)
              ? 'filled'
              : 'filled'},[_vm._v("\n          Valor total por extenso\n        ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`flight_${_vm.flight.id}_total_price_written`]),expression:"[`flight_${flight.id}_total_price_written`]"}],staticClass:"travel-input upper",attrs:{"placeholder":"Valor total por extenso","disabled":_vm.isSale}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"flight-table",attrs:{"span":24}},[_c('a-row',[_c('a-col',{staticClass:"line",staticStyle:{"padding":"0","border":"0"},attrs:{"span":14}},[_c('a-row',{staticClass:"head"},[_c('a-col',{attrs:{"span":9}},[_vm._v("Pax ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Milhas ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Ticket ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Taxas ")])],1),_vm._v(" "),_c('a-row',[_c('a-col',{attrs:{"span":9}},[_vm._v(_vm._s(_vm.flightTravellers(_vm.flight.id).adults.qtd)+"x Adulto(s)")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n              "+_vm._s(_vm.flightTravellers(_vm.flight.id).adults.totalMiles)+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n              "+_vm._s(_vm.formatPricePtBr(
                  _vm.flightTravellers(_vm.flight.id).adults.totalTicket
                ))+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n              "+_vm._s(_vm.formatPricePtBr(_vm.flightTravellers(_vm.flight.id).adults.totalTaxes))+"\n            ")])],1),_vm._v(" "),(_vm.flightTravellers(_vm.flight.id).children.qtd > 0)?_c('a-row',{staticClass:"line"},[_c('a-col',{attrs:{"span":9}},[_vm._v(_vm._s(_vm.flightTravellers(_vm.flight.id).children.qtd)+"x\n              Criança(s)")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v("\n              "+_vm._s(_vm.flightTravellers(_vm.flight.id).children.totalMiles)+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(_vm._s(_vm.formatPricePtBr(
                  _vm.flightTravellers(_vm.flight.id).children.totalTicket
                ))+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(_vm._s(_vm.formatPricePtBr(
                  _vm.flightTravellers(_vm.flight.id).children.totalTaxes
                ))+"\n            ")])],1):_vm._e()],1),_vm._v(" "),_c('a-col',{staticClass:"line",staticStyle:{"padding":"0","border":"0"},attrs:{"span":10}},[_c('a-row',{staticClass:"head"},[_c('a-col',{attrs:{"span":6}},[_vm._v(" Custo ")]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_vm._v(" Fornecedores ")]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_vm._v(" Pedidos ")])],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('b',{class:_vm.tempContract[
                  `contract_finances_flight_${_vm.flight.id}_sale_value`
                ] !=
                _vm.sale.contract.meta[
                  `contract_finances_flight_${_vm.flight.id}_sale_value`
                ]
                  ? 'corange'
                  : ''},[_vm._v("\n              "+_vm._s(_vm.formatPricePtBr(
                  _vm.tempContract[
                    `contract_finances_flight_${_vm.flight.id}_sale_value`
                  ]
                ))+"\n            ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_vm._v("\n            "+_vm._s(_vm.tempContract[`contract_finances_flight_${_vm.flight.id}_suppliers`])+"\n          ")]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_vm._v("\n            "+_vm._s(_vm.tempContract[
                `contract_finances_flight_${_vm.flight.id}_miles_order_ids`
              ])+"\n          ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }