<template>
    <div>Configs</div>
</template>

<script>
export default {
    props: {
        tempContract: Object,
        sale: Object,
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped></style>
