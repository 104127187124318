<template>
  <div>
    <a-row style="padding-right: 10px">
      <a-col
        v-for="(item, index) in theList()"
        :key="index"
        class="payment-row"
        :span="24"
      >
        <div @click="activeAccordeon(index)">
          <a-row
            class="head"
            :class="
              (tempContract[
                `contract_finances_hotel_${item.id}_audit_status`
              ] == 'Realizado' &&
                item.type == 'hotel') ||
              (tempContract[
                `contract_finances_service_${item.id}_audit_status`
              ] == 'Realizado' &&
                item.type == 'service') ||
              (tempContract[
                `contract_finances_flight_${item.id}_audit_status`
              ] == 'Realizado' &&
                item.type == 'flight') ||
              (tempContract[`contract_finances_package_audit_status`] ==
                'Realizado' &&
                item.type == 'package')
                ? 'done'
                : 'edit'
            "
            type="flex"
            justify="space-between"
          >
            <a-col
              >{{ index + 1 }} - {{ item.name }}
              {{ item.id ? "#" + item.id : "" }}
            </a-col>
            <a-col>
              <img
                v-if="
                  (tempContract[
                    `contract_finances_hotel_${item.id}_audit_status`
                  ] == 'Realizado' &&
                    item.type == 'hotel') ||
                    (tempContract[
                      `contract_finances_service_${item.id}_audit_status`
                    ] == 'Realizado' &&
                      item.type == 'service') ||
                    (tempContract[
                      `contract_finances_flight_${item.id}_audit_status`
                    ] == 'Realizado' &&
                      item.type == 'flight') ||
                    (tempContract[`contract_finances_package_audit_status`] ==
                      'Realizado' &&
                      item.type == 'package')
                "
                src="@/assets/images/dashboard/sales/check.png"
                alt="check"
                width="16"
              />

              <a-icon
                class="ml-10 relative"
                type="caret-down"
                style="top: 3px"
              />
            </a-col>
          </a-row>
        </div>
        <div v-if="active == index">
          <div
            v-if="
              (item.type == 'hotel' &&
                tempContract.package_type == 'Serviços') ||
                (item.type == 'hotel' &&
                  tempContract.package_type == 'Reserva Online')
            "
            style="padding: 20px 20px 0"
          >
            <HotelPaymentDetails
              :id="item.id"
              :tempContract="tempContract"
              :sale="sale"
              :form="form"
              :index="index"
              @updateTempContract="updateTempContract"
            />
          </div>

          <div
            v-if="
              (item.type == 'flight' &&
                tempContract.package_type == 'Serviços') ||
                (item.type == 'flight' &&
                  tempContract.package_type == 'Reserva Online')
            "
            style="padding: 20px 20px 0"
          >
            <FlightPaymentDetails
              :id="item.id"
              :flight="item.data"
              :tempContract="tempContract"
              :sale="sale"
              :form="form"
              :index="index"
              @updateTempContract="updateTempContract"
            />
          </div>

          <div
            v-if="
              (item.type == 'service' &&
                tempContract.package_type == 'Serviços') ||
                (item.type == 'service' &&
                  tempContract.package_type == 'Reserva Online')
            "
            style="padding: 20px 20px 0"
          >
            <ServicePaymentDetails
              :id="item.id"
              :index="index"
              :tempContract="tempContract"
              :sale="sale"
              :form="form"
              @updateTempContract="updateTempContract"
            />
          </div>

          <div
            v-if="tempContract.package_type == 'Pacote Completo Terceiros'"
            style="padding: 20px 20px 0"
          >
            <ThirdPartyPackagePaymentDetails
              :tempContract="tempContract"
              :sale="sale"
              :form="form"
              @updateTempContract="updateTempContract"
            />
          </div>
        </div>
      </a-col>

      <a-col
        v-if="milesTaxesPayments().show"
        class="payment-row mb-20"
        :span="24"
      >
        <a-row>
          <a-col
            class="head"
            :class="
              tempContract[
                `contract_finances_flight_miles_taxes_audit_status`
              ] == 'Realizado'
                ? 'done'
                : 'edit'
            "
            @click="activeAccordeon(999)"
            :span="24"
          >
            <a-row type="flex" justify="space-between">
              <a-col>
                <a-row type="flex" justify="start" :gutter="40">
                  <a-col><a-icon type="dollar" /> Taxas</a-col>
                  <a-col>
                    Valor:
                    {{
                      formatPriceCurrency(
                        milesTaxesPayments().totalTaxesWithCurrency,
                        milesTaxesPayments().currency
                      )
                    }}
                  </a-col>

                  <a-col>
                    Câmbio utilizado:
                    {{
                      formatAnyPricePtBr(tempContract.voucher_payments_exchange)
                    }}</a-col
                  >
                </a-row>
              </a-col>

              <a-col>
                <img
                  v-if="
                    tempContract[
                      `contract_finances_flight_miles_taxes_audit_status`
                    ] == 'Realizado'
                  "
                  src="@/assets/images/dashboard/sales/check.png"
                  alt="check"
                  width="16"/>

                <a-icon
                  class="ml-10 relative"
                  type="caret-down"
                  style="top: 3px"
              /></a-col>
            </a-row>
          </a-col>
          <a-col v-if="active == 999" :span="24">
            <MilesFlightTaxesPaymentDetails
              :index="active"
              :tempContract="tempContract"
              :sale="sale"
              :milesTaxesPayments="milesTaxesPayments()"
              @updateTempContract="updateTempContract"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import HotelPaymentDetails from "../collapse/HotelPaymentDetails.vue";
import FlightPaymentDetails from "../collapse/FlightPaymentDetails.vue";
import ServicePaymentDetails from "../collapse/ServicePaymentDetails.vue";
import ThirdPartyPackagePaymentDetails from "../collapse/ThirdPartyPackagePaymentDetails.vue";
import MilesFlightTaxesPaymentDetails from "../collapse/MilesFlightTaxesPaymentDetails.vue";

export default {
  components: {
    HotelPaymentDetails,
    FlightPaymentDetails,
    ServicePaymentDetails,
    ThirdPartyPackagePaymentDetails,
    MilesFlightTaxesPaymentDetails,
  },
  props: {
    tempContract: Object,
    sale: Object,
    form: Object,
  },
  mixins: [formatThings, cardMachinesMixins, productSupplierMixins],
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    if (this.tempContract.sale_to_pay_active_accordeon != undefined) {
      this.active = parseInt(this.tempContract.sale_to_pay_active_accordeon);
    }
  },
  methods: {
    updateTempContract(newTempContract) {
      this.tempContract = newTempContract;
      setTimeout(() => {
        this.$emit("updateTempContract", newTempContract);
      }, 20);
    },
    activeAccordeon(current) {
      if (current == this.active) {
        this.active = -1;
      } else {
        this.active = current;
      }
    },
    milesTaxesPayments() {
      let flag = false,
        totalTaxes = parseFloat(0),
        totalTaxesWithCurrency = parseFloat(0),
        currency = "BRL";

      if (this.tempContract["flight_sections_rows"] != undefined) {
        JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
          (flight) => {
            if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
              flight.travellers.forEach((traveller) => {
                let taxCurrency = this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                  ],
                  taxes = this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ];

                if (taxCurrency != undefined) {
                  currency = taxCurrency;
                }

                if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
                  if (currency == "BRL") {
                    if (taxes) {
                      taxes = taxes.replace(",", ".");

                      if (isNaN(taxes)) {
                        totalTaxesWithCurrency += parseFloat(0);
                      } else {
                        totalTaxesWithCurrency += parseFloat(taxes);
                      }
                    } else {
                      totalTaxesWithCurrency += parseFloat(0);
                    }
                  } else {
                    if (taxes) {
                      taxes = taxes.replace(",", ".");
                      if (isNaN(taxes)) {
                        totalTaxesWithCurrency += parseFloat(0);
                      } else {
                        totalTaxesWithCurrency += parseFloat(taxes);
                      }
                    } else {
                      totalTaxesWithCurrency += parseFloat(0);
                    }
                  }
                } else {
                  if (taxes) {
                    taxes = taxes.replace(",", ".");
                    if (isNaN(taxes)) {
                      totalTaxes += parseFloat(0);
                    } else {
                      totalTaxes += parseFloat(taxes);
                    }
                  } else {
                    totalTaxes += parseFloat(0);
                  }
                }

                if (taxCurrency && taxes && taxCurrency != "" && taxes != "") {
                  flag = true;
                }
              });
            }
          }
        );
      }

      return {
        show: flag,
        totalTaxes: totalTaxes,
        totalTaxesWithCurrency,
        currency: currency,
      };
    },
    theList() {
      let contractedServices =
          this.tempContract.contracted_services != undefined
            ? JSON.parse(this.tempContract.contracted_services)
            : [],
        arr = [],
        packageType = this.tempContract.package_type;

      if (packageType == "Serviços" || packageType == "Reserva Online") {
        if (contractedServices.includes("hotel")) {
          JSON.parse(this.tempContract["hotel_rows"]).forEach((hotel) => {
            arr.push({
              id: hotel.id,
              name: this.tempContract[`hotel_${hotel.id}_name`],
              type: "hotel",
            });
          });
        }

        if (contractedServices.includes("flight")) {
          JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
            (flight) => {
              let allSections = [];
              flight.sections.forEach((section) => {
                allSections.push({
                  type: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_type`
                  ],
                  origin: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_origin`
                  ],
                  destination: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_destination`
                  ],
                  class: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_class`
                  ],
                  airline: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_airline`
                  ],
                  duration: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_duration`
                  ],
                  number: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_number`
                  ],
                  stops: this.tempContract[
                    `flight_${flight.id}_section_${section.id}_stops`
                  ],
                  departure: `${this.formatMultiDates(
                    this.tempContract[
                      `flight_${flight.id}_section_${section.id}_departure_date`
                    ]
                  )} ${
                    this.tempContract[
                      `flight_${flight.id}_section_${section.id}_departure_time`
                    ]
                  }`,

                  arrival: `${this.formatMultiDates(
                    this.tempContract[
                      `flight_${flight.id}_section_${section.id}_arrival_date`
                    ]
                  )} ${
                    this.tempContract[
                      `flight_${flight.id}_section_${section.id}_arrival_time`
                    ]
                  }`,
                });
              });

              arr.push({
                id: flight.id,
                name: `PASSAGEM AÉREA ${
                  this.tempContract[`flight_${flight.id}_type`]
                }`,
                type: "flight",
                data: flight,
              });
            }
          );
        }

        if (contractedServices.includes("service")) {
          JSON.parse(this.tempContract["service_rows"]).forEach((service) => {
            arr.push({
              id: service.id,
              name: this.tempContract[`service_${service.id}_name`],
              type: "service",
            });
          });
        }
      } else {
        arr.push({
          id: "",
          name: "PACOTE COMPLETO DE TERCEIROS",
          type: "package",
        });
      }

      return arr;
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-row
  .content
    padding: 20px 20px 0
    .edit
      background: #f27935
      border-color: #f27935
      padding: 5px 10px
    .update
      background: #2ecc71
      border-color: #2ecc71
      padding: 5px 10px
  .head
    text-transform: capitalize
    background: #929496
    padding: 5px 8px
    border-radius: 5px
    margin-bottom: 5px
    color: #FFF
    text-transform: uppercase
    cursor: pointer
    &.done
      background: #2ecc71
</style>
