<template>
  <div class="payment-row">
    <a-form :form="form" @submit="submitContract" style="padding-bottom: 20px">
      <a-row>
        <a-col
          v-if="tempContract[`flight_${flight.id}_type`] == 'Regular'"
          :span="24"
        >
          <ContractFinancesRegularFlight
            :form="form"
            :flight="flight"
            :tempContract="tempContract"
            :isSaleBlockedField="true"
            :isSale="!edit"
          />
        </a-col>

        <a-col
          v-if="tempContract[`flight_${flight.id}_type`] == 'Milhas'"
          :span="24"
        >
          <ContractFinancesMilesFlight
            :form="form"
            :flight="flight"
            :tempContract="tempContract"
            :isSale="!edit"
            :sale="sale"
            @updateTempContract="updateTempContract"
            @milesOrdersList="getMilesOrdersList()"
          />

          <div class="travel-table last-itens">
            <a-table
              class="travel-table"
              :pagination="false"
              :columns="[
                {
                  title: 'Milhas',
                  dataIndex: 'miles_quantity',
                  key: 'miles_quantity',
                  width: 96,
                },
                {
                  title: 'Custo',
                  scopedSlots: { customRender: 'miles_cost' },
                  key: 'miles_cost',
                  width: 110,
                },
                {
                  title: '/1000',
                  scopedSlots: { customRender: 'per_miles' },
                  key: 'per_miles',
                  width: 90,
                },
                {
                  title: 'Valor Tkt.',
                  scopedSlots: { customRender: 'value' },
                  key: 'value',
                  width: 110,
                },

                {
                  title: 'Lucro',
                  scopedSlots: { customRender: 'profit' },
                  key: 'profit',
                  width: 115,
                  class: 'available-miles profit',
                },
                {
                  title: 'Taxa Tkt.',
                  scopedSlots: { customRender: 'tax_value' },
                  key: 'tax_value',
                  width: 100,
                },
                {
                  title: 'Pgm.',
                  scopedSlots: {
                    customRender: 'miles_program',
                  },
                  width: 90,
                },
                {
                  title: 'Cartão',
                  key: 'miles_card',
                  scopedSlots: { customRender: 'miles_card' },
                  width: 80,
                },
                {
                  title: 'Fornecedor',
                  scopedSlots: { customRender: 'supplier' },
                  width: 120,
                },
                {
                  title: '',
                  align: 'right',
                  width: 80,
                  scopedSlots: { customRender: 'action' },
                },
              ]"
              :data-source="milesOrdersList"
              :loading="loadingOrders"
            >
              <div slot="id" slot-scope="text">{{ text }}</div>

              <div slot="miles_cost" slot-scope="record">
                {{ formatPricePtBr(record.raw.miles_cost) }}
                <a-popconfirm
                  placement="bottom"
                  ok-text="Sim"
                  cancel-text="Não"
                  @confirm="updateMileCostInContract(record.raw)"
                >
                  <template slot="title">
                    <p>Tem certeza que deseja atualizar o custo no contrato?</p>
                  </template>
                  <a-tooltip
                    placement="top"
                    title="Atualizar o custo no contrato"
                  >
                    <a-icon
                      class="cgreen c-pointer f13 ml-5"
                      type="play-circle"
                    />
                  </a-tooltip>
                </a-popconfirm>
              </div>

              <div slot="per_miles" slot-scope="record">
                R$ {{ record.per_miles }}
              </div>

              <div slot="profit" slot-scope="record">
                <div v-html="formatProfit(record)" />
              </div>

              <div slot="sale" slot-scope="record">R$ {{ record.sale }}</div>

              <div slot="miles_program" slot-scope="record">
                {{ record.miles_program.name }}
              </div>
              <div slot="module" slot-scope="record">
                {{ record }}
              </div>
              <div slot="value" slot-scope="record">
                R$
                {{
                  record.flight_value == "0,00"
                    ? record.value
                    : record.flight_value
                }}
              </div>
              <div slot="tax_value" slot-scope="record">
                {{ formatCurrency(record.tax_currency) }}
                {{ record.tax_value }}
              </div>
              <div slot="user" slot-scope="record">
                {{ record.first_name }} {{ record.last_name }}
              </div>
              <div slot="miles_card" slot-scope="record">
                <div v-html="formatMilesRequest(record)"></div>
              </div>
              <div slot="supplier" slot-scope="record">
                <div class="dotted-phrase" style="width: 120px">
                  {{ record.miles_card.supplier.first_name }}
                  {{ record.miles_card.supplier.last_name }}
                </div>
              </div>
              <div slot="modified" slot-scope="record">
                {{
                  record.status == "Pendente"
                    ? "A confirmar emissão"
                    : formatDateTime(record.modified)
                }}
              </div>
              <div slot="created" slot-scope="record">
                {{ formatDateTime(record) }}
              </div>
              <span class="travel-actions" slot="action" slot-scope="record">
                <a-tooltip v-if="edit" placement="top" title="Alterar custo">
                  <a class="ml-10 cgreen" @click="openMilesCostModal(record)">
                    <a-icon type="dollar" />
                  </a>
                </a-tooltip>
              </span>
            </a-table>

            <a-modal
              class="modal-special-price"
              width="430px"
              :visible="milesCostModal"
              :footer="false"
              @cancel="milesCostModal = false"
            >
              <template slot="title">
                <a-icon type="dollar" /> Custo Milhas
              </template>

              <a-row :gutter="20">
                <a-col :span="12">
                  <label> Custo /1000</label>

                  <div class="travel-input">
                    <a-input
                      class="special-price"
                      v-currency
                      v-model="perMiles"
                      placeholder="Insira o valor do preço especial"
                    />
                  </div>
                </a-col>
                <a-col :span="12">
                  <a-popconfirm
                    placement="bottom"
                    ok-text="Sim"
                    cancel-text="Não"
                    @confirm="updateMilesCost"
                  >
                    <template slot="title">
                      <p>Tem certeza que deseja alterar o custo?</p>
                    </template>
                    <a-button
                      class="mt-20 green"
                      type="primary"
                      style="width: 100%"
                    >
                      ATUALIZAR
                    </a-button>
                  </a-popconfirm>
                </a-col>
              </a-row>
            </a-modal>
          </div>
        </a-col>

        <a-col
          class="mt-20 a-right"
          v-if="sale.status != 'Finalizado'"
          :span="24"
        >
          <a-button
            v-if="edit"
            @click="editRow(id, 'cancel')"
            class="ml-10 cancel"
            type="primary"
          >
            <a-icon type="close" />
          </a-button>

          <a-button
            v-if="!edit"
            @click="editRow(flight.id, 'edit')"
            class="ml-10 edit"
            type="primary"
          >
            <a-icon type="edit" />
          </a-button>

          <a-button
            v-if="edit"
            html-type="submit"
            class="ml-10 save"
            type="primary"
            style="padding: 0px 6px"
          >
            <img src="@/assets/images/dashboard/sales/disket.png" alt="save" />
          </a-button>

          <a-popconfirm
            v-if="tempContract[`flight_${flight.id}_type`] == 'Regular'"
            placement="left"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="confirmAuditory()"
          >
            <template slot="title">
              <p>
                Você confirma todos os valores e dados
                <br />inseridos neste pagamento? Após esta ação
                <br />
                não será mais possível editar.
              </p>
            </template>
            <a-button
              v-if="!edit"
              html-type="submit"
              class="ml-10 update"
              type="primary"
            >
              <a-icon type="check" />
            </a-button>
          </a-popconfirm>

          <a-popconfirm
            v-if="tempContract[`flight_${flight.id}_type`] == 'Milhas'"
            placement="left"
            ok-text="Sim"
            :disabled="disableConfirmAuditory()"
            cancel-text="Não"
            @confirm="confirmAuditory()"
          >
            <template slot="title">
              <p>
                Você confirma todos os valores e dados
                <br />inseridos neste pagamento? Após esta ação
                <br />
                não será mais possível editar.
              </p>
            </template>
            <a-button
              v-if="!edit"
              html-type="submit"
              class="ml-10 update"
              :class="disableConfirmAuditory() ? 'disabled' : ''"
              type="primary"
            >
              <a-icon type="check" />
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import ContractFinancesRegularFlight from "../../contracts/collapses/ContractFinancesRegularFlight.vue";
import ContractFinancesMilesFlight from "../../contracts/collapses/ContractFinancesMilesFlight.vue";

export default {
  components: {
    ContractFinancesRegularFlight,
    ContractFinancesMilesFlight,
  },
  props: {
    tempContract: Object,
    sale: Object,
    flight: Object,
    id: Number,
    index: Number,
  },
  mixins: [formatThings],
  data() {
    return {
      edit: false,
      form: this.$form.createForm(this),
      milesOrdersList: [],
      loadingOrders: false,
      milesCostModal: false,
      perMiles: 0,
      milesOrderId: 0,
    };
  },
  mounted() {
    this.form.setFieldsValue({
      [`contract_finances_flight_${this.id}_supplier_id`]:
        this.tempContract[`contract_finances_flight_${this.id}_supplier_id`],
    });

    if (this.tempContract[`flight_${this.flight.id}_type`] == "Milhas") {
      this.getMilesOrdersList();
    }
  },
  methods: {
    updateMileCostInContract(milesOrder) {
      //contract_id
      let values = {},
        totalSaleValue = 0;

      this.milesOrdersList.forEach((mOrder) => {
        mOrder.raw.flight_id;
        if (mOrder.raw.flight_id == milesOrder.flight_id) {
          totalSaleValue += parseFloat(mOrder.raw.miles_cost);
        }
      });

      // values[`contract_finances_flight_${milesOrder.flight_id}_sale_value`] =
      //   milesOrder.miles_cost;

      values[`contract_finances_flight_${milesOrder.flight_id}_sale_value`] =
        totalSaleValue;
      values.id = this.tempContract.id;

      //contract_finances_flight_1_sale_value

      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);

          this.$http
            .post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.sale.id,
              module: "sale",
              action: `update-miles-cost-in-contract`,
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o valor do custo das milhas do Aéreo ${milesOrder.flight_id}, no contrato ${this.tempContract.id}.`,
            })
            .then(({ data }) => {
              data;
            });
          this.$emit("updateTempContract", this.tempContract);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    disableConfirmAuditory() {
      let flag = false;

      if (
        this.tempContract[
          `contract_finances_flight_${this.flight.id}_sale_value`
        ] !=
        this.sale.contract.meta[
          `contract_finances_flight_${this.flight.id}_sale_value`
        ]
      ) {
        flag = true;
      }

      return flag;
    },

    getMilesOrdersList() {
      this.loadingOrders = true;
      setTimeout(() => {
        this.$http
          .get(
            `/miles-card-order/list?page=1&per_page=20&status=Concluído&contract_id=${this.sale.raw.contract_id}&flight_id=${this.flight.id}&order=desc&order-by=created`
          )
          .then(({ data }) => {
            this.milesOrdersList = data.data;
            this.loadingOrders = false;
          });
      }, 200);
    },
    calcTheCost(order) {
      let cost =
        (order.miles_quantity
          .replace(".", "")
          .replace(".", "")
          .replace(".", "") *
          parseFloat(order.miles_card.price_per_miles)) /
        1000;
      return this.formatPricePtBr(cost);
    },
    updateMilesCost() {
      let values = {};

      values.id = this.milesOrderId;
      values.field = "per_miles";
      values.value = this.perMiles ? this.perMiles.replace(",", ".") : 0;
      values.action = "update-miles-cost";
      values.module = "miles-card-order";
      values.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o custo das milhas para R$ ${this.perMiles} no pedido ${this.milesOrderId}.`;
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/miles-card-order/update-field", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);
          this.edit = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    openMilesCostModal(milesOrder) {
      this.milesOrderId = milesOrder.id;
      this.perMiles = milesOrder.per_miles;
      this.milesCostModal = true;
    },
    formatMilesRequest(request) {
      let allCards = "";

      if (
        request.status == "Pendente" ||
        request.status == "Pedido Cancelado"
      ) {
        allCards = "";
      } else {
        allCards = `ID ${request.miles_card.id}`;
      }

      return allCards;
    },
    formatProfit(record) {
      let divClass = "";
      if (parseFloat(record.raw.profit) < 0) {
        divClass = "red";
      }

      return `<div class="${divClass}"> R$ ${record.profit} </div>`;
    },
    editRow(id, type) {
      id;
      if (type == "edit") {
        this.edit = true;
      }

      if (type == "cancel") {
        this.edit = false;
      }
    },
    onChangePaymentType(paymentType, onMount) {
      if (!onMount) {
        this.form.setFieldsValue({
          [`contract_finances_service_${this.id}_payment_method`]: undefined,
        });
      }

      if (
        paymentType == "Boleto Bancário" ||
        paymentType == "Faturado" ||
        paymentType == "Transferência Bancária"
      ) {
        this.$http
          .get(`/company-bank-accounts/list?page=1&per_page=200&status=Ativo`)
          .then(({ data }) => {
            this.paymentMethodsList = data.data;
          });
      }

      if (paymentType == "Cartão de Crédito") {
        this.$http
          .get(`/company-credit-card/list?page=1&per_page=100&status=Ativo`)
          .then(({ data }) => {
            this.paymentMethodsList = data.data;
          });
      }
    },
    confirmAuditory() {
      let values = {};
      values[`contract_finances_flight_${this.id}_audit_status`] = "Realizado";
      values[`contract_finances_flight_${this.id}_audit_user_id`] =
        this.$store.state.userData.id;
      values.id = this.tempContract.id;
      values.sale_id = this.sale.id;
      values.module = "sale";
      values.flight = {
        id: this.id,
        name: `AÉREO ${this.tempContract[
          `flight_${this.id}_type`
        ].toUpperCase()}`,
      };
      values.action = "update-contract-flight-status-in-sale";
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      values.sale_to_pay_active_accordeon = this.index;

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);
          this.edit = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    updateTempContract(tempContract) {
      this.$emit("updateTempContract", tempContract);
    },
    submitContract(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        values.id = this.tempContract.id;
        values.sale_id = this.sale.id;
        values.module = "sale";
        values.flight = {
          id: this.id,
          name: `AÉREO ${this.tempContract[
            `flight_${this.id}_type`
          ].toUpperCase()}`,
        };
        values.action = "update-contract-flight-in-sale";
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        values.sale_to_pay_active_accordeon = this.index;

        if (!err) {
          this.$http
            .post("/contract/update-multi-meta", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("updateTempContract", this.tempContract);
              this.edit = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        } else {
          this.$message.error("Prrencha todos os campos.");
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.head
  text-transform: capitalize
  background: #929496
  padding: 5px 8px
  border-radius: 5px
  margin-bottom: 5px
  color: #FFF
  text-transform: uppercase
  cursor: pointer
.payment-row
  .edit
    background: #f27935
    border-color: #f27935
    padding: 5px 10px
  .cancel
    background: #59595b
    border-color: #59595b
    padding: 5px 10px
  .update
    background: #2ecc71
    border-color: #2ecc71
    padding: 5px 10px
    &.disabled
      background: #ddd
      border-color: #ddd
  .save
    background: #00acec
    border-color: #00acec
    padding: 0 6px
</style>
