var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submitContract}},[(_vm.item.type == 'custom-payment')?_c('a-row',{staticClass:"content",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":21}},[_c('a-row',[_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Valor ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_custom_payment_total_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_custom_payment_total_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Preencha o valor.',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor","disabled":!_vm.item.edit}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),(_vm.sale.status != 'Finalizado')?_c('a-col',{staticClass:"a-right",attrs:{"span":3}},[_c('a-popconfirm',{attrs:{"placement":"left","ok-text":"Sim","cancel-text":"Não"},on:{"confirm":function($event){return _vm.confirmAuditory()}}},[_c('template',{slot:"title"},[_c('p',[_vm._v("\n              Você confirma todos os valores e dados\n              "),_c('br'),_vm._v("inseridos neste recebimento? Após esta ação\n              "),_c('br'),_vm._v("\n              não será mais possível editar.\n            ")])]),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 update",attrs:{"html-type":"submit","type":"primary"}},[_c('a-icon',{attrs:{"type":"check"}})],1):_vm._e()],2)],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }