<template>
  <div>
    <a-form :form="form" @submit="submitContract">
      <a-row style="padding-right: 10px">
        <a-col
          v-for="(item, index) in theList()"
          :key="index"
          class="detail-row"
          :span="24"
        >
          <div @click="activeAccordeon(index)">
            <a-row
              class="head"
              :class="item.done ? 'done' : 'edit'"
              type="flex"
              justify="space-between"
            >
              <a-col> Livelo </a-col>
              <a-col>
                <img
                  v-if="item.done"
                  src="../../../assets/images/dashboard/sales/check.png"
                  alt="check"
                  width="16"
                />

                <a-icon
                  class="ml-10 relative"
                  type="caret-down"
                  style="top: 3px"
                />
              </a-col>
            </a-row>
          </div>
          <div v-if="active == index">
            <div v-if="item.type == 'Livelo'" style="padding: 0 20px 0">
              <LiveloTab
                :total="0"
                :contract="sale.contract"
                :tempContract="tempContract"
                :form="form"
                :blockFields="true"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import LiveloTab from "../../contracts/tabs/LiveloTab.vue";

export default {
  components: { LiveloTab },
  props: {
    tempContract: Object,
    sale: Object,
  },
  data() {
    return {
      marketingTypes: [],
      active: -1,
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
    };
  },
  mounted() {
    this.marketingTypes =
      this.tempContract.marketing_types != undefined &&
      this.tempContract.marketing_types != ""
        ? JSON.parse(this.tempContract.marketing_types)
        : [];
  },
  methods: {
    activeAccordeon(current) {
      if (current == this.active) {
        this.active = -1;
      } else {
        this.active = current;
      }
    },
    theList() {
      let arr = [];

      if (this.marketingTypes.includes("Livelo")) {
        arr.push({
          id: 1,
          name: "Livelo",
          type: "Livelo",
          done:
            this.tempContract.livelo_points_is_sent == "Finalizado"
              ? true
              : false,
        });
      }

      return arr;
    },
    submitContract(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="sass" scoped>
.detail-row
  .content
    padding: 20px 20px 0
    .edit
      background: #f27935
      border-color: #f27935
      padding: 5px 10px
    .update
      background: #2ecc71
      border-color: #2ecc71
      padding: 5px 10px
  .head
    text-transform: capitalize
    background: #929496
    padding: 5px 8px
    border-radius: 5px
    margin-bottom: 5px
    color: #FFF
    text-transform: uppercase
    cursor: pointer
    &.done
      background: #2ecc71
</style>
