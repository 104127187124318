<template>
  <aForm :form="form" @submit="submitContract">
    <aRow class="content" :gutter="20">
      <aCol :span="21">
        <aRow :gutter="20">
          <aCol :span="4">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Valor </label>
              <a-input
                class="travel-input"
                placeholder="Valor"
                v-currency
                :disabled="true"
                v-decorator="[
                  `payment_methods_payment_link_total_value_${item.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol :span="2">
            <a-form-item class="travel-input-outer">
              <label class="filled">Parcelas</label>

              <a-select
                class="travel-input select"
                placeholder="Parcelas"
                :disabled="!edit"
                @change="calcInstallments"
                v-decorator="[
                  `payment_methods_payment_link_installments_${item.id}`,
                  {
                    initialValue: 2,
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of installments"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </aCol>

          <aCol :span="6">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">PDQ</label>

              <a-select
                class="travel-input select"
                placeholder="Maquininha"
                :disabled="true"
                allow-clear
                @change="onChangeCardMachine"
                v-decorator="[
                  `payment_methods_payment_link_machine_id_${item.id}`,

                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of cardMachines.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </aCol>

          <aCol
            v-if="
              form.getFieldValue(
                `payment_methods_payment_link_machine_id_${item.id}`
              ) != 14
            "
            :span="3"
          >
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Taxa PDQ % </label>

              <a-input
                class="travel-input readonly"
                placeholder=""
                :disabled="!edit"
                v-currency
                v-decorator="[
                  `payment_methods_payment_link_tax_percentage_${item.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol
            v-if="
              form.getFieldValue(
                `payment_methods_payment_link_machine_id_${item.id}`
              ) != 14
            "
            :span="3"
          >
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Taxa PDQ </label>

              <a-input
                class="travel-input"
                placeholder="Valor"
                v-currency
                :disabled="!edit"
                v-decorator="[
                  `payment_methods_payment_link_tax_value_${item.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol :span="6">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Data de recebimento </label>
              <a-date-picker
                class="travel-input"
                placeholder="Data de recebimento"
                format="DD/MM/YYYY"
                :showToday="false"
                :allowClear="true"
                v-mask="'##/##/####'"
                :disabled="!edit"
                v-decorator="[
                  `payment_methods_payment_link_receipt_date_${item.id}`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-date-picker>
            </a-form-item>
          </aCol>
        </aRow>
      </aCol>

      <aCol v-if="sale.status != 'Finalizado'" class="a-right" :span="3">
        <a-button
          v-if="edit"
          @click="editRow('cancel')"
          class="ml-10 cancel"
          type="primary"
        >
          <a-icon type="close" />
        </a-button>

        <a-button
          v-if="!edit"
          @click="editRow('edit')"
          class="ml-10 edit"
          type="primary"
        >
          <a-icon type="edit" />
        </a-button>

        <a-popconfirm
          placement="left"
          ok-text="Sim"
          cancel-text="Não"
          @confirm="confirmAuditory()"
        >
          <template slot="title">
            <p>
              Você confirma todos os valores e dados
              <br />inseridos neste recebimento? Após esta ação
              <br />
              não será mais possível editar.
            </p>
          </template>
          <a-button
            v-if="!edit"
            html-type="submit"
            class="ml-10 update"
            type="primary"
            :loading="loading"
          >
            <a-icon type="check" />
          </a-button>
        </a-popconfirm>

        <a-button
          v-if="edit"
          html-type="submit"
          class="ml-10 save"
          type="primary"
          style="padding: 0px 6px"
          :loading="loading"
        >
          <img
            v-show="!loading"
            src="@/assets/images/dashboard/sales/disket.png"
            alt="save"
          />
        </a-button>
      </aCol>
    </aRow>

    <aRow
      v-if="
        form.getFieldValue(
          `payment_methods_payment_link_machine_id_${item.id}`
        ) != 14
      "
      class="content pt-0 mb-20"
    >
      <aCol class="adm-tax bordered-box" :span="24">
        <div class="title">Taxa Administrativa</div>
        <aRow :gutter="20">
          <aCol
            v-if="
              form.getFieldValue(
                `payment_methods_payment_link_machine_id_${item.id}`
              ) != 14
            "
            :span="2"
          >
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Taxa ADM % </label>

              <a-input
                class="travel-input readonly"
                placeholder=""
                v-currency
                :disabled="!edit"
                v-decorator="[
                  `payment_methods_payment_link_advance_fee_percentage_${item.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol
            v-if="
              form.getFieldValue(
                `payment_methods_payment_link_machine_id_${item.id}`
              ) != 14
            "
            :span="3"
          >
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Taxa ADM </label>

              <a-input
                class="travel-input"
                placeholder="Valor"
                v-currency
                :disabled="!edit"
                v-decorator="[
                  `payment_methods_payment_link_advance_fee_value_${item.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol
            v-if="
              form.getFieldValue(
                `payment_methods_payment_link_machine_id_${item.id}`
              ) != 14
            "
            :span="6"
          >
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Filial</label>

              <a-select
                class="travel-input select"
                placeholder="Selecione "
                :disabled="!edit"
                allow-clear
                @change="onChangeCardCompanyBranch"
                v-decorator="[
                  `payment_methods_payment_link_company_branch_id_${item.id}`,

                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of companyBranchesList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.id }} - {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </aCol>

          <aCol
            v-if="
              form.getFieldValue(
                `payment_methods_payment_link_machine_id_${item.id}`
              ) != 14
            "
            :span="6"
          >
            <a-checkbox
              v-model="discountAdmTaxInProfit"
              :disabled="!edit"
              style="
                font-size: 11px;
                font-weight: 600;
                position: relative;
                top: 10px;
              "
            >
              (-) Lucratividade
            </a-checkbox>

            <a-button
              v-if="edit"
              html-type="submit"
              class="relative save-adm pd-0"
              type="link"
              style="top: 10px"
              :loading="loading"
            >
              <a-icon class="cgreen" type="reload" />
            </a-button>
          </aCol>
        </aRow>
      </aCol>
    </aRow>

    <aRow
      v-if="
        form.getFieldValue(
          `payment_methods_payment_link_machine_id_${item.id}`
        ) == 14
      "
      class="content pt-0 mb-20"
    >
      <aCol class="adm-tax auto bordered-box" :span="24">
        <div class="title">À Receber</div>
        <aRow :gutter="20">
          <aCol :span="6">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Fornecedor</label>
              <a-select
                class="travel-input"
                show-search
                :disabled="!edit"
                :allow-clear="true"
                optionFilterProp="txt"
                placeholder="Selecione um fornecedor"
                v-decorator="[
                  `payment_methods_payment_link_machine_supplier_id_${item.id}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                style="width: 100%; height: 30px"
                @change="onSelectSupplier(item.id)"
              >
                <a-select-option
                  v-for="(item, index) of productSupplier.list"
                  :key="index"
                  :value="item.id"
                  :txt="item.trading_name"
                >
                  {{ item.id }} -
                  {{ item.trading_name.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </aCol>

          <aCol :span="18">
            <aRow
              v-for="(valueToReceive, index) in valueToReceiveRows"
              :key="index"
              :gutter="20"
            >
              <aCol :span="10">
                <a-form-item class="travel-input-outer">
                  <label :class="'filled'">Lucratividade</label>
                  <a-select
                    class="travel-input"
                    show-search
                    :disabled="!edit"
                    :allow-clear="true"
                    optionFilterProp="txt"
                    placeholder="Selecione"
                    v-decorator="[
                      `payment_methods_payment_link_${item.id}_value_to_receive_${valueToReceive.id}_profit_value`,
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                    style="width: 100%; height: 30px"
                  >
                    <a-select-option
                      v-for="(profitItem, index) of allContractData.profitList"
                      :key="index"
                      :value="profitItem.product"
                      :txt="profitItem.name"
                    >
                      <aRow type="flex" justify="space-between" class="f11">
                        <aCol> <div v-html="profitItem.name" /></aCol>
                        <aCol style="font-weight: 600; padding-left: 20px">
                          <div v-html="profitItem.value"
                        /></aCol>
                      </aRow>
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </aCol>

              <aCol :span="6">
                <a-form-item class="travel-input-outer">
                  <label :class="'filled'">Data de recebimento </label>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    v-mask="'##/##/####'"
                    :disabled="!edit"
                    v-decorator="[
                      `payment_methods_payment_link_${item.id}_value_to_receive_${valueToReceive.id}_receipt_date`,
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </aCol>

              <aCol
                v-if="
                  valueToReceiveRows.length == valueToReceive.id &&
                  valueToReceive.id != 1
                "
                :span="1"
              >
                <a-button
                  v-if="edit"
                  class="relative pd-0"
                  type="link"
                  style="top: 10px"
                  @click="removeValueToReceiveRow(valueToReceive.id)"
                >
                  <a-icon class="red" type="delete" />
                </a-button>
              </aCol>

              <aCol v-if="valueToReceive.id == 1" :span="1">
                <a-button
                  v-if="edit"
                  class="relative pd-0 f26"
                  type="link"
                  style="top: 5px; font-weight: 500; color: #ff6456"
                  @click="addValueToReceiveRow"
                >
                  +
                </a-button>
              </aCol>
              <aCol :span="1">
                <a-tooltip>
                  <template slot="title">
                    Limpar dados da lucratividade
                  </template>
                  <a-button
                    v-if="edit"
                    class="relative pd-0"
                    type="link"
                    style="top: 10px"
                    @click="resetValueToReceiveRow(valueToReceive.id)"
                  >
                    <a-icon class="red f14" type="close-circle" />
                  </a-button>
                </a-tooltip>
              </aCol>
              <aCol v-if="valueToReceive.id == 1" :span="1">
                <a-button
                  v-if="edit"
                  html-type="submit"
                  class="relative save-adm pd-0"
                  type="link"
                  style="top: 10px"
                  :loading="loading"
                >
                  <a-icon class="cgreen" type="reload" />
                </a-button>
              </aCol>
            </aRow>
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </aForm>
</template>

<script>
import { parse } from "vue-currency-input";

import formatThings from "@/mixins/general/formatThings.js";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import contractMixins from "@/components/contracts/mixins/contractMixins";

export default {
  props: {
    tempContract: Object,
    sale: Object,
    item: Object,
    index: Number,
  },
  mixins: [
    formatThings,
    cardMachinesMixins,
    productSupplierMixins,
    contractMixins,
  ],
  data() {
    return {
      edit: false,
      loading: false,
      installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      taxesRange: [],
      advanceFeeRange: [],
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
      companyBranchesList: [],
      discountAdmTaxInProfit: false,
      CreditCardCompanyBranchName: "",
      valueToReceiveRows: [
        {
          id: 1,
        },
      ],
    };
  },
  mounted() {
    this.cardMachines.filters.status.selected = "Ativo";
    this.getCardMachines();

    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    this.contractPricingData(this.tempContract);

    // if (
    //   this.tempContract[
    //     `payment_methods_payment_link_${this.item.id}_value_to_receive_rows`
    //   ] != undefined
    // ) {
    //   this.valueToReceiveRows = JSON.parse(
    //     this.tempContract[
    //       `payment_methods_payment_link_${this.item.id}_value_to_receive_rows`
    //     ]
    //   );
    // }

    if (
      this.tempContract[
        `payment_methods_payment_link_taxes_range_${this.item.id}`
      ] != undefined
    ) {
      this.taxesRange =
        this.tempContract[
          `payment_methods_payment_link_taxes_range_${this.item.id}`
        ];
    }

    if (
      this.tempContract[
        `payment_methods_payment_link_advance_fee_range_${this.item.id}`
      ] != undefined
    ) {
      this.advanceFeeRange =
        this.tempContract[
          `payment_methods_payment_link_advance_fee_range_${this.item.id}`
        ];
    }

    const payments = JSON.parse(this.tempContract.payment_methods);

    setTimeout(() => {
      if (payments.includes("payment-link")) {
        if (this.tempContract["payment_link_rows"]) {
          JSON.parse(this.tempContract["payment_link_rows"]).forEach((card) => {
            this.form.setFieldsValue({
              [`payment_methods_payment_link_receipt_date_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_receipt_date_${card.id}`
                ],
              [`payment_methods_payment_link_total_value_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_total_value_${card.id}`
                ],
              [`payment_methods_payment_link_installments_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_installments_${card.id}`
                ],
              [`payment_methods_payment_link_machine_id_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_machine_id_${card.id}`
                ],
              [`payment_methods_payment_link_advance_fee_value_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_advance_fee_value_${card.id}`
                ],
              [`payment_methods_payment_link_tax_value_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_tax_value_${card.id}`
                ],
              [`payment_methods_payment_link_advance_fee_percentage_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_advance_fee_percentage_${card.id}`
                ],
              [`payment_methods_payment_link_tax_percentage_${card.id}`]:
                this.tempContract[
                  `payment_methods_payment_link_tax_percentage_${card.id}`
                ],
            });

            this.discountAdmTaxInProfit =
              this.tempContract[
                `payment_methods_payment_link_discount_adm_tax_in_profit_${card.id}`
              ] != undefined
                ? this.tempContract[
                    `payment_methods_payment_link_discount_adm_tax_in_profit_${card.id}`
                  ] == 1
                  ? true
                  : false
                : false;

            setTimeout(() => {
              this.form.setFieldsValue({
                [`payment_methods_payment_link_machine_supplier_id_${card.id}`]:
                  this.tempContract[
                    `payment_methods_payment_link_machine_supplier_id_${card.id}`
                  ],
                [`payment_methods_payment_link_company_branch_id_${card.id}`]:
                  this.tempContract[
                    `payment_methods_payment_link_company_branch_id_${card.id}`
                  ]
                    ? this.tempContract[
                        `payment_methods_payment_link_company_branch_id_${card.id}`
                      ]
                    : undefined,
              });
            }, 500);
          });
        }
      }

      this.getCompanyBranches();

      setTimeout(() => {
        this.valueToReceiveRows.forEach((valueToReceive) => {
          this.form.setFieldsValue({
            [`payment_methods_payment_link_${this.item.id}_value_to_receive_${valueToReceive.id}_profit_value`]:
              this.tempContract[
                `payment_methods_payment_link_${this.item.id}_value_to_receive_${valueToReceive.id}_profit_value`
              ]
                ? this.tempContract[
                    `payment_methods_payment_link_${this.item.id}_value_to_receive_${valueToReceive.id}_profit_value`
                  ]
                : undefined,
            [`payment_methods_payment_link_${this.item.id}_value_to_receive_${valueToReceive.id}_receipt_date`]:
              this.tempContract[
                `payment_methods_payment_link_${this.item.id}_value_to_receive_${valueToReceive.id}_receipt_date`
              ],
          });
        });
      }, 500);
    }, 500);
  },
  methods: {
    editRow(type) {
      if (type == "edit") this.edit = true;
      if (type == "cancel") this.edit = false;
    },
    addValueToReceiveRow() {
      this.valueToReceiveRows.push({
        id: this.valueToReceiveRows.length + 1,
      });
    },
    resetValueToReceiveRow(id) {
      this.form.setFieldsValue({
        [`payment_methods_payment_link_${this.item.id}_value_to_receive_${id}_profit_value`]:
          "",
        [`payment_methods_payment_link_${this.item.id}_value_to_receive_${id}_receipt_date`]:
          "",
      });
    },
    removeValueToReceiveRow(id) {
      for (var i in this.valueToReceiveRows) {
        if (this.valueToReceiveRows[i].id == id) {
          this.valueToReceiveRows.splice(i, 1);
          break;
        }
      }
    },
    onChangeCardCompanyBranch(id) {
      if (id) {
        let theName = this.companyBranchesList.filter((companyBranch) => {
          return companyBranch.id == id;
        });

        this.CreditCardCompanyBranchName = theName[0].name;
      } else {
        this.CreditCardCompanyBranchName = "";
      }
    },
    getCompanyBranches() {
      this.$http
        .get(`/company-branch/list?page=1&per_page=100&status=Ativo`)
        .then(({ data }) => {
          this.companyBranchesList = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
    onChangeCardMachine() {
      let id = this.form.getFieldValue(
        `payment_methods_payment_link_machine_id_${this.item.id}`
      );

      this.form.setFieldsValue({
        [`payment_methods_payment_link_advance_fee_value_${this.item.id}`]: "",
      });

      this.form.setFieldsValue({
        [`payment_methods_payment_link_tax_value_${this.item.id}`]: "",
      });

      this.form.setFieldsValue({
        [`payment_methods_payment_link_total_tax_discount_${this.item.id}`]: "",
      });

      let tax = this.cardMachines.list.filter((e) => {
        return e.id == id;
      });

      if (tax[0] != undefined) {
        this.taxesRange = tax[0].taxes_range;
        this.advanceFeeRange = tax[0].advance_fee;
      } else {
        this.taxesRange = "[]";
        this.advanceFeeRange = "[]";
        setTimeout(() => {
          this.tempContract[
            `payment_methods_payment_link_tax_id_${this.item.id}`
          ] = "";
        }, 100);
      }

      // let installments =
      //     this.tempContract[
      //         `payment_methods_payment_link_installments_${this.item.id}`
      //     ];

      let installments = this.form.getFieldValue(
        `payment_methods_payment_link_installments_${this.item.id}`
      );

      this.calcInstallments(installments);
    },
    onSelectSupplier(cardID) {
      setTimeout(() => {
        let supplierId =
          this.tempContract[
            `payment_methods_payment_link_machine_supplier_id_${cardID}`
          ];

        let theSupplier = this.productSupplier.list.filter((supplier) => {
          return supplier.id == supplierId;
        });

        this.tempContract[
          `payment_methods_payment_link_machine_supplier_${cardID}`
        ] = theSupplier[0].trading_name;
      }, 200);
    },
    calcInstallments(installments) {
      let creditCardValue = this.form.getFieldValue(
        `payment_methods_payment_link_total_value_${this.item.id}`
      );

      if (creditCardValue) {
        let monthlyInstallments = [];

        creditCardValue = parse(creditCardValue);

        let taxesRange = this.taxesRange;

        let advanceFeeRange = this.advanceFeeRange;

        let selectedTax = "";
        let selectedAdvanceFee = "";
        let totalAdvanceFee = 0;
        let totalTax = 0;

        if (advanceFeeRange && advanceFeeRange != "[]") {
          advanceFeeRange = JSON.parse(advanceFeeRange);

          if (advanceFeeRange.length > 0) {
            selectedAdvanceFee = advanceFeeRange.filter((e) => {
              return e.id == installments;
            });

            let totalPercentage = 0;
            let thePercentage = 0;

            if (selectedAdvanceFee.length > 0) {
              thePercentage = selectedAdvanceFee[0].value
                .replace("%", "")
                .replace(",", ".");
            }

            totalPercentage = parseFloat(thePercentage);

            let theTaxPercentageDiscount = 0;

            theTaxPercentageDiscount =
              (totalPercentage / 100) * creditCardValue;

            this.form.setFieldsValue({
              [`payment_methods_payment_link_advance_fee_percentage_${this.item.id}`]:
                totalPercentage,
            });

            this.form.setFieldsValue({
              [`payment_methods_payment_link_advance_fee_value_${this.item.id}`]:
                theTaxPercentageDiscount.toFixed(2).replace(".", ","),
            });

            totalAdvanceFee = theTaxPercentageDiscount;
          }
        } else {
          selectedAdvanceFee = "";
          advanceFeeRange = [];
          totalAdvanceFee = 0;

          this.form.setFieldsValue({
            [`payment_methods_payment_link_advance_fee_percentage_${this.item.id}`]: 0,
          });

          this.form.setFieldsValue({
            [`payment_methods_payment_link_advance_fee_value_${this.item.id}`]: 0,
          });
        }

        if (taxesRange && taxesRange != "[]") {
          taxesRange = JSON.parse(taxesRange);

          if (taxesRange.length > 0) {
            selectedTax = taxesRange.filter((e) => {
              return e.id == installments;
            });

            let totalPercentage = 0;
            let thePercentage = selectedTax[0].value
              .replace("%", "")
              .replace(",", ".");
            totalPercentage = parseFloat(thePercentage);

            let theTaxPercentageDiscount = 0;

            theTaxPercentageDiscount =
              (totalPercentage / 100) * creditCardValue;

            this.form.setFieldsValue({
              [`payment_methods_payment_link_tax_percentage_${this.item.id}`]:
                totalPercentage,
            });

            this.form.setFieldsValue({
              [`payment_methods_payment_link_tax_value_${this.item.id}`]:
                theTaxPercentageDiscount.toFixed(2).replace(".", ","),
            });

            totalTax = theTaxPercentageDiscount;
          }
        } else {
          selectedTax = "";
          taxesRange = [];
          totalTax = 0;

          this.form.setFieldsValue({
            [`payment_methods_payment_link_tax_percentage_${this.item.id}`]: 0,
          });

          this.form.setFieldsValue({
            [`payment_methods_payment_link_tax_value_${this.item.id}`]: 0,
          });
        }

        this.form.setFieldsValue({
          [`payment_methods_payment_link_total_with_tax_percentage_discount_${this.item.id}`]:
            (creditCardValue - (totalTax + totalAdvanceFee))
              .toFixed(2)
              .replace(".", ","),
        });

        this.form.setFieldsValue({
          [`payment_methods_payment_link_total_tax_discount_${this.item.id}`]: (
            totalTax + totalAdvanceFee
          )
            .toFixed(2)
            .replace(".", ","),
        });

        for (let i = 1; i < installments + 1; i++) {
          monthlyInstallments.push(creditCardValue / installments);
        }

        this.form.setFieldsValue({
          [`payment_methods_payment_link_first_installment_value_${this.item.id}`]:
            this.formatPrice(monthlyInstallments[0]),
        });

        this.form.setFieldsValue({
          [`payment_methods_payment_link_installments_value_${this.item.id}`]:
            this.formatPrice(monthlyInstallments[0]),
        });
      }
    },
    confirmAuditory() {
      let values = {};

      values[`payment_methods_payment_link_audit_status_${this.item.id}`] =
        "Realizado";
      values[`payment_methods_payment_link_audit_user_id_${this.item.id}`] =
        this.$store.state.userData.id;
      values[`payment_methods_payment_link_taxes_range_${this.item.id}`] =
        this.taxesRange;
      values[`payment_methods_payment_link_advance_fee_range_${this.item.id}`] =
        this.advanceFeeRange;

      values[
        `payment_methods_payment_link_${this.item.id}_value_to_receive_rows`
      ] = JSON.stringify(this.valueToReceiveRows);

      values.id = this.tempContract.id;
      values.sale_id = this.sale.id;
      values.module = "sale";
      values.action = "update-contract-payment-link-status-in-sale";
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.tempContract[
        `payment_methods_payment_link_audit_status_${this.item.id}`
      ] = "Realizado";
      this.tempContract[
        `payment_methods_payment_link_audit_user_id_${this.item.id}`
      ] = this.$store.state.userData.id;

      values["sale_receive_active_accordeon"] = this.index;

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);
          this.edit = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    submitContract(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.tempContract.id;
        values.sale_id = this.sale.id;
        values.module = "sale";
        values.action = "update-contract-payment-link-in-sale";
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        values[
          `payment_methods_payment_link_company_branch_name_${this.item.id}`
        ] = this.CreditCardCompanyBranchName;

        values[
          `payment_methods_payment_link_discount_adm_tax_in_profit_${this.item.id}`
        ] = this.discountAdmTaxInProfit;

        values[`payment_methods_payment_link_taxes_range_${this.item.id}`] =
          this.taxesRange;

        values[
          `payment_methods_payment_link_${this.item.id}_value_to_receive_rows`
        ] = JSON.stringify(this.valueToReceiveRows);

        values[
          `payment_methods_payment_link_company_branch_id_${this.item.id}`
        ];

        if (
          values[
            `payment_methods_payment_link_company_branch_id_${this.item.id}`
          ] == undefined
        )
          values[
            `payment_methods_payment_link_company_branch_id_${this.item.id}`
          ] = "";

        values[
          `payment_methods_payment_link_advance_fee_range_${this.item.id}`
        ] = this.advanceFeeRange;

        values.sale_receive_active_accordeon = this.index;

        if (!err) {
          this.loading = true;
          this.$http
            .post("/contract/update-multi-meta", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("updateTempContract", this.tempContract);
              this.edit = false;
              this.loading = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        } else {
          this.$message.error("Prrencha todos os campos.");
        }
      });
    },
  },
};
</script>

<style>
.adm-tax .ant-select-selection-selected-value {
  width: 100% !important;
}
</style>

<style lang="sass" scoped>
.bordered-box.adm-tax.auto
  height: auto
.bordered-box.adm-tax
  height: 76px
.bordered-box
  border: 1px solid #ddd
  padding: 20px 20px 0
  border-radius: 10px
  position: relative
  .title
    position: absolute
    top: -11px
    background: #FFF
    padding: 0 10px
    font-size: 12px
    font-weight: 500
.content
  padding: 20px 20px 0
  .edit
    background: #f27935
    border-color: #f27935
    padding: 5px 10px
  .cancel
    background: #59595b
    border-color: #59595b
    padding: 5px 10px
  .update
    background: #2ecc71
    border-color: #2ecc71
    padding: 5px 10px
  .save
    background: #00acec
    border-color: #00acec
    padding: 0 6px
    width: 34px
</style>
