import { render, staticRenderFns } from "./SaleModal.vue?vue&type=template&id=1ed0b7be&scoped=true&"
import script from "./SaleModal.vue?vue&type=script&lang=js&"
export * from "./SaleModal.vue?vue&type=script&lang=js&"
import style0 from "./SaleModal.vue?vue&type=style&index=0&id=1ed0b7be&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed0b7be",
  null
  
)

export default component.exports