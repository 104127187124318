var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{staticStyle:{"padding-right":"10px"}},_vm._l((this.moneyToReceiveList),function(item,index){return _c('aCol',{key:index,staticClass:"payment-row",attrs:{"span":24}},[_c('div',{on:{"click":function($event){return _vm.activeAccordeon(index)}}},[_c('aRow',{staticClass:"head",class:(_vm.tempContract.payment_methods_bank_transfer_audit_status ==
              'Realizado' &&
              item.type == 'bank-transfer') ||
            (_vm.tempContract[
              `payment_methods_bank_transfer_audit_status_${item.id}`
            ] == 'Realizado' &&
              item.type == 'bank-transfer') ||
            (_vm.tempContract[
              `payment_methods_custom_payment_audit_status_${item.id}`
            ] == 'Realizado' &&
              item.type == 'custom-payment') ||
            (_vm.tempContract.payment_methods_custom_payment_audit_status ==
              'Realizado' &&
              item.type == 'custom-payment') ||
            (_vm.tempContract.payment_methods_payment_card_audit_status ==
              'Realizado' &&
              item.type == 'payment-card') ||
            (_vm.tempContract.payment_methods_billing_ticket_audit_status ==
              'Realizado' &&
              item.type == 'billing-ticket') ||
            (_vm.tempContract[
              `payment_methods_credit_card_audit_status_${item.id}`
            ] == 'Realizado' &&
              item.type == 'card') ||
            (_vm.tempContract[
              `payment_methods_payment_link_audit_status_${item.id}`
            ] == 'Realizado' &&
              item.type == 'payment-link')
              ? 'done'
              : 'edit',attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v(_vm._s(index + 1)+" - "+_vm._s(item.name)+"\n            "+_vm._s(item.id ? "#" + item.id : "")+"\n            "),(item.number)?_c('span',{staticClass:"ml-20 lower"},[_vm._v("\n              "+_vm._s(item.number ? "xxxx-" + item.number : "")+"\n            ")]):_vm._e(),_vm._v(" "),(item.number)?_c('span',{staticClass:"ml-20"},[(
                  _vm.tempContract[
                    `payment_methods_credit_card_client_flag_${item.id}`
                  ] == 'Mastercard'
                )?_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/images/mastercard.png"),"alt":"img"}}):_vm._e(),_vm._v(" "),(
                  _vm.tempContract[
                    `payment_methods_credit_card_client_flag_${item.id}`
                  ] == 'Visa'
                )?_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/images/visa.png"),"alt":"img"}}):_vm._e(),_vm._v(" "),(
                  _vm.tempContract[
                    `payment_methods_credit_card_client_flag_${item.id}`
                  ] == 'Diners'
                )?_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/images/diners.png"),"alt":"img"}}):_vm._e(),_vm._v(" "),(
                  _vm.tempContract[
                    `payment_methods_credit_card_client_flag_${item.id}`
                  ] == 'AMEX'
                )?_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/images/american.png"),"alt":"img"}}):_vm._e(),_vm._v(" "),(
                  _vm.tempContract[
                    `payment_methods_credit_card_client_flag_${item.id}`
                  ] == 'Elo'
                )?_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/images/elo.png"),"alt":"img"}}):_vm._e(),_vm._v(" "),(
                  _vm.tempContract[
                    `payment_methods_credit_card_client_flag_${item.id}`
                  ] == 'Hipercard'
                )?_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/images/hipercard.png"),"alt":"img"}}):_vm._e()]):_vm._e()]),_vm._v(" "),_c('aCol',[(
                (_vm.tempContract.payment_methods_bank_transfer_audit_status ==
                  'Realizado' &&
                  item.type == 'bank-transfer') ||
                (_vm.tempContract.payment_methods_custom_payment_audit_status ==
                  'Realizado' &&
                  item.type == 'custom-payment') ||
                (_vm.tempContract.payment_methods_payment_card_audit_status ==
                  'Realizado' &&
                  item.type == 'payment-card') ||
                (_vm.tempContract.payment_methods_billing_ticket_audit_status ==
                  'Realizado' &&
                  item.type == 'billing-ticket') ||
                (_vm.tempContract[
                  `payment_methods_credit_card_audit_status_${item.id}`
                ] == 'Realizado' &&
                  item.type == 'card') ||
                (_vm.tempContract[
                  `payment_methods_payment_link_audit_status_${item.id}`
                ] == 'Realizado' &&
                  item.type == 'payment-link')
              )?_c('img',{attrs:{"src":require("@/assets/images/dashboard/sales/check.png"),"alt":"check","width":"16"}}):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"ml-10 relative",staticStyle:{"top":"3px"},attrs:{"type":"caret-down"}})],1)],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active == index),expression:"active == index"}]},[(item.type == 'card')?_c('CreditCardPaymentDetails',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"index":index,"form":_vm.form,"item":item},on:{"updateTempContract":_vm.updateTempContract}}):_vm._e(),_vm._v(" "),(item.type === 'bank-transfer')?_c('ToPayBankTransferSection',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index,"item":item},on:{"updateTempContract":_vm.updateTempContract}}):_vm._e(),_vm._v(" "),(item.type === 'custom-payment')?[(!item.id)?_c('CustomPaymentDetails',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index,"item":item},on:{"updateTempContract":_vm.updateTempContract}}):_vm._e(),_vm._v(" "),(item.id)?_c('CustomPaymentDetailsCollapse',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index,"item":item},on:{"updateTempContract":_vm.updateTempContract}}):_vm._e()]:_vm._e(),_vm._v(" "),(item.type === 'billing-ticket')?_c('BillingTicketPaymentDetails',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index,"item":item},on:{"updateTempContract":_vm.updateTempContract}}):_vm._e(),_vm._v(" "),(item.type == 'payment-card')?_c('PaymentCardDetails',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index,"item":item},on:{"updateTempContract":_vm.updateTempContract}}):_vm._e(),_vm._v(" "),(item.type == 'payment-link')?_c('PaymentLinkDetails',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index,"item":item},on:{"updateTempContract":_vm.updateTempContract}}):_vm._e()],2)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }