<template>
  <div>
    <BankTransferPaymentDetails
      v-if="!item.id"
      :tempContract="tempContract"
      @updateTempContract="updateTempContract"
      :sale="sale"
      :form="form"
      :index="index"
      :item="item"
    />

    <BankTransferPaymentDetailsCollapse
      v-if="item.id"
      :tempContract="tempContract"
      @updateTempContract="updateTempContract"
      :sale="sale"
      :form="form"
      :index="index"
      :item="item"
    />
  </div>
</template>

<script>
import BankTransferPaymentDetails from "../collapse/BankTransferPaymentDetails.vue";
import BankTransferPaymentDetailsCollapse from "../collapse/BankTransferPaymentDetailsCollapse.vue";

export default {
  name: "ToPayBankTransferSection",
  props: {
    tempContract: Object,
    sale: Object,
    item: Object,
    form: Object,
    index: Number,
  },
  components: {
    BankTransferPaymentDetails,
    BankTransferPaymentDetailsCollapse,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    updateTempContract(newTempContract) {
      this.tempContract = newTempContract;
      setTimeout(() => {
        this.$emit("updateTempContract", newTempContract);
      }, 20);
    },
  },
};
</script>

<style lang="sass" scoped></style>
