<template>
  <div id="sale-modal">
    <div v-if="loadingNewSale" style="padding: 30px 30px 0">
      <a-skeleton active />
      <a-skeleton active />
      <a-skeleton active />
      <a-skeleton active />
    </div>

    <div v-if="loadingNewSale == false" class="header">
      <span class="id"> VENDA {{ sale.details.id }} </span>
      <span class="rate">
        <a-rate v-model="sale.tempContract.rate" disabled />
      </span>
      <div v-if="sale.details.status == 'Finalizado'" class="finished">
        <span class="txt"> VENDA FINALIZADA - A edição não é permitida. </span>
      </div>

      <div v-if="sale.details.status == 'Cancelado'" class="finished">
        <span class="txt">
          <a-icon type="close-circle" /> VENDA CANCELADA
        </span>
      </div>

      <aRow class="values" type="flex" :gutter="[5, 5]">
        <aCol :span="6">
          <div class="box sale">
            <div class="subtitle">Venda</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.total) }}

              <img
                v-if="salesTotalValues().totalToReceive == 0"
                src="@/assets/images/dashboard/sales/check-color.png"
                alt="same"
                width="16"
              />
              <a-tooltip v-else placement="top">
                <template slot="title">
                  <span
                    >Verifique na seção recebimentos os dados e valores que
                    ainda não foram recebidos</span
                  >
                </template>
                <img
                  src="@/assets/images/dashboard/sales/alert.png"
                  alt="not-same"
                  width="16"
                />
              </a-tooltip>
            </div>
          </div>
        </aCol>
        <aCol :span="6">
          <div class="box sale">
            <div class="subtitle">A pagar</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalNet) }}
            </div>
            <div class="extra">
              <aRow>
                <aCol class="a-left" :span="12"> </aCol>
                <aCol :span="12">
                  <div v-if="allContractData.flight.sale.netTaxes > 0">
                    Taxas:
                    {{ formatPricePtBr(allContractData.flight.sale.netTaxes) }}
                  </div>
                </aCol>
              </aRow>
            </div>
          </div>
        </aCol>

        <aCol v-if="creditCardPaymentIsWithSupplier()" :span="6">
          <div class="box sale">
            <div class="subtitle">A RECEBER</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalToReceive) }}
            </div>
            <div class="extra"></div>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="box sale">
            <div class="subtitle">Taxas</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalTaxes) }}

              <ContractValuesInfoList
                class="ml-5"
                :arr="allContractData.extraFeesList"
              />
            </div>
            <div class="extra">
              Custo Financeiro:
              <span class="price">
                {{ formatPricePtBr(allContractData.financialCosts.total) }}
              </span>
            </div>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="box sale">
            <div class="subtitle">
              <aRow type="flex" justify="space-between">
                <aCol :span="12">Lucratividade</aCol>
                <aCol class="cgreen f12">
                  <!-- {{
                                            salesTotalValues()
                                                .totalProfitPercentage
                                        }} -->

                  {{ formatPercentage(allContractData.totalProfitPercentage) }}%
                </aCol>
              </aRow>
            </div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalProfit) }}

              <ContractValuesInfoList
                class="ml-5"
                :arr="allContractData.profitList"
              />
            </div>
            <div class="extra"></div>
          </div>
        </aCol>
      </aRow>
    </div>

    <div v-if="loadingNewSale == false" class="content">
      <aRow class="sale-tabs">
        <aCol :span="2" class="tabs-side">
          <div
            class="tab"
            :class="activeTab == 'resume' ? 'active' : ''"
            @click="changeActiveTab('resume')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Resumo</span>
              </template>
              <img
                src="@/assets/images//dashboard/sales/resume.png"
                alt="img"
              />
            </a-tooltip>
          </div>
          <div
            class="tab"
            :class="activeTab == 'receive' ? 'active' : ''"
            @click="changeActiveTab('receive')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Recebimentos</span>
              </template>
              <img
                src="@/assets/images//dashboard/sales/save-money.png"
                alt="img"
              />
            </a-tooltip>
          </div>
          <div
            class="tab"
            :class="activeTab == 'topay' ? 'active' : ''"
            @click="changeActiveTab('topay')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Pagamentos</span>
              </template>
              <img
                src="@/assets/images//dashboard/sales/receive.png"
                alt="img"
              />
            </a-tooltip>
          </div>

          <div
            class="tab"
            :class="activeTab == 'taxesIncentives' ? 'active' : ''"
            @click="changeActiveTab('taxesIncentives')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Taxas & Incentivos</span>
              </template>
              <img src="@/assets/images//dashboard/sales/tax.png" alt="img" />
            </a-tooltip>
          </div>

          <div
            class="tab"
            :class="activeTab == 'docs' ? 'active' : ''"
            @click="changeActiveTab('docs')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Documentos</span>
              </template>
              <img
                src="@/assets/images//dashboard/sales/folder.png"
                alt="img"
                width="35"
              />
            </a-tooltip>
          </div>

          <div
            v-if="
              strToJson(sale.tempContract.marketing_types).includes('Livelo')
            "
            class="tab"
            :class="activeTab == 'marketing' ? 'active' : ''"
            @click="changeActiveTab('marketing')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Marketing</span>
              </template>
              <img src="@/assets/images//dashboard/sales/mkt.png" alt="img" />
            </a-tooltip>
          </div>

          <div
            class="tab"
            :class="activeTab == 'notes' ? 'active' : ''"
            @click="changeActiveTab('notes')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Notas</span>
              </template>
              <img src="@/assets/images//dashboard/sales/notes.png" alt="img" />
            </a-tooltip>
          </div>

          <div
            class="tab"
            :class="activeTab == 'logs' ? 'active' : ''"
            @click="changeActiveTab('logs')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Logs</span>
              </template>
              <img src="@/assets/images//dashboard/sales/logs.png" alt="img" />
            </a-tooltip>
          </div>

          <a-divider style="margin: 5px 0" />

          <div
            v-if="
              strToJson(sale.tempContract.contracted_services).includes('hotel')
            "
            class="tab"
            :class="activeTab == 'hotel' ? 'active' : ''"
            @click="changeActiveTab('hotel')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Hotel/Resort</span>
              </template>
              <img src="@/assets/images//dashboard/sales/hotel.png" alt="img" />
            </a-tooltip>
          </div>

          <div
            v-if="
              strToJson(sale.tempContract.contracted_services).includes(
                'flight'
              )
            "
            class="tab"
            :class="activeTab == 'flight' ? 'active' : ''"
            @click="changeActiveTab('flight')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Aéreo</span>
              </template>
              <img src="@/assets/images//dashboard/sales/plane.png" alt="img" />
            </a-tooltip>
          </div>

          <div
            v-if="
              strToJson(sale.tempContract.contracted_services).includes(
                'service'
              )
            "
            class="tab"
            :class="activeTab == 'service' ? 'active' : ''"
            @click="changeActiveTab('service')"
          >
            <a-tooltip placement="right">
              <template slot="title">
                <span>Serviço</span>
              </template>
              <img
                src="@/assets/images//dashboard/sales/services-ico.png"
                alt="img"
              />
            </a-tooltip>
          </div>
        </aCol>

        <aCol class="tabs-content" v-if="loadingNewSale == false" :span="22">
          <div v-if="activeTab == 'resume'" class="tab">
            <h2>
              <aRow type="flex" justify="space-between">
                <aCol>
                  <span style="position: relative; top: 5px">RESUMO</span>
                </aCol>

                <aCol>
                  <a-tooltip placement="left">
                    <template slot="title">
                      Este botão apenas atualizará os valores da tabela de
                      vendas, se for necessário.
                    </template>
                    <a-button
                      @click="updateSale(true)"
                      class="mr-10 f12 update-sale"
                      type="primary"
                      size="small"
                      :loading="loadingUpdateSale"
                    >
                      <a-icon
                        :type="!loadingUpdateSale ? 'dollar' : 'laoding'"
                      />
                      Atualizar valores
                    </a-button>
                  </a-tooltip>
                </aCol>
              </aRow>
            </h2>
            <ResumeTab :tempContract="sale.tempContract" :sale="sale.details" />
          </div>

          <div v-if="activeTab == 'receive'" class="tab">
            <h2>RECEBIMENTOS</h2>
            <ToReceiveTab
              @updateTempContract="updateTempContract"
              :tempContract="sale.tempContract"
              :sale="sale.details"
            />
          </div>

          <div v-if="activeTab == 'topay'" class="tab">
            <h2>PAGAMENTOS</h2>
            <ToPayTab
              @updateTempContract="updateTempContract"
              :tempContract="sale.tempContract"
              :sale="sale.details"
            />
          </div>

          <div v-if="activeTab == 'taxesIncentives'" class="tab">
            <h2>TAXAS & INCENTIVOS</h2>
            <TaxesIncentivesTab
              @reloadSale="reloadSale"
              :tempContract="sale.tempContract"
              :sale="sale.details"
            />
          </div>

          <div v-if="activeTab == 'docs'" class="tab">
            <h2>DOCUMENTOS</h2>
            <SaleDocumentsTab
              :tempContract="sale.tempContract"
              :sale="sale"
              @reloadSale="reloadSale"
            />
          </div>

          <div v-if="activeTab == 'marketing'" class="tab">
            <h2>MARKETING</h2>
            <MarketingTab
              :tempContract="sale.tempContract"
              :sale="sale.details"
            />
          </div>

          <div v-if="activeTab == 'settings'" class="tab">
            <h2>CONFIGURAÇÕES</h2>
            <SettingsTab
              :tempContract="sale.tempContract"
              :sale="sale.details"
            />
          </div>

          <div v-if="activeTab == 'notes'" class="tab">
            <h2>NOTAS</h2>

            <Notes
              class="mt-30"
              listType="multi"
              moduleName="sale"
              :entry="sale.details"
              :noteReasonsCategoryid="21"
              :moduleId="`${sale.details.id}`"
              :multiIds="[
                {
                  module: 'opportunity',
                  id: sale.details.raw.opportunity_id,
                },
                {
                  module: 'contract',
                  id: sale.details.raw.contract_id,
                },
                {
                  module: 'sale',
                  id: sale.details.id,
                },
              ]"
              style="padding: 0 30px 0 0"
            />
          </div>

          <div v-if="activeTab == 'logs'" class="tab">
            <h2>LOG DE ALTERAÇÕES</h2>

            <MultiLogsTimeline
              class="mt-10"
              :opportunityId="sale.details.raw.opportunity_id"
              :contractId="sale.details.raw.contract_id"
              :saleId="sale.details.id"
            />
          </div>

          <div v-if="activeTab == 'hotel'" class="tab">
            <h2>HOTEL / RESORT</h2>
            <HotelTab :tempContract="sale.tempContract" :sale="sale.details" />
          </div>

          <div v-if="activeTab == 'flight'" class="tab">
            <h2>PASSAGEM AÉREA</h2>
            <FlightTab :tempContract="sale.tempContract" :sale="sale.details" />
          </div>

          <div v-if="activeTab == 'service'" class="tab">
            <h2>SERVIÇO</h2>
            <ServiceTab
              :tempContract="sale.tempContract"
              :sale="sale.details"
            />
          </div>

          <div>
            <div
              id="live-debug"
              style="
                display: none;
                height: 300px;
                max-width: 98%;
                overflow: auto;
                overflow-x: hidden;
              "
            >
              <json-viewer
                v-if="$store.state.userData.id == 1"
                class="mt-10 mb-20"
                :value="allContractData"
                :show-array-index="false"
                :expand-depth="1"
              ></json-viewer>
              <json-viewer
                v-if="$store.state.userData.id == 1"
                class="mt-10 mb-20"
                :value="sale.tempContract"
                :show-array-index="false"
                :expand-depth="1"
              ></json-viewer>
            </div>
          </div>
        </aCol>
      </aRow>

      <div class="footer">
        <aRow>
          <aCol :span="4">
            <div class="box">
              <div class="travel-input search-sale">
                <a-input
                  v-model="searchSaleId"
                  placeholder="ID Venda + Enter"
                  @blur="searchSale()"
                  @pressEnter="searchSale()"
                >
                </a-input>
              </div>
            </div>
          </aCol>

          <aCol :span="2">
            <div class="box a-center" style="margin-top: -3px">
              <a-tooltip>
                <template slot="title">
                  {{ sale.details.user.first_name }}
                  {{ sale.details.user.last_name }}
                </template>
                <a
                  class="user"
                  :href="`/users/view/${sale.details.user.id}`"
                  v-if="sale.details.user != undefined"
                  target="_blank"
                >
                  <span id="avatar">
                    <a-avatar
                      v-if="sale.details.user.avatar"
                      :src="sale.details.user.avatar"
                      :size="40"
                    />
                    <a-avatar
                      v-else
                      class="upper"
                      style="color: #f56a00; background-color: #fde3cf"
                    >
                      {{ sale.details.user.first_name.substring(0, 1)
                      }}{{ sale.details.user.last_name.substring(0, 1) }}
                    </a-avatar>
                  </span>
                </a>
              </a-tooltip>
            </div>
          </aCol>

          <aCol :span="2">
            <div class="box" style="margin-top: -3px">
              <div class="f10" style="color: #aaa">Contrato:</div>

              <div class="f12">
                <a
                  v-if="sale.details.contract != undefined"
                  class="user"
                  :href="`/contracts/edit/${sale.details.contract.id}`"
                  target="_blank"
                >
                  {{ sale.details.contract.id }}
                </a>
              </div>
            </div>
          </aCol>
          <aCol v-if="sale.details.opportunity != 0" :span="2">
            <div class="box" style="margin-top: -3px">
              <div class="f10" style="color: #aaa">Oportunida...</div>

              <div class="f12">
                <a
                  class="user"
                  :href="`/opportunity/edit/${sale.details.opportunity}`"
                  target="_blank"
                >
                  {{ sale.details.opportunity }}
                </a>
              </div>
            </div>
          </aCol>

          <aCol :span="5">
            <div class="box" style="margin-top: -3px; border-right: 0">
              <div v-if="!saleDate.edit">
                <div class="f10" style="color: #aaa">Data da venda:</div>

                <div class="f12">
                  {{
                    sale.details.sale_date
                      ? formatMultiDates(sale.details.sale_date)
                      : "---"
                  }}
                  <a-icon
                    v-if="sale.details.status != 'Finalizado'"
                    type="edit"
                    @click="editSaleDate"
                  />
                </div>
              </div>

              <div v-if="saleDate.edit">
                <a-date-picker
                  class="travel-input f10"
                  placeholder="Data da venda"
                  format="DD/MM/YYYY"
                  :showToday="false"
                  :allowClear="true"
                  v-mask="'##/##/####'"
                  v-model="saleDate.date"
                  style="width: 90%"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-date-picker>
                <a-icon class="cgreen" type="check" @click="updateSaleDate" />
              </div>
            </div>
          </aCol>

          <aCol class="a-right pt-5 pr-10" :span=" sale.details.opportunity != 0 ? 9 : 11">
            <a-tooltip v-if="sale.details.status != 'Cancelado'">
              <template slot="title"> Cancelar esta venda </template>
              <a-button
                size="large"
                class="cancel-btn ml-10"
                type="danger"
                @click="cancel.modal = true"
              >
                <a-icon type="close" />
              </a-button>
            </a-tooltip>

            <a-tooltip
              v-if="
                sale.details.status != 'Cancelado' &&
                sale.details.status != 'Finalizado'
              "
              class="ml-10"
            >
              <template slot="title"> Finalizar a venda! </template>

              <a-popconfirm
                ok-text="Sim"
                cancel-text="Não"
                :disabled="blockFinishSale()"
                @confirm="concludeSale"
              >
                <template slot="title">
                  Deseja <b>CONCLUIR</b> esta <br />venda?
                </template>
                <a-button
                  size="large"
                  class="finish-btn"
                  type="primary"
                  style="width: 200px"
                  :disabled="blockFinishSale()"
                >
                  FINALIZAR
                </a-button>
              </a-popconfirm>
            </a-tooltip>
          </aCol>
        </aRow>

        <a-modal
          :title="'CANCELAR VENDA #' + sale.details.id"
          :visible="cancel.modal"
          :footer="false"
          @cancel="cancel.modal = false"
        >
          <aRow v-if="cancel.modal">
            <aCol :span="24">
              <div class="travel-input-outer mt-20" style="position: relative">
                <label class="filled"> Motivo do cancelamento </label>
                <a-select
                  class="travel-input"
                  placeholder="Selecione o motivo  do cancelamento"
                  style="width: 100%"
                  v-model="cancel.reason"
                >
                  <a-select-option
                    v-for="(reason, index) of cancelationReasonsList"
                    :key="index"
                    :value="reason.name"
                  >
                    {{ reason.name }}
                  </a-select-option>
                </a-select>
              </div>
            </aCol>

            <aCol class="mt-20" :span="24">
              <a-textarea
                v-model="cancel.obs"
                placeholder="Observações"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </aCol>

            <aCol class="a-center mt-20" :span="24">
              <a-popconfirm
                ok-text="Sim"
                cancel-text="Não"
                @confirm="cancelSale"
              >
                <template slot="title">
                  tem certeza que deseja <br />
                  <b>CANCELAR</b> esta venda?
                </template>
                <a-button
                  :disabled="
                    cancel.reason == undefined ||
                    cancel.reason == '' ||
                    cancel.obs == undefined ||
                    cancel.obs == ''
                      ? true
                      : false
                  "
                  size="large"
                  class="cancel-btn ml-10"
                  type="danger"
                >
                  Cancelar venda
                </a-button>
              </a-popconfirm>
            </aCol>
          </aRow>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

import saleMixins from "@/mixins/sales/saleMixins.js";
import formatThings from "@/mixins/general/formatThings.js";
import ResumeTab from "../tabs/ResumeTab.vue";
import ToReceiveTab from "../tabs/ToReceiveTab.vue";
import ToPayTab from "../tabs/ToPayTab.vue";
import SettingsTab from "../tabs/SettingsTab.vue";
import HotelTab from "../tabs/HotelTab.vue";
import FlightTab from "../tabs/FlightTab.vue";
import ServiceTab from "../tabs/ServiceTab.vue";
import MultiLogsTimeline from "../../logs/MultiLogsTimeline.vue";
import Notes from "../../general/Notes.vue";
import MarketingTab from "../tabs/marketingTab.vue";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import ContractValuesInfoList from "@/components/contracts/lists/ContractValuesInfoList.vue";
import TaxesIncentivesTab from "../tabs/TaxesIncentivesTab.vue";
import SaleDocumentsTab from "../tabs/SaleDocumentsTab.vue";

export default {
  props: {
    id: String,
  },
  components: {
    ResumeTab,
    HotelTab,
    FlightTab,
    ServiceTab,
    SettingsTab,
    ToReceiveTab,
    ToPayTab,
    MultiLogsTimeline,
    Notes,
    MarketingTab,
    TaxesIncentivesTab,
    ContractValuesInfoList,
    SaleDocumentsTab,
  },
  mixins: [saleMixins, formatThings, contractMixins],
  data() {
    return {
      loadingUpdateSale: false,
      tempContract: {},
      searchSaleId: "",
      activeTab: "resume",
      saleDate: {
        date: undefined,
        edit: false,
      },
      cancel: {
        modal: false,
        loading: false,
        reason: undefined,
        obs: undefined,
      },
    };
  },
  mounted() {
    this.loadingNewSale = true;
    this.contract.isSale = true;
    this.getSale(this.id);

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=50&category_id=17&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.cancelationReasonsList = data.data;
      });
  },
  methods: {
    getSale(id) {
      this.$http
        .post(`/sale/details?id=${id}`)
        .then(({ data }) => {
          this.loadingNewSale = false;
          this.sale.details = data;

          let name = "";

          if (data.customer.person_type == "Pessoa Física")
            name = `VENDA ${data.id} - ${data.customer.first_name}  ${data.customer.last_name}`;
          if (data.customer.person_type == "Pessoa Jurídica")
            name = `VENDA ${data.id} - ${data.customer.trading_name} `;

          document.title = name;

          // CONTRATO TEMPORARIO
          this.sale.tempContract = {
            ...data.contract,
            package_type: data.contract.package_type
              ? data.contract.package_type
              : "Serviços",
            the_customer: data.customer,
          };

          let allFieldsSize = Object.values(data.contract.meta).length,
            values = Object.values(data.contract.meta),
            keys = Object.keys(data.contract.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.sale.tempContract[keys[i]] = values[i];
          }

          delete this.sale.tempContract.meta;

          this.contractPricingData(this.sale.tempContract);

          setTimeout(() => {
            this.verifyAndAutoUpdateSaleValues();
          }, 200);

          this.saleForm.setFieldsValue(data);
        })
        .catch(({ response }) => {
          response;
        });
    },
    verifyAndAutoUpdateSaleValues() {
      let value = this.allContractData.total,
        value_to_pay = this.allContractData.totalNet.toFixed(2),
        profit_value = this.allContractData.totalProfit.toFixed(2),
        taxes_value = this.allContractData.totalTaxes.toFixed(2),
        embark_taxes_value =
          this.allContractData.flight.sale.netTaxes.toFixed(2),
        value_to_receive = this.allContractData.totalToReceive.toFixed(2);

      if (
        value == this.sale.details.raw.value &&
        value_to_pay == this.sale.details.raw.value_to_pay &&
        profit_value == this.sale.details.raw.profit_value &&
        taxes_value == this.sale.details.raw.taxes_value &&
        embark_taxes_value == this.sale.details.raw.embark_taxes_value &&
        value_to_receive == this.sale.details.raw.value_to_receive
      ) {
        //console.log('verifyAndAutoUpdateSaleValues igual',);
      } else {
        //console.log('verifyAndAutoUpdateSaleValues diferente',);
        this.updateSale(false);
      }
    },
    creditCardPaymentIsWithSupplier() {
      let flag = false,
        creditCardRows =
          this.sale.tempContract.credit_card_rows != undefined
            ? JSON.parse(this.sale.tempContract.credit_card_rows)
            : [];

      if (creditCardRows.length > 0) {
        creditCardRows.forEach((cCard) => {
          if (
            this.sale.tempContract[
              `payment_methods_credit_card_machine_id_${cCard.id}`
            ] == 14
          ) {
            flag = true;
          }
        });
      }

      return flag;
    },
    creditCardPaymentIsNoSupplier() {
      let flag = false,
        creditCardRows =
          this.sale.tempContract.credit_card_rows != undefined
            ? JSON.parse(this.sale.tempContract.credit_card_rows)
            : [];

      if (creditCardRows.length > 0) {
        creditCardRows.forEach((cCard) => {
          if (
            this.sale.tempContract[
              `payment_methods_credit_card_machine_id_${cCard.id}`
            ] != 14
          ) {
            flag = true;
          }
        });
      }

      return flag;
    },
    salesTotalValues() {
      const packageType = this.sale.tempContract[`package_type`],
        paymentMethods =
          this.sale.tempContract[`payment_methods`] != undefined
            ? JSON.parse(this.sale.tempContract[`payment_methods`])
            : [],
        contractedServices =
          this.sale.tempContract[`contracted_services`] != undefined
            ? JSON.parse(this.sale.tempContract[`contracted_services`])
            : [],
        marketingTypes =
          this.sale.tempContract[`marketing_types`] != undefined &&
          this.sale.tempContract[`marketing_types`] != ""
            ? JSON.parse(this.sale.tempContract[`marketing_types`])
            : [];

      let totalSale = 0,
        totalToReceive = 0,
        totalToPay = 0,
        totalPaid = 0,
        totalFinancialCost = 0,
        totalProfit = 0,
        totalProfitPercentage = 0,
        totalProfitCalc = 0,
        taxes = { totalValue: 0, rows: [] },
        thirdPartyPackage = { totalValue: 0, netValue: 0 },
        hotel = { totalValue: 0, rows: [] },
        flight = { totalValue: 0, rows: [] },
        service = { totalValue: 0, rows: [] },
        creditCard = { totalValue: 0, rows: [] };

      thirdPartyPackage,
        hotel,
        flight,
        service,
        creditCard,
        totalProfit,
        contractedServices,
        marketingTypes;

      if (packageType == "Serviços" || packageType == "Reserva Online") {
        if (contractedServices.includes("hotel")) {
          hotel.rows =
            this.sale.tempContract.hotel_rows != undefined
              ? JSON.parse(this.sale.tempContract.hotel_rows)
              : [];
          hotel.rows.forEach((hotel) => {
            hotel.totalValue =
              this.sale.tempContract[`hotel_${hotel.id}_total_price`];

            if (hotel.totalValue) {
              hotel.totalValue = hotel.totalValue.replace(",", ".");
              if (isNaN(hotel.totalValue)) {
                totalSale += parseFloat(0);
              } else {
                totalSale += parseFloat(hotel.totalValue);
              }
            } else {
              totalSale += parseFloat(0);
            }

            totalToPay += parse(
              this.sale.tempContract[
                `contract_finances_hotel_${hotel.id}_net_value`
              ]
            );

            if (
              this.sale.tempContract[
                `contract_finances_hotel_${hotel.id}_audit_status`
              ] == "Realizado"
            ) {
              totalPaid += parse(
                this.sale.tempContract[
                  `contract_finances_hotel_${hotel.id}_net_value`
                ]
              );
            }

            // INCENTIVOS
            if (
              this.sale.tempContract[
                `contract_finances_hotel_${hotel.id}_incentives_rows`
              ] != undefined
            ) {
              JSON.parse(
                this.sale.tempContract[
                  `contract_finances_hotel_${hotel.id}_incentives_rows`
                ]
              ).forEach((incentive) => {
                if (
                  this.sale.tempContract[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_type`
                  ] == "%"
                ) {
                  totalSale += parseFloat(
                    this.sale.tempContract[
                      `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  );
                }

                totalSale += parse(
                  this.sale.tempContract[
                    `contract_finances_hotel_${hotel.id}_incentive_${incentive.id}_value`
                  ]
                );
              });
            }

            if (
              this.sale.tempContract[
                `contract_finances_hotel_${hotel.id}_taxes_rows`
              ] != undefined
            ) {
              JSON.parse(
                this.sale.tempContract[
                  `contract_finances_hotel_${hotel.id}_taxes_rows`
                ]
              ).forEach((tax) => {
                if (
                  this.sale.tempContract[
                    `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`
                  ] &&
                  this.sale.tempContract[
                    `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                  ]
                ) {
                  taxes.rows.push({
                    name: `${
                      this.sale.tempContract[
                        `contract_finances_hotel_${hotel.id}_tax_${tax.id}_type`
                      ]
                    } <span class='cprimary' style="font-size: 10px"><i>Pacote</i></span> `,
                    value: `${this.formatPricePtBr(
                      this.sale.tempContract[
                        `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                      ]
                    )}`,
                  });

                  taxes.totalValue += parse(
                    this.sale.tempContract[
                      `contract_finances_hotel_${hotel.id}_tax_${tax.id}_value`
                    ]
                  );
                }
              });
            }
          });
        }

        if (contractedServices.includes("service")) {
          service.rows =
            this.sale.tempContract.service_rows != undefined
              ? JSON.parse(this.sale.tempContract.service_rows)
              : [];
          service.rows.forEach((service) => {
            service.totalValue =
              this.sale.tempContract[`service_${service.id}_total_price`];

            if (service.totalValue) {
              service.totalValue = service.totalValue.replace(",", ".");
              if (isNaN(service.totalValue)) {
                totalSale += parseFloat(0);
              } else {
                totalSale += parseFloat(service.totalValue);
              }
            } else {
              totalSale += parseFloat(0);
            }

            totalToPay += parse(
              this.sale.tempContract[
                `contract_finances_service_${service.id}_net_value`
              ]
            );

            if (
              this.sale.tempContract[
                `contract_finances_service_${service.id}_audit_status`
              ] == "Realizado"
            ) {
              totalPaid += parse(
                this.sale.tempContract[
                  `contract_finances_service_${service.id}_net_value`
                ]
              );
            }

            // INCENTIVOS
            if (
              this.sale.tempContract[
                `contract_finances_service_${service.id}_incentives_rows`
              ] != undefined
            ) {
              JSON.parse(
                this.sale.tempContract[
                  `contract_finances_service_${service.id}_incentives_rows`
                ]
              ).forEach((incentive) => {
                if (
                  this.sale.tempContract[
                    `contract_finances_service_${service.id}_incentive_${incentive.id}_type`
                  ] == "%"
                ) {
                  totalSale += parseFloat(
                    this.sale.tempContract[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  );
                }

                totalSale += parse(
                  this.sale.tempContract[
                    `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                  ]
                );
              });
            }

            // TAXAS EXTRA
            if (
              this.sale.tempContract[
                `contract_finances_service_${service.id}_taxes_rows`
              ] != undefined
            ) {
              JSON.parse(
                this.sale.tempContract[
                  `contract_finances_service_${service.id}_taxes_rows`
                ]
              ).forEach((tax) => {
                if (
                  this.sale.tempContract[
                    `contract_finances_service_${service.id}_tax_${tax.id}_type`
                  ] &&
                  this.sale.tempContract[
                    `contract_finances_service_${service.id}_tax_${tax.id}_value`
                  ]
                ) {
                  taxes.rows.push({
                    name: `${
                      this.sale.tempContract[
                        `contract_finances_service_${service.id}_tax_${tax.id}_type`
                      ]
                    } <span class='cprimary' style="font-size: 10px"><i>Pacote</i></span> `,
                    value: `${this.formatPricePtBr(
                      this.sale.tempContract[
                        `contract_finances_service_${service.id}_tax_${tax.id}_value`
                      ]
                    )}`,
                  });

                  taxes.totalValue += parse(
                    this.sale.tempContract[
                      `contract_finances_service_${service.id}_tax_${tax.id}_value`
                    ]
                  );
                }
              });
            }
          });
        }

        if (contractedServices.includes("flight")) {
          flight.rows =
            this.sale.tempContract.flight_sections_rows != undefined
              ? JSON.parse(this.sale.tempContract.flight_sections_rows)
              : [];

          flight.rows.forEach((f) => {
            f.travellers.forEach((traveller) => {
              totalSale += parse(
                this.sale.tempContract[
                  `flight_${f.id}_traveller_${traveller.id}_value`
                ]
              );

              if (this.sale.tempContract[`flight_${f.id}_type`] == "Regular") {
                totalToPay += parse(
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_value`
                  ]
                );

                if (
                  this.sale.tempContract[
                    `contract_finances_flight_${f.id}_audit_status`
                  ] == "Realizado"
                ) {
                  totalPaid += parse(
                    this.sale.tempContract[
                      `flight_${f.id}_traveller_${traveller.id}_value`
                    ]
                  );
                }

                taxes.totalValue += parse(
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_taxes`
                  ]
                );

                if (
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_taxes`
                  ] != 0
                ) {
                  taxes.rows.push({
                    name: `Tx. Embarque  <span class='cprimary' style="font-size: 10px"><i>Aéreo ${f.id} Psg ${traveller.id}</i></span>`,
                    value: `R$ ${
                      this.sale.tempContract[
                        `flight_${f.id}_traveller_${traveller.id}_taxes`
                      ]
                    }`,
                  });
                }
              } else {
                if (
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_taxes_currency`
                  ] == "BRL"
                ) {
                  taxes.totalValue += parse(
                    this.sale.tempContract[
                      `flight_${f.id}_traveller_${traveller.id}_taxes`
                    ]
                  );

                  if (
                    this.sale.tempContract[
                      `contract_finances_flight_miles_taxes_audit_status`
                    ] == "Realizado"
                  ) {
                    totalPaid += parse(
                      this.sale.tempContract[
                        `flight_${f.id}_traveller_${traveller.id}_taxes`
                      ]
                    );
                  }

                  taxes.rows.push({
                    name: `Tx. Embarque <span class='cprimary' style="font-size: 10px"><i>Aéreo ${f.id} Psg ${traveller.id}</i></span>`,
                    value: `R$ ${
                      this.sale.tempContract[
                        `flight_${f.id}_traveller_${traveller.id}_taxes`
                      ]
                    }`,
                  });
                }

                if (
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_taxes_currency`
                  ] == "EUR" ||
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_taxes_currency`
                  ] == "USD"
                ) {
                  if (this.sale.tempContract["voucher_payments_exchange"]) {
                    taxes.totalValue +=
                      parse(
                        this.sale.tempContract["voucher_payments_exchange"]
                      ) *
                      parse(
                        this.sale.tempContract[
                          `flight_${f.id}_traveller_${traveller.id}_taxes`
                        ]
                      );

                    if (
                      this.sale.tempContract[
                        `contract_finances_flight_miles_taxes_audit_status`
                      ] == "Realizado"
                    ) {
                      totalPaid +=
                        parse(
                          this.sale.tempContract["voucher_payments_exchange"]
                        ) *
                        parse(
                          this.sale.tempContract[
                            `flight_${f.id}_traveller_${traveller.id}_taxes`
                          ]
                        );
                    }
                  }

                  totalSale +=
                    parse(this.sale.tempContract["voucher_payments_exchange"]) *
                    parse(
                      this.sale.tempContract[
                        `flight_${f.id}_traveller_${traveller.id}_taxes`
                      ]
                    );

                  taxes.rows.push({
                    name: `Tx. Embarque <span class='cprimary' style="font-size: 10px"><i>Aéreo ${f.id} Psg ${traveller.id}</i></span>`,
                    value: `${this.formatPricePtBr(
                      parse(
                        this.sale.tempContract["voucher_payments_exchange"]
                      ) *
                        parse(
                          this.sale.tempContract[
                            `flight_${f.id}_traveller_${traveller.id}_taxes`
                          ]
                        )
                    )}`,
                  });
                } else {
                  totalSale += parse(
                    this.sale.tempContract[
                      `flight_${f.id}_traveller_${traveller.id}_taxes`
                    ]
                  );
                }
              }

              if (this.sale.tempContract[`flight_${f.id}_type`] == "Regular") {
                totalSale += parse(
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_taxes`
                  ]
                );

                totalSale += parse(
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_teb_agency`
                  ]
                );

                totalSale += parse(
                  this.sale.tempContract[
                    `flight_${f.id}_traveller_${traveller.id}_du_tax`
                  ]
                );
              }
            });

            if (this.sale.tempContract[`flight_${f.id}_type`] == "Milhas") {
              if (
                this.sale.tempContract[
                  `contract_finances_flight_${f.id}_audit_status`
                ] == "Realizado"
              ) {
                totalPaid += this.parseAnyNumber(
                  this.sale.tempContract[
                    `contract_finances_flight_${f.id}_sale_value`
                  ]
                );
              }
            }

            // INCENTIVOS
            if (
              this.sale.tempContract[
                `contract_finances_flight_${f.id}_incentives_rows`
              ] != undefined
            ) {
              JSON.parse(
                this.sale.tempContract[
                  `contract_finances_flight_${f.id}_incentives_rows`
                ]
              ).forEach((incentive) => {
                if (
                  this.sale.tempContract[
                    `contract_finances_flight_${f.id}_incentive_${incentive.id}_type`
                  ] == "%"
                ) {
                  totalProfit += parseFloat(
                    this.sale.tempContract[
                      `contract_finances_flight_${f.id}_incentive_${incentive.id}_percentage_value`
                    ]
                  );
                } else {
                  totalProfit += parse(
                    this.sale.tempContract[
                      `contract_finances_flight_${f.id}_incentive_${incentive.id}_value`
                    ]
                  );
                }
              });
            }

            // TAXAS EXTRA
            if (
              this.sale.tempContract[
                `contract_finances_flight_${f.id}_taxes_rows`
              ] != undefined
            ) {
              JSON.parse(
                this.sale.tempContract[
                  `contract_finances_flight_${f.id}_taxes_rows`
                ]
              ).forEach((tax) => {
                if (
                  this.sale.tempContract[
                    `contract_finances_flight_${f.id}_tax_${tax.id}_type`
                  ] &&
                  this.sale.tempContract[
                    `contract_finances_flight_${f.id}_tax_${tax.id}_value`
                  ]
                ) {
                  taxes.rows.push({
                    name: `${
                      this.sale.tempContract[
                        `contract_finances_flight_${f.id}_tax_${tax.id}_type`
                      ]
                    } <span class='cprimary' style="font-size: 10px"><i>Pacote</i></span> `,
                    value: `${this.formatPricePtBr(
                      this.sale.tempContract[
                        `contract_finances_flight_${f.id}_tax_${tax.id}_value`
                      ]
                    )}`,
                  });

                  taxes.totalValue += parse(
                    this.sale.tempContract[
                      `contract_finances_flight_${f.id}_tax_${tax.id}_value`
                    ]
                  );

                  totalSale += parseFloat(
                    this.sale.tempContract[
                      `contract_finances_flight_${f.id}_tax_${tax.id}_value`
                    ]
                  );
                }
              });
            }

            if (this.sale.tempContract[`flight_${f.id}_type`] == "Milhas") {
              totalToPay += parseFloat(
                this.sale.tempContract[
                  `contract_finances_flight_${f.id}_sale_value`
                ]
              );
            }
          });
        }
      } else {
        thirdPartyPackage.totalValue =
          this.sale.tempContract[`package_total_price`];

        thirdPartyPackage.netValue =
          this.sale.tempContract[`contract_finances_package_net_value`];

        if (thirdPartyPackage.totalValue) {
          thirdPartyPackage.totalValue = thirdPartyPackage.totalValue.replace(
            ",",
            "."
          );
          if (isNaN(thirdPartyPackage.totalValue)) {
            totalSale += parseFloat(0);
          } else {
            totalSale += parseFloat(thirdPartyPackage.totalValue);
          }
        } else {
          totalSale += parseFloat(0);
        }

        if (thirdPartyPackage.netValue) {
          thirdPartyPackage.netValue = thirdPartyPackage.netValue.replace(
            ",",
            "."
          );
          if (isNaN(thirdPartyPackage.netValue)) {
            totalToPay += parseFloat(0);
          } else {
            totalToPay += parseFloat(thirdPartyPackage.netValue);
          }
        } else {
          totalToPay += parseFloat(0);
        }

        if (
          this.sale.tempContract[`contract_finances_package_audit_status`] ==
          "Realizado"
        ) {
          totalPaid += parse(
            this.sale.tempContract[`contract_finances_package_net_value`]
          );
        }

        if (
          this.sale.tempContract[`contract_finances_package_incentives_rows`] !=
          undefined
        ) {
          JSON.parse(
            this.sale.tempContract[`contract_finances_package_incentives_rows`]
          ).forEach((incentive) => {
            if (
              this.sale.tempContract[
                `contract_finances_package_incentive_${incentive.id}_type`
              ] == "%"
            ) {
              totalSale += parseFloat(
                this.sale.tempContract[
                  `contract_finances_package_incentive_${incentive.id}_percentage_value`
                ]
              );
            }

            totalSale += parse(
              this.sale.tempContract[
                `contract_finances_package_incentive_${incentive.id}_value`
              ]
            );
          });
        }

        if (
          this.sale.tempContract[`contract_finances_package_taxes_rows`] !=
          undefined
        ) {
          JSON.parse(
            this.sale.tempContract[`contract_finances_package_taxes_rows`]
          ).forEach((tax) => {
            if (
              this.sale.tempContract[
                `contract_finances_package_tax_${tax.id}_type`
              ] &&
              this.sale.tempContract[
                `contract_finances_package_tax_${tax.id}_value`
              ]
            ) {
              taxes.rows.push({
                name: `${
                  this.sale.tempContract[
                    `contract_finances_package_tax_${tax.id}_type`
                  ]
                } <span class='cprimary' style="font-size: 10px"><i>Pacote</i></font> `,
                value: `${this.formatPricePtBr(
                  this.sale.tempContract[
                    `contract_finances_package_tax_${tax.id}_value`
                  ]
                )}`,
              });

              totalSale += parseFloat(
                this.sale.tempContract[
                  `contract_finances_package_tax_${tax.id}_value`
                ]
              );

              taxes.totalValue += parse(
                this.sale.tempContract[
                  `contract_finances_package_tax_${tax.id}_value`
                ]
              );
            }
          });
        }
      }

      if (paymentMethods.includes("custom-payment")) {
        if (
          this.sale.tempContract[
            `payment_methods_custom_payment_audit_status`
          ] == undefined
        ) {
          totalToReceive += parse(
            this.sale.tempContract[`payment_methods_custom_payment_total_value`]
          );
        }
      }

      if (paymentMethods.includes("payment-card")) {
        if (
          this.sale.tempContract[`payment_methods_payment_card_audit_status`] ==
          undefined
        ) {
          totalToReceive += parse(
            this.sale.tempContract[`payment_methods_payment_card_total_value`]
          );
        }

        totalProfit -= parse(
          this.sale.tempContract
            .payment_methods_payment_card_contract_profit_discount_value
            ? this.sale.tempContract
                .payment_methods_payment_card_contract_profit_discount_value
            : this.sale.tempContract[
                `payment_methods_payment_card_total_profit_value`
              ]
        );
      }

      if (paymentMethods.includes("billing-ticket")) {
        if (
          this.sale.tempContract[
            `payment_methods_billing_ticket_audit_status`
          ] == undefined
        ) {
          totalToReceive += parse(
            this.sale.tempContract[`payment_methods_billing_ticket_total_value`]
          );
        }

        taxes.totalValue += parse(
          this.sale.tempContract[`payment_methods_billing_ticket_tax_value`]
        );

        taxes.rows.push({
          name: `Tx. Boleto <span class='cprimary' style="font-size: 10px"><i>Boleto</i></span>`,
          value: `R$ ${
            this.sale.tempContract[`payment_methods_billing_ticket_tax_value`]
          }`,
        });

        totalFinancialCost += parse(
          this.sale.tempContract[`payment_methods_billing_ticket_tax_value`]
        );
      }

      if (paymentMethods.includes("bank-transfer")) {
        if (
          this.sale.tempContract[
            `payment_methods_bank_transfer_audit_status`
          ] == undefined
        ) {
          totalToReceive += parse(
            this.sale.tempContract[`payment_methods_bank_transfer_total_value`]
          );
        }
      }

      if (paymentMethods.includes("credit-card")) {
        creditCard.rows =
          this.sale.tempContract.credit_card_rows != undefined
            ? JSON.parse(this.sale.tempContract.credit_card_rows)
            : [];
        creditCard.rows.forEach((card) => {
          card.totalValue += parse(
            this.sale.tempContract[
              `payment_methods_credit_card_total_value_${card.id}`
            ]
          );

          if (
            this.sale.tempContract[
              `payment_methods_credit_card_audit_status_${card.id}`
            ] == undefined
          ) {
            totalToReceive += parse(
              this.sale.tempContract[
                `payment_methods_credit_card_total_value_${card.id}`
              ]
            );
          }

          taxes.totalValue +=
            parse(
              this.sale.tempContract[
                `payment_methods_credit_card_advance_fee_value_${card.id}`
              ]
            ) +
            parse(
              this.sale.tempContract[
                `payment_methods_credit_card_tax_value_${card.id}`
              ]
            );

          if (
            this.sale.tempContract[
              `payment_methods_credit_card_machine_id_${card.id}`
            ] != 14
          ) {
            taxes.rows.push({
              name: `Tx. Adm. <span class='cprimary' style="font-size: 10px"><i>C. Crédito ${card.id}</i></span>`,
              value: `R$ ${
                parse(
                  this.sale.tempContract[
                    `payment_methods_credit_card_advance_fee_value_${card.id}`
                  ]
                ) +
                parse(
                  this.sale.tempContract[
                    `payment_methods_credit_card_tax_value_${card.id}`
                  ]
                )
              }`,
            });
          }

          totalFinancialCost +=
            parse(
              this.sale.tempContract[
                `payment_methods_credit_card_advance_fee_value_${card.id}`
              ]
            ) +
            parse(
              this.sale.tempContract[
                `payment_methods_credit_card_tax_value_${card.id}`
              ]
            );
        });
      }

      totalProfitCalc =
        totalSale + totalProfit - (totalToPay + taxes.totalValue);
      totalProfitPercentage = (100 * totalProfitCalc) / totalSale;

      return {
        totalSale,
        totalToReceive,
        totalToPay,
        totalPaid,
        totalFinancialCost,
        totalProfit: totalProfitCalc,
        totalProfitPercentage:
          this.formatPercentage(totalProfitPercentage) + "%",
        taxes: {
          totalValue: taxes.totalValue,
          rows: taxes.rows,
        },
      };
    },
    concludeSale() {
      let values = { ...this.sale.details.raw };

      values.profit_value = this.salesTotalValues().totalProfit;
      values.taxes_value = this.salesTotalValues().taxes.totalValue;
      values.value_to_pay = this.salesTotalValues().totalToPay;
      values.value = this.salesTotalValues().totalSale;
      values.status = "Finalizado";

      values.action = "conclude-sale";
      values.module = "sale";
      values.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} finalizou a venda #${this.sale.details.id}.`;
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.updateContractValues();

      this.$http
        .post("/sale/update", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.saleDate.edit = false;
          this.loadingNewSale = true;
          this.getSale(this.id);

          setTimeout(() => {
            this.loadingNewSale = false;
            this.$emit("closeModal");
          }, 2000);

          this.$http
            .post("/contract/update-meta", {
              id: this.sale.details.raw.contract_id,
              info_name: "sale_status",
              info_value: "Finalizado",
            })
            .then(({ data }) => {
              data;
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    cancelSale() {
      let values = {};
      values.id = this.sale.details.id;
      values.field = "status";
      values.value = "Cancelado";
      values.action = "cancel-sale";
      values.module = "sale";
      values.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cancelou a venda #${this.sale.details.id}. Motivo: ${this.cancel.reason}. Observações: ${this.cancel.obs}`;
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/sale/update-field", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.saleDate.edit = false;
          this.loadingNewSale = true;
          this.getSale(this.id);

          this.cancel.modal = false;

          setTimeout(() => {
            this.loadingNewSale = false;
          }, 2000);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    blockFinishSale() {
      const packageType = this.sale.tempContract[`package_type`],
        paymentMethods =
          this.sale.tempContract[`payment_methods`] != undefined
            ? JSON.parse(this.sale.tempContract[`payment_methods`])
            : [],
        contractedServices =
          this.sale.tempContract[`contracted_services`] != undefined
            ? JSON.parse(this.sale.tempContract[`contracted_services`])
            : [];

      let flag = true,
        creditCard = { totalValue: 0, rows: [] };

      if (packageType == "Serviços" || packageType == "Reserva Online") {
        if (contractedServices.includes("hotel")) {
          JSON.parse(this.sale.tempContract["hotel_rows"]).forEach((hotel) => {
            if (
              this.sale.tempContract[
                `contract_finances_hotel_${hotel.id}_audit_status`
              ] == "Realizado"
            ) {
              flag = false;
            } else {
              flag = true;
            }
          });

          if (flag) {
            return flag;
          }
        }

        if (contractedServices.includes("flight")) {
          JSON.parse(this.sale.tempContract["flight_sections_rows"]).forEach(
            (flight) => {
              if (
                this.sale.tempContract[
                  `contract_finances_flight_${flight.id}_audit_status`
                ] == "Realizado"
              ) {
                flag = false;
              } else {
                flag = true;
              }
            }
          );
          if (flag) {
            return flag;
          }
        }

        if (contractedServices.includes("service")) {
          JSON.parse(this.sale.tempContract["service_rows"]).forEach(
            (service) => {
              if (
                this.sale.tempContract[
                  `contract_finances_service_${service.id}_audit_status`
                ] == "Realizado"
              ) {
                flag = false;
              } else {
                flag = true;
              }
            }
          );
          if (flag) {
            return flag;
          }
        }
      } else {
        if (
          this.sale.tempContract[`contract_finances_package_audit_status`] ==
          "Realizado"
        ) {
          flag = false;
        } else {
          flag = true;
        }

        if (flag) {
          return flag;
        }
      }

      if (paymentMethods.includes("custom-payment")) {
        const customPaymentRows = this.sale.tempContract.custom_payment_rows
          ? JSON.parse(this.sale.tempContract.custom_payment_rows)
          : [];
        const customPaymentsReceivedArr = [];

        if (customPaymentRows.length) {
          customPaymentRows.forEach(({ id }) => {
            if (
              this.sale.tempContract[
                `payment_methods_custom_payment_audit_status_${id}`
              ] === "Realizado"
            )
              customPaymentsReceivedArr.push({ id });
          });

          if (customPaymentRows.length === customPaymentsReceivedArr.length)
            flag = false;

          if (flag) return flag;
        } else {
          if (
            this.sale.tempContract[
              `payment_methods_custom_payment_audit_status`
            ] == "Realizado"
          ) {
            flag = false;
          } else {
            flag = true;
          }
          if (flag) {
            return flag;
          }
        }
      }

      if (paymentMethods.includes("payment-card")) {
        if (
          this.sale.tempContract[`payment_methods_payment_card_audit_status`] ==
          "Realizado"
        ) {
          flag = false;
        } else {
          flag = true;
        }
        if (flag) {
          return flag;
        }
      }

      if (paymentMethods.includes("billing-ticket")) {
        if (
          this.sale.tempContract[
            `payment_methods_billing_ticket_audit_status`
          ] == "Realizado"
        ) {
          flag = false;
        } else {
          flag = true;
        }
        if (flag) {
          return flag;
        }
      }

      if (paymentMethods.includes("bank-transfer")) {
        const bankTransferRows = this.sale.tempContract.bank_transfer_rows
          ? JSON.parse(this.sale.tempContract.bank_transfer_rows)
          : [];
        const bankTransfersReceivedArr = [];
        bankTransfersReceivedArr;

        if (bankTransferRows.length) {
          bankTransferRows.forEach(({ id }) => {
            if (
              this.sale.tempContract[
                `payment_methods_bank_transfer_audit_status_${id}`
              ] === "Realizado"
            )
              bankTransfersReceivedArr.push({ id });
          });

          if (bankTransferRows.length === bankTransfersReceivedArr.length)
            flag = false;

          if (flag) return flag;
        } else {
          if (
            this.sale.tempContract[
              `payment_methods_bank_transfer_audit_status`
            ] == "Realizado"
          ) {
            flag = false;
          } else {
            flag = true;
          }
          if (flag) return flag;
        }
      }

      if (paymentMethods.includes("credit-card")) {
        creditCard.rows =
          this.sale.tempContract.credit_card_rows != undefined
            ? JSON.parse(this.sale.tempContract.credit_card_rows)
            : [];
        creditCard.rows.forEach((card) => {
          card;
          if (
            this.sale.tempContract[
              `payment_methods_credit_card_audit_status_${card.id}`
            ] == "Realizado"
          ) {
            flag = false;
          } else {
            flag = true;
          }
        });
        if (flag) {
          return flag;
        }
      }

      return flag;
    },
    editSaleDate() {
      this.saleDate.edit = true;
      this.saleDate.date = this.sale.details.sale_date;
    },
    updateContractValues() {
      let values = this.sale.details.contract;
      delete values.meta;

      values.value = this.allContractData.total;
      values.value_to_pay = this.allContractData.totalNet;
      values.profit_value = this.allContractData.totalProfit;
      values.taxes_value = this.allContractData.totalTaxes;
      values.profit_percentage = this.allContractData.totalProfitPercentage;
      values.financial_cost = this.allContractData.financialCosts.total;

      values.contract_total_value = this.allContractData.total;
      values.contract_net_value = this.allContractData.totalNet;
      values.contract_profit_value = this.allContractData.totalProfit;
      values.contract_taxes_value = this.allContractData.totalTaxes;
      values.contract_profit_percentage_value =
        this.allContractData.totalProfitPercentage;
      values.contract_financial_cost =
        this.allContractData.financialCosts.total;

      this.$http.post("/contract-v2/update", values).then(() => {
        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: values.id,
          module: "contract",
          action: "update-contract-values",
          description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou os valores do contrato ID ${values.id} a partir da venda ID ${values.sale_id}.`,
        });
      });
    },
    updateSale(updateWithMessage) {
      this.loadingUpdateSale = true;

      this.updateContractValues();

      let values = this.sale.details.raw;
      values.action = "update-sale-date";
      values.module = "sale";
      values.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou os valores da venda ${this.sale.details.id}.`;

      values.value = this.allContractData.total;
      values.value_to_pay = this.allContractData.totalNet;
      values.value_to_receive = this.allContractData.totalToReceive;
      values.profit_value = this.allContractData.totalProfit;
      values.taxes_value = this.allContractData.totalTaxes;
      values.embark_taxes_value = this.allContractData.flight.sale.netTaxes;
      values.profit_percentage = this.allContractData.totalProfitPercentage;

      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/sale/update", values)
        .then(({ data }) => {
          if (updateWithMessage) this.$message.success(data.message);
          this.loadingUpdateSale = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingUpdateSale = false;
        });
    },
    updateSaleDate() {
      let values = {};
      values.id = this.sale.details.id;
      values.field = "sale_date";
      values.value = this.saleDate.date;
      values.action = "update-sale-date";
      values.module = "sale";
      values.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} alterou a data da venda.`;
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/sale/update-field", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.saleDate.edit = false;
          this.loadingNewSale = true;
          this.getSale(this.id);

          setTimeout(() => {
            this.loadingNewSale = false;
          }, 2000);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    reloadSale() {
      this.loadingNewSale = true;
      this.getSale(this.id);
    },
    updateTempContract(newTempContract) {
      this.loadingNewSale = true;
      this.getSale(this.id);
      this.tempContract = newTempContract;

      this.$emit("updateTempContract", newTempContract);

      setTimeout(() => {
        this.loadingNewSale = false;
      }, 2000);
    },
    changeActiveTab(tab) {
      this.activeTab = tab;

      this.verifyAndAutoUpdateSaleValues();
    },
    searchSale() {
      if (this.searchSaleId) {
        this.loadingNewSale = true;
        this.getSale(this.searchSaleId);
        this.searchSaleId = "";
      }
    },
    calcAmountReceivable() {
      let total = 0;

      if (this.sale.tempContract.package_type == "Pacote Completo Terceiros") {
        if (this.sale.tempContract["package_total_price"] != undefined) {
          total += parse(this.sale.tempContract.package_total_price);
        }
      } else {
        ///////////////////////////////////////////////////
        // HOTEL ////////////////////////////////////
        ///////////////////////////////////////////////////
        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "hotel"
          )
        ) {
          let hotelTotal = 0;
          this.strToJson(this.sale.tempContract.hotel_rows).forEach((hotel) => {
            hotelTotal =
              this.sale.tempContract[`hotel_${hotel.id}_total_price`];

            if (hotelTotal) {
              hotelTotal = hotelTotal.replace(",", ".");
              if (isNaN(hotelTotal)) {
                total += parseFloat(0);
              } else {
                total += parseFloat(hotelTotal);
              }
            } else {
              total += parseFloat(0);
            }
          });
        }

        ///////////////////////////////////////////////////
        // AÉREO ////////////////////////////////////
        ///////////////////////////////////////////////////

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "flight"
          )
        ) {
          let totalTax = 0;

          this.strToJson(this.sale.tempContract.flight_sections_rows).forEach(
            (flightSection) => {
              let totalFlight = 0;

              flightSection.travellers.forEach((traveller) => {
                total +=
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_teb_agency`
                    ]
                  ) +
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_du_tax`
                    ]
                  ) +
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_taxes`
                    ]
                  ) +
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_value`
                    ]
                  );

                ///////////////////////////////////////////////////
                // REGULAR ////////////////////////////////////
                ///////////////////////////////////////////////////

                totalFlight =
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_teb_agency`
                    ]
                  ) +
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_du_tax`
                    ]
                  ) +
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_taxes`
                    ]
                  ) +
                  parse(
                    this.sale.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}_value`
                    ]
                  );
              });

              ///////////////////////////////////////////////////
              // TAXAS EXTRAS ////////////////////////////////////
              ///////////////////////////////////////////////////

              if (
                this.sale.tempContract[
                  `contract_finances_flight_${flightSection.id}_taxes_rows`
                ] != undefined
              ) {
                JSON.parse(
                  this.sale.tempContract[
                    `contract_finances_flight_${flightSection.id}_taxes_rows`
                  ]
                ).forEach((tax) => {
                  if (
                    this.sale.tempContract[
                      `contract_finances_flight_${flightSection.id}_tax_${tax.id}_value`
                    ]
                  ) {
                    totalTax += parse(
                      this.sale.tempContract[
                        `contract_finances_flight_${flightSection.id}_tax_${tax.id}_value`
                      ]
                    );
                  }
                });
              }

              totalTax;

              this.sale.tempContract[`flight_${flightSection.id}_total_price`] =
                totalFlight;
            }
          );
        }

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "service"
          )
        ) {
          let serviceTotal = 0;
          this.strToJson(this.sale.tempContract.service_rows).forEach(
            (service) => {
              serviceTotal = parse(
                this.sale.tempContract[`service_${service.id}_total_price`]
              );

              if (serviceTotal) {
                if (isNaN(serviceTotal)) {
                  total += parseFloat(0);
                } else {
                  total += serviceTotal;
                }
              } else {
                total += parseFloat(0);
              }
            }
          );
        }
      }

      return { formattedTotal: this.formatPricePtBr(total), total };
    },
    calcAmountPayable() {
      let total = 0;
      let status = true;

      if (this.sale.tempContract.package_type == "Pacote Completo Terceiros") {
        if (
          this.sale.tempContract[`contract_finances_package_net_value`] != ""
        ) {
          total += parse(
            this.sale.tempContract[`contract_finances_package_net_value`]
          );
        } else {
          status = false;
          total = 0;
        }
      } else {
        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "hotel"
          )
        ) {
          let hotelTotal = 0;
          this.strToJson(this.sale.tempContract.hotel_rows).forEach((hotel) => {
            hotelTotal =
              this.sale.tempContract[
                `contract_finances_hotel_${hotel.id}_net_value`
              ];

            if (hotelTotal) {
              hotelTotal = hotelTotal.replace(",", ".");
              if (isNaN(hotelTotal)) {
                total += parseFloat(0);
              } else {
                total += parseFloat(hotelTotal);
              }
            } else {
              total += parseFloat(0);
            }
          });
        }

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "flight"
          )
        ) {
          let totalTax = 0;

          this.strToJson(this.sale.tempContract.flight_sections_rows).forEach(
            (flightSection) => {
              flightSection.travellers.forEach((traveller) => {
                total += parse(
                  this.sale.tempContract[
                    `flight_${flightSection.id}_traveller_${traveller.id}_value`
                  ]
                );

                ///////////////////////////////////////////////////
                // REGULAR ////////////////////////////////////
                ///////////////////////////////////////////////////
              });

              totalTax;
            }
          );
        }

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "service"
          )
        ) {
          let serviceTotal = 0;
          this.strToJson(this.sale.tempContract.service_rows).forEach(
            (service) => {
              serviceTotal = parse(
                this.sale.tempContract[
                  `contract_finances_service_${service.id}_net_value`
                ]
              );

              if (serviceTotal) {
                if (isNaN(serviceTotal)) {
                  total += parseFloat(0);
                } else {
                  total += serviceTotal;
                }
              } else {
                total += parseFloat(0);
              }
            }
          );
        }
      }

      return {
        formattedTotal: this.formatPricePtBr(total),
        total,
        status,
      };
    },
    calcFeeAmount() {
      let total = 0;
      let taxesArr = [];
      let totalToShow = 0;

      ///////////////////////////////////////////////////
      // PACOTE COMPLETO ////////////////////////////////////
      ///////////////////////////////////////////////////
      if (this.sale.tempContract.package_type == "Pacote Completo Terceiros") {
        //
      } else {
        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "hotel"
          )
        ) {
          //
        }

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "flight"
          )
        ) {
          let totalEmbarkFee = 0;
          this.strToJson(this.sale.tempContract.flight_sections_rows).forEach(
            (flightSection) => {
              flightSection.travellers.forEach((traveller) => {
                totalEmbarkFee += parse(
                  this.sale.tempContract[
                    `flight_${flightSection.id}_traveller_${traveller.id}_taxes`
                  ]
                );

                ///////////////////////////////////////////////////
                // REGULAR ////////////////////////////////////
                ///////////////////////////////////////////////////
              });
            }
          );

          taxesArr.push({
            name: `Taxa de Embarque <span class='cprimary' style="font-size: 10px"><i>Aéreo</i></span>`,
            value: this.formatPricePtBr(totalEmbarkFee),
          });

          totalToShow += totalEmbarkFee;
        }

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "service"
          )
        ) {
          //
        }
      }

      if (this.sale.tempContract.payment_methods != undefined) {
        ///////////////////////////////////////////////////
        // CREDIT CARD ////////////////////////////////////
        ///////////////////////////////////////////////////
        if (
          this.strToJson(
            this.sale.tempContract.payment_methods.includes("credit-card")
          ) &&
          this.sale.tempContract[`credit_card_rows`] != undefined
        ) {
          this.strToJson(this.sale.tempContract[`credit_card_rows`]).forEach(
            (card) => {
              totalToShow +=
                parse(
                  this.sale.tempContract[
                    `payment_methods_credit_card_advance_fee_value_${card.id}`
                  ]
                ) +
                parse(
                  this.sale.tempContract[
                    `payment_methods_credit_card_tax_value_${card.id}`
                  ]
                );

              if (
                this.sale.tempContract[
                  `payment_methods_credit_card_total_tax_discount_${card.id}`
                ] != "0,00"
              ) {
                taxesArr.push({
                  name: `Taxa Administrativa <span class='cprimary' style="font-size: 10px"><i>C. Crédito ${card.id}</i></span>`,
                  value: `${this.formatPricePtBr(
                    parse(
                      this.sale.tempContract[
                        `payment_methods_credit_card_advance_fee_value_${card.id}`
                      ]
                    ) +
                      parse(
                        this.sale.tempContract[
                          `payment_methods_credit_card_tax_value_${card.id}`
                        ]
                      )
                  )}`,
                });
              }
            }
          );
        }

        ///////////////////////////////////////////////////
        // BILLING TICKET ////////////////////////////////////
        ///////////////////////////////////////////////////
        if (
          this.strToJson(this.sale.tempContract.payment_methods).includes(
            "billing-ticket"
          )
        ) {
          totalToShow += parse(
            this.sale.tempContract[`payment_methods_billing_ticket_tax_value`]
          );

          taxesArr.push({
            name: `Taxa do Boleto <span class='cprimary' style="font-size: 10px"><i>Boleto</i></span>`,
            value: `R$ ${
              this.sale.tempContract[`payment_methods_billing_ticket_tax_value`]
            }`,
          });
        }
      }

      return {
        formattedTotal: this.formatPricePtBr(total),
        total,
        totalToShow,
        taxesArr,
      };
    },
    calcProfitability() {
      let total = 0;
      let profitArr = [];

      if (
        this.calcAmountReceivable().total > 0 &&
        this.calcAmountPayable().total > 0
      ) {
        profitArr.push({
          name: `Venda - A Pagar`,
          value: `${this.formatPricePtBr(
            this.calcAmountReceivable().total - this.calcAmountPayable().total
          )}`,
        });

        total +=
          this.calcAmountReceivable().total - this.calcAmountPayable().total;
      }

      if (this.sale.tempContract.package_type == "Pacote Completo Terceiros") {
        //
      } else {
        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "hotel"
          )
        ) {
          //
        }

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "flight"
          )
        ) {
          //
        }

        if (
          this.strToJson(this.sale.tempContract.contracted_services).includes(
            "service"
          )
        ) {
          this.strToJson(this.sale.tempContract.service_rows).forEach(
            (service) => {
              // INCENTIVES /////////////////////////////////////////////////////////////////
              if (
                this.sale.tempContract[
                  `contract_finances_service_${service.id}_incentives_rows`
                ] != undefined
              ) {
                this.strToJson(
                  this.sale.tempContract[
                    `contract_finances_service_${service.id}_incentives_rows`
                  ]
                ).forEach((incentive) => {
                  if (
                    this.sale.tempContract[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_type`
                    ] == "%"
                  ) {
                    total += parseFloat(
                      this.sale.tempContract[
                        `contract_finances_service_${service.id}_incentive_${incentive.id}_percentage_value`
                      ]
                    );
                  }

                  if (
                    this.sale.tempContract[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_type`
                    ]
                  ) {
                    profitArr.push({
                      name: `Incentivo ${incentive.id} <span class='cprimary' style="font-size: 10px"><i>Serviço</i></span>`,
                      value: `${
                        this.sale.tempContract[
                          `contract_finances_service_${service.id}_incentive_${incentive.id}_type`
                        ]
                      } ${
                        this.sale.tempContract[
                          `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                        ]
                      }`,
                    });
                  }

                  total += parse(
                    this.sale.tempContract[
                      `contract_finances_service_${service.id}_incentive_${incentive.id}_value`
                    ]
                  );
                });
              }
            }
          );
        }
      }

      return {
        formattedTotal: this.formatPricePtBr(total),
        total,
        profitArr,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.update-sale
  background: #5bcb95
  border: 2px solid #5bcb95
  color: #fff
.ant-dropdown
  ul
    max-height: 300px
    overflow: auto
#sale-modal
  .content
    padding: 10px 0 0
    .sale-tabs
      .tab
        max-height: 590px
        overflow: auto
        overflow-x: hidden
      .tabs-content
        padding: 0 0 10px 10px
        h2
          padding: 0 0 8px 0
          border-bottom: 1px solid #ebebeb
          font-size: 18px
      .tabs-side
        text-align: center
        min-height: 600px
        border-right: 1px solid #ebebeb
        padding: 10px
        .tab
          padding: 15px 10px
          cursor: pointer
          transition: .3s
          opacity: 0.5
          filter: grayscale(1)
        .tab.active, .tab:hover
          opacity: 1
          transition: .3s
          filter: grayscale(0)
    .footer
      padding: 5px
      border-top: 1px solid #ebebeb
      .finish-btn
        background: #2ecc71 !important
        border-color: #2ecc71  !important
        font-size: 18px  !important
        font-weight: 600
        letter-spacing: 0
        border-radius: 6px
      .box
        position: relative
        padding: 10px 15px 10px
        border-right: 1px solid #ebebeb
        height: 52px
        .allow-user
          font-size: 12px
          font-weight: 500
          position: relative
          top: 5px
        .search-sale
          input
            background: url(../../../assets/images//dashboard/sales/search.png) no-repeat left center
            font-size: 12px
            border-width: 0 0 1px !important
            padding-left: 20px !important
            border-color: #ebebeb !important
  .header
    padding: 25px 10px 10px 10px
    border-radius: 10px 10px 0 0
    background: #ebebeb
    .action-icos
      position: relative
      top: 22px
      padding: 0
      text-align: right
      list-style: none
      li
        display: inline-block
        cursor: pointer
        &:last-child
          margin: 0  0  0 9px
    .values
      > .ant-col
        width: 20% !important
      .box
        position: relative
        padding: 4px 8px
        background: #FFF
        border-radius: 6px
        height: 70px
        border: 1px solid #d6d6d6
        .subtitle
         font-size: 12px
         font-weight: 500
         color: #d0d1d3
         text-transform: uppercase
         margin-top: 3px
         line-height: 1
         margin-bottom: 2px
        .value
          text-align: right
          font-size: 18px
          font-weight: 600
          color: #59595b
          text-transform: uppercase
        .extra
          text-align: right
          font-size: 10px
          font-weight: 500
          color: #59595b
          .price
            font-weight: 700
    .id
      background: #ffffff
      padding: 3px 20px
      border-radius: 6px
      color: #be4178
      font-size: 12px
      font-weight: 600
      top: -11px
      position: absolute
      border: 1px solid #d5d6d6
    .rate
      background: #ffffff
      padding: 3px 9px
      border-radius: 6px
      color: #be4178
      font-size: 12px
      font-weight: 600
      top: -12px
      left: 137px
      line-height: 0
      position: absolute
      border: 1px solid #d5d6d6
    .finished
      text-align: center
      margin-top: -34px
      margin-bottom: 10px

      .txt
        background: #f64747
        padding: 3px 40px
        border-radius: 6px
        color: #FFF
        font-size: 12px
        font-weight: 600
    .title
      border-bottom: 1px solid #cbcbcb
      padding: 16px 0 12px
      h1
        line-height: 10px
        margin: 0
        font-size: 24px
        font-weight: 600
        img
          margin-top: -5px
    .meta
      font-size: 10px
      padding: 5px 0 0px
      font-weight: 600
      color: #333
      .user
          color: #333
</style>
