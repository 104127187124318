var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{staticStyle:{"padding-right":"10px"}},[_vm._l((_vm.theList()),function(item,index){return _c('a-col',{key:index,staticClass:"payment-row",attrs:{"span":24}},[_c('div',{on:{"click":function($event){return _vm.activeAccordeon(index)}}},[_c('a-row',{staticClass:"head",class:(_vm.tempContract[
              `contract_finances_hotel_${item.id}_audit_status`
            ] == 'Realizado' &&
              item.type == 'hotel') ||
            (_vm.tempContract[
              `contract_finances_service_${item.id}_audit_status`
            ] == 'Realizado' &&
              item.type == 'service') ||
            (_vm.tempContract[
              `contract_finances_flight_${item.id}_audit_status`
            ] == 'Realizado' &&
              item.type == 'flight') ||
            (_vm.tempContract[`contract_finances_package_audit_status`] ==
              'Realizado' &&
              item.type == 'package')
              ? 'done'
              : 'edit',attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v(_vm._s(index + 1)+" - "+_vm._s(item.name)+"\n            "+_vm._s(item.id ? "#" + item.id : "")+"\n          ")]),_vm._v(" "),_c('a-col',[(
                (_vm.tempContract[
                  `contract_finances_hotel_${item.id}_audit_status`
                ] == 'Realizado' &&
                  item.type == 'hotel') ||
                  (_vm.tempContract[
                    `contract_finances_service_${item.id}_audit_status`
                  ] == 'Realizado' &&
                    item.type == 'service') ||
                  (_vm.tempContract[
                    `contract_finances_flight_${item.id}_audit_status`
                  ] == 'Realizado' &&
                    item.type == 'flight') ||
                  (_vm.tempContract[`contract_finances_package_audit_status`] ==
                    'Realizado' &&
                    item.type == 'package')
              )?_c('img',{attrs:{"src":require("@/assets/images/dashboard/sales/check.png"),"alt":"check","width":"16"}}):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"ml-10 relative",staticStyle:{"top":"3px"},attrs:{"type":"caret-down"}})],1)],1)],1),_vm._v(" "),(_vm.active == index)?_c('div',[(
            (item.type == 'hotel' &&
              _vm.tempContract.package_type == 'Serviços') ||
              (item.type == 'hotel' &&
                _vm.tempContract.package_type == 'Reserva Online')
          )?_c('div',{staticStyle:{"padding":"20px 20px 0"}},[_c('HotelPaymentDetails',{attrs:{"id":item.id,"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index},on:{"updateTempContract":_vm.updateTempContract}})],1):_vm._e(),_vm._v(" "),(
            (item.type == 'flight' &&
              _vm.tempContract.package_type == 'Serviços') ||
              (item.type == 'flight' &&
                _vm.tempContract.package_type == 'Reserva Online')
          )?_c('div',{staticStyle:{"padding":"20px 20px 0"}},[_c('FlightPaymentDetails',{attrs:{"id":item.id,"flight":item.data,"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form,"index":index},on:{"updateTempContract":_vm.updateTempContract}})],1):_vm._e(),_vm._v(" "),(
            (item.type == 'service' &&
              _vm.tempContract.package_type == 'Serviços') ||
              (item.type == 'service' &&
                _vm.tempContract.package_type == 'Reserva Online')
          )?_c('div',{staticStyle:{"padding":"20px 20px 0"}},[_c('ServicePaymentDetails',{attrs:{"id":item.id,"index":index,"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form},on:{"updateTempContract":_vm.updateTempContract}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract.package_type == 'Pacote Completo Terceiros')?_c('div',{staticStyle:{"padding":"20px 20px 0"}},[_c('ThirdPartyPackagePaymentDetails',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"form":_vm.form},on:{"updateTempContract":_vm.updateTempContract}})],1):_vm._e()]):_vm._e()])}),_vm._v(" "),(_vm.milesTaxesPayments().show)?_c('a-col',{staticClass:"payment-row mb-20",attrs:{"span":24}},[_c('a-row',[_c('a-col',{staticClass:"head",class:_vm.tempContract[
              `contract_finances_flight_miles_taxes_audit_status`
            ] == 'Realizado'
              ? 'done'
              : 'edit',attrs:{"span":24},on:{"click":function($event){return _vm.activeAccordeon(999)}}},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('a-row',{attrs:{"type":"flex","justify":"start","gutter":40}},[_c('a-col',[_c('a-icon',{attrs:{"type":"dollar"}}),_vm._v(" Taxas")],1),_vm._v(" "),_c('a-col',[_vm._v("\n                  Valor:\n                  "+_vm._s(_vm.formatPriceCurrency(
                      _vm.milesTaxesPayments().totalTaxesWithCurrency,
                      _vm.milesTaxesPayments().currency
                    ))+"\n                ")]),_vm._v(" "),_c('a-col',[_vm._v("\n                  Câmbio utilizado:\n                  "+_vm._s(_vm.formatAnyPricePtBr(_vm.tempContract.voucher_payments_exchange)))])],1)],1),_vm._v(" "),_c('a-col',[(
                  _vm.tempContract[
                    `contract_finances_flight_miles_taxes_audit_status`
                  ] == 'Realizado'
                )?_c('img',{attrs:{"src":require("@/assets/images/dashboard/sales/check.png"),"alt":"check","width":"16"}}):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"ml-10 relative",staticStyle:{"top":"3px"},attrs:{"type":"caret-down"}})],1)],1)],1),_vm._v(" "),(_vm.active == 999)?_c('a-col',{attrs:{"span":24}},[_c('MilesFlightTaxesPaymentDetails',{attrs:{"index":_vm.active,"tempContract":_vm.tempContract,"sale":_vm.sale,"milesTaxesPayments":_vm.milesTaxesPayments()},on:{"updateTempContract":_vm.updateTempContract}})],1):_vm._e()],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }