<template>
  <div>
    <a-row
      class="taxes-payment"
      v-for="(payment, index) in voucherPaymentsMethod"
      :key="index"
    >
      <a-col
        class="credit-card tax-type"
        v-if="payment == 'credit-card'"
        :span="24"
      >
        <a-row>
          <a-col class="title" :span="24">
            <a-icon type="credit-card" class="mr-5" /> Cartão de Crédito
          </a-col>
          <a-col class="content" :span="24">
            <a-row :gutter="20">
              <a-col :span="10">
                {{
                  getCreditCardData(
                    tempContract["voucher_payments_credit_card_id"]
                  )
                }}
              </a-col>
              <a-col :span="4">
                Valor ({{ milesTaxesPayments.currency }}):
                <font class="txt">
                  {{ formatCurrency(milesTaxesPayments.currency) }}
                  {{ tempContract["voucher_payments_credit_card_value"] }}</font
                >
              </a-col>
              <a-col :span="7">
                Valor debitado em reais:
                <font class="txt">{{
                  formatAnyPricePtBr(
                    tempContract["voucher_payments_credit_card_value_brl"]
                  )
                }}</font>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>

      <a-col
        class="voucher tax-type"
        v-if="payment == 'voucher-payment'"
        :span="24"
      >
        <a-row>
          <a-col class="title" :span="24">
            <a-icon type="solution" class="mr-5" /> Voucher
          </a-col>
          <a-col class="content" :span="24">
            <a-row v-for="(voucher, index) in voucherRows" :key="index">
              <a-col :span="6">
                <b>{{ voucher.id }} - </b>
                {{
                  tempContract[`voucher_payments_voucher_${voucher.id}_code`]
                }}
              </a-col>
              <a-col :span="6">
                Valor utilizado:
                <font class="txt">
                  {{ formatCurrency(milesTaxesPayments.currency) }}
                  {{
                    tempContract[`voucher_payments_voucher_${voucher.id}_value`]
                  }}
                </font>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-row
      v-if="
        tempContract[`contract_finances_flight_miles_taxes_audit_status`] !=
        'Realizado'
      "
      class="payment-row"
    >
      <a-col class="mt-20 a-right">
        <a-popconfirm
          placement="left"
          ok-text="Sim"
          cancel-text="Não"
          @confirm="confirmAuditory()"
        >
          <template slot="title">
            <p>
              Você confirma todos os valores e dados
              <br />inseridos neste pagamento? Após esta ação
              <br />
              não será mais possível editar.
            </p>
          </template>
          <a-button
            v-if="!edit"
            html-type="submit"
            class="ml-10 update"
            type="primary"
          >
            <a-icon type="check" />
          </a-button>
        </a-popconfirm>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import companyCreditCardMixins from "@/mixins/company-credit-cards/companyCreditCardMixins";
import formatThings from "@/mixins/general/formatThings";

export default {
  props: {
    tempContract: Object,
    sale: Object,
    flight: Object,
    index: Number,
    milesTaxesPayments: Object,
  },
  mixins: [companyCreditCardMixins, formatThings],
  data() {
    return {
      voucherPaymentsMethod: [],
      voucherRows: [],
    };
  },
  mounted() {
    this.voucherPaymentsMethod = this.tempContract["voucher_payments_method"]
      ? JSON.parse(this.tempContract["voucher_payments_method"])
      : [];

    this.voucherRows = this.tempContract["voucher_rows"]
      ? JSON.parse(this.tempContract["voucher_rows"])
      : [];

    this.companyCreditCards.filters.showInContract = 1;
    this.companyCreditCards.pagination.perPage = 100;
    this.getCompanyCreditCards();
  },
  methods: {
    confirmAuditory() {
      let values = {};
      values[`contract_finances_flight_miles_taxes_audit_status`] = "Realizado";
      values[`contract_finances_flight_miles_taxes_audit_user_id`] =
        this.$store.state.userData.id;

      values.id = this.tempContract.id;
      values.sale_id = this.sale.id;
      values.module = "sale";
      values.sale_to_pay_active_accordeon = this.index;
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);

          this.$http
            .post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.sale.id,
              module: "sale",
              action: `update-contract-miles-flight-taxes-status-in-sale`,
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} confirmou através de auditoria os dados do pagamento das taxas (milhas)  a partir da venda #${this.sale.id}.`,
            })
            .then(({ data }) => {
              data;
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    getCreditCardData(id) {
      let theCreditCard = false;

      if (this.companyCreditCards.list.length > 0) {
        let creditCard = this.companyCreditCards.list.filter((card) => {
          return card.id == id;
        });

        theCreditCard = `${creditCard[0].card_name} - ${creditCard[0].card_number}`;
      }

      return theCreditCard;
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-row
  .edit
    background: #f27935
    border-color: #f27935
    padding: 5px 10px
  .cancel
    background: #59595b
    border-color: #59595b
    padding: 5px 10px
  .update
    background: #2ecc71
    border-color: #2ecc71
    padding: 5px 10px
    &.disabled
      background: #ddd
      border-color: #ddd
  .save
    background: #00acec
    border-color: #00acec
    padding: 0 6px
.taxes-payment
  padding: 0 0 0 20px
  .tax-type
    padding: 10px 0
    border-bottom: 1px solid #ddd
    .content
      font-size: 13px
    .txt
      font-weight: 600
    .title
      padding: 0 0 10px
      text-transform: uppercase
      color: #c34e81
      font-weight: 600
      font-size: 11px
</style>
