<template>
  <div class="payment-row">
    <aForm :form="form" @submit="submitContract">
      <a-row :gutter="20">
        <a-col :span="4">
          <a-form-item class="travel-input-outer">
            <label class="filled">Valor total (R$) </label>
            <a-input
              class="travel-input"
              placeholder="Valor total"
              v-currency
              :disabled="true"
              v-decorator="[
                `service_${id}_total_price`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              @blur="calcProfit(id)"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item class="travel-input-outer travel-multiselector">
            <label :class="'filled'"> Fornecedor </label>
            <a-select
              class="travel-input"
              show-search
              :allow-clear="true"
              :disabled="!edit"
              optionFilterProp="txt"
              placeholder="Selecione "
              v-decorator="[
                `contract_finances_service_${id}_supplier_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%; height: 30px"
              @change="onSelectSupplier"
            >
              <a-select-option
                v-for="(item, index) of productSupplier.list"
                :key="index"
                :value="item.id"
                :txt="item.trading_name"
              >
                {{ item.id }} -
                {{ item.trading_name.toUpperCase() }}
              </a-select-option>
            </a-select>
          </a-form-item></a-col
        >

        <a-col :span="4">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">Vencimento</label>
            <a-date-picker
              class="travel-input"
              placeholder="Vencimento"
              format="DD/MM/YYYY"
              :disabled="!edit"
              :showToday="false"
              :allowClear="true"
              v-mask="'##/##/####'"
              v-decorator="[
                `contract_finances_service_${id}_expired_at`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <HayaSelectField
            label="Empresa"
            :fieldKey="`contract_finances_service_${id}_company_id`"
            :required="true"
            :form="form"
            :fieldClass="!edit ? 'readonly' : ''"
            :list="companiesList"
            @onChangeSelectField="getCompanyBranches"
          />

          <a-form-item class="readonl none">
            <a-input
              v-decorator="[`contract_finances_service_${id}_company`]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <HayaSelectField
            label="Filial"
            :fieldKey="`contract_finances_service_${id}_company_branch_id`"
            :required="false"
            :form="form"
            :fieldClass="!edit ? 'readonly' : ''"
            :list="companyBranchesList"
            @onChangeSelectField="setCompanyBranchName"
          />

          <a-form-item>
            <a-input
              class="readonly none"
              v-decorator="[`contract_finances_service_${id}_company_branch`]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="20">
        <a-col :span="4">
          <a-form-item class="travel-input-outer">
            <label class="filled">Valor a Pagar (R$) </label>
            <a-input
              class="travel-input"
              placeholder="Valor a Pagar"
              v-currency
              :disabled="!edit"
              v-decorator="[
                `contract_finances_service_${id}_net_value`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              @blur="calcProfit(id)"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="4">
          <a-form-item class="travel-input-outer travel-multiselector">
            <label :class="'filled'"> Tipo de Pagamento </label>
            <a-select
              class="travel-input"
              show-search
              :allow-clear="true"
              :disabled="!edit"
              @change="onChangePaymentType"
              optionFilterProp="txt"
              placeholder="Selecione"
              v-decorator="[
                `contract_finances_service_${id}_payment_type`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%; height: 30px"
            >
              <a-select-option
                v-for="(item, index) of paymentType"
                :key="index"
                :value="item.name"
                :txt="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item></a-col
        >
        <a-col
          v-if="
            form.getFieldValue(`contract_finances_service_${id}_payment_type`)
          "
          :span="7"
        >
          <a-form-item class="travel-input-outer travel-multiselector">
            <label :class="'filled'"> Forma de Pagamento </label>
            <a-select
              class="travel-input"
              show-search
              :disabled="!edit"
              :allow-clear="true"
              optionFilterProp="txt"
              placeholder="Selecione"
              v-decorator="[
                `contract_finances_service_${id}_payment_method`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%; height: 30px"
            >
              <a-select-option
                v-for="(item, index) of paymentMethodsList"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                <div
                  v-if="
                    form.getFieldValue(
                      `contract_finances_service_${id}_payment_type`
                    ) == 'Cartão de Crédito'
                  "
                >
                  {{ item.id }} - {{ item.card_name }} ({{ item.card_number }}
                  )
                </div>
                <div v-else>
                  {{ item.id }} - {{ item.company_name }} ({{ item.account }})
                </div>
              </a-select-option>
            </a-select>
          </a-form-item></a-col
        >

        <a-col :span="4">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">Dia do pagamento</label>
            <a-date-picker
              class="travel-input"
              placeholder="Dia do pagamento"
              format="DD/MM/YYYY"
              :disabled="!edit"
              :showToday="false"
              :allowClear="true"
              v-mask="'##/##/####'"
              v-decorator="[
                `contract_finances_service_${id}_paid_at`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-date-picker>
          </a-form-item>
        </a-col>
        <a-col v-if="sale.status != 'Finalizado'" :span="4" style="top: 5px">
          <a-button
            v-if="edit"
            @click="editRow(id, 'cancel')"
            class="ml-10 cancel"
            type="primary"
          >
            <a-icon type="close" />
          </a-button>

          <a-button
            v-if="!edit"
            @click="editRow(id, 'edit')"
            class="ml-10 edit"
            type="primary"
          >
            <a-icon type="edit" />
          </a-button>

          <a-button
            v-if="edit"
            html-type="submit"
            class="ml-10 save"
            type="primary"
            style="padding: 0px 6px"
          >
            <img
              src="../../../assets/images/dashboard/sales/disket.png"
              alt="save"
            />
          </a-button>

          <a-popconfirm
            placement="left"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="confirmAuditory()"
          >
            <template slot="title">
              <p>
                Você confirma todos os valores e dados
                <br />inseridos neste pagamento? Após esta ação
                <br />
                não será mais possível editar.
              </p>
            </template>
            <a-button
              v-if="!edit"
              html-type="submit"
              class="ml-10 update"
              type="primary"
            >
              <a-icon type="check" />
            </a-button>
          </a-popconfirm>

          <span
            v-if="
              tempContract[
                `contract_finances_service_${this.id}_calendar_event`
              ] != 'Criado'
            "
          >
            <a-popconfirm
              v-if="!edit"
              placement="left"
              ok-text="Sim"
              cancel-text="Não"
              @confirm="addToCalendar"
              :disabled="blockCreateEvent()"
            >
              <template slot="title">
                <p>
                  Tem certeza que deseja criar este <br />
                  pagamento no <b>Calendário Financeiro?</b>
                  <br />
                  <b
                    v-if="
                      tempContract[
                        `contract_finances_service_${this.id}_audit_status`
                      ] == 'Realizado'
                    "
                    >Status: PAGO</b
                  ><b v-else>Status: PENDENTE</b>.
                </p>
              </template>

              <a-tooltip placement="top" title="Criar Pagamento no Calendário">
                <a-button
                  class="ml-10 calendar"
                  :class="blockCreateEvent() ? 'disabled' : ''"
                  type="primary"
                >
                  <a-icon type="calendar" />
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </span>
        </a-col>
      </a-row>
    </aForm>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import bankTransactionsMixins from "@/components/finances/mixins/bankTransactionsMixins";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";

export default {
  props: {
    tempContract: Object,
    sale: Object,
    id: Number,
    index: Number,
  },
  components: { HayaSelectField },
  mixins: [formatThings, productSupplierMixins, bankTransactionsMixins],
  data() {
    return {
      paymentType: [],
      paymentMethodsList: [],
      companiesList: [],
      companyBranchesList: [],
      edit: false,
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
    };
  },
  beforeMount() {
    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data.map(({ id, trading_name }) => {
        return {
          label: `${id != 0 ? id + " - " : ""} ${trading_name} `,
          value: id,
          item: { trading_name, id },
        };
      });
    });
  },
  mounted() {
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=15&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.paymentType = data.data;
      });

    this.form.setFieldsValue({
      [`contract_finances_service_${this.id}_payment_type`]:
        this.tempContract[`contract_finances_service_${this.id}_payment_type`],
    });

    if (this.tempContract[`contract_finances_service_${this.id}_company_id`])
      this.getCompanyBranches(
        this.tempContract[`contract_finances_service_${this.id}_company_id`]
      );

    setTimeout(() => {
      this.form.setFieldsValue({
        [`service_${this.id}_total_price`]:
          this.tempContract[`service_${this.id}_total_price`],
        [`contract_finances_service_${this.id}_supplier_id`]:
          this.tempContract[`contract_finances_service_${this.id}_supplier_id`],
        [`contract_finances_service_${this.id}_expired_at`]:
          this.tempContract[`contract_finances_service_${this.id}_payday`],
        [`contract_finances_service_${this.id}_net_value`]:
          this.tempContract[`contract_finances_service_${this.id}_net_value`],
        [`contract_finances_service_${this.id}_paid_at`]:
          this.tempContract[`contract_finances_service_${this.id}_paid_at`],
        [`contract_finances_service_${this.id}_payment_method`]:
          this.tempContract[
            `contract_finances_service_${this.id}_payment_method`
          ],
        [`contract_finances_service_${this.id}_net_value`]:
          this.tempContract[`contract_finances_service_${this.id}_net_value`],
        [`contract_finances_service_${this.id}_company_id`]:
          this.tempContract[`contract_finances_service_${this.id}_company_id`],
        [`contract_finances_service_${this.id}_company_branch_id`]:
          this.tempContract[
            `contract_finances_service_${this.id}_company_branch_id`
          ],
        [`contract_finances_service_${this.id}_company`]:
          this.tempContract[`contract_finances_service_${this.id}_company`],
        [`contract_finances_service_${this.id}_company_branch`]:
          this.tempContract[
            `contract_finances_service_${this.id}_company_branch`
          ],
      });

      if (
        this.tempContract[
          `contract_finances_service_${this.id}_payment_type`
        ] != undefined
      ) {
        this.onChangePaymentType(
          this.tempContract[
            `contract_finances_service_${this.id}_payment_type`
          ],
          true
        );
      }
    }, 500);
  },
  methods: {
    setCompanyBranchName(companyBranchId, obj) {
      companyBranchId;

      if (obj !== undefined)
        this.form.setFieldsValue({
          [`contract_finances_service_${this.id}_company_branch`]:
            obj.data.attrs.obj.item.name,
        });
    },
    getCompanyBranches(companyId, obj) {
      this.companyBranchesList = [];

      if (obj !== undefined)
        this.form.setFieldsValue({
          [`contract_finances_service_${this.id}_company`]:
            obj.data.attrs.obj.item.trading_name,
        });

      this.form.setFieldsValue({
        [`contract_finances_service_${this.id}_company_branch_id`]: undefined,
      });

      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&show_in_contract=1&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data.map(({ id, name }) => {
            return {
              label: `${id != 0 ? id + " - " : ""} ${name} `,
              value: id,
              item: { name, id },
            };
          });
        })
        .catch(() => {
          this.$message.error("Nenhuma filial encontrada.");
        });
    },
    editRow(id, type) {
      if (type == "edit") this.edit = true;
      if (type == "cancel") this.edit = false;
    },
    addToCalendar() {
      let eventData = {};

      eventData.name =
        this.tempContract[`contract_finances_service_${this.id}_supplier`];

      eventData.type = "Pagamento";
      eventData.status =
        this.tempContract[
          `contract_finances_service_${this.id}_audit_status`
        ] == "Realizado"
          ? "Pago"
          : "Pendente";

      eventData.date =
        this.tempContract[`contract_finances_service_${this.id}_expired_at`];
      eventData.payday =
        this.tempContract[`contract_finances_service_${this.id}_paid_at`];
      eventData.module_id =
        this.tempContract[`contract_finances_service_${this.id}_supplier_id`];
      eventData.module = "product-supplier";
      eventData.company_id = this.sale.raw.company_id;
      eventData.company_branch_id = this.sale.raw.company_branch_id;
      eventData.description = `Pagamento criado a partir da venda #${this.sale.raw.id}.`;
      eventData.value = this.tempContract[
        `contract_finances_service_${this.id}_net_value`
      ].replace(",", ".");
      eventData.payment_methods =
        this.tempContract[`contract_finances_service_${this.id}_payment_type`];

      if (
        this.tempContract[
          `contract_finances_service_${this.id}_payment_type`
        ] == "Cartão de Crédito"
      ) {
        eventData.company_credit_card_id =
          this.tempContract[
            `contract_finances_service_${this.id}_payment_method`
          ];
      } else {
        eventData.bank_id =
          this.tempContract[
            `contract_finances_service_${this.id}_payment_method`
          ];
      }

      eventData.priority = "Média";
      eventData.user_id = this.$store.state.userData.id;
      eventData.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/event/create", eventData)
        .then(({ data }) => {
          this.$message.success(data.message, 5);

          let contractData = {};

          contractData[`contract_finances_service_${this.id}_calendar_event`] =
            "Criado";
          contractData.id = this.tempContract.id;
          contractData.sale_id = this.sale.id;
          contractData.module = "sale";
          contractData.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          contractData.sale_to_pay_active_accordeon = this.index;

          this.$http
            .post("/contract/update-multi-meta", contractData)
            .then(({ data }) => {
              data;
              this.$emit("updateTempContract", this.tempContract);
              this.edit = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    blockCreateEvent() {
      let flag = true;

      if (
        this.tempContract[`contract_finances_service_${this.id}_expired_at`] &&
        this.tempContract[`contract_finances_service_${this.id}_supplier_id`] &&
        this.tempContract[`contract_finances_service_${this.id}_net_value`]
      )
        flag = false;

      return flag;
    },
    onChangePaymentType(paymentType, onMount) {
      if (!onMount) {
        this.form.setFieldsValue({
          [`contract_finances_service_${this.id}_payment_method`]: undefined,
        });
      }

      if (
        paymentType == "Boleto Bancário" ||
        paymentType == "Faturado" ||
        paymentType == "Transferência Bancária"
      )
        this.$http
          .get(`/company-bank-accounts/list?page=1&per_page=200&status=Ativo`)
          .then(({ data }) => {
            this.paymentMethodsList = data.data;
          });

      if (paymentType == "Cartão de Crédito")
        this.$http
          .get(`/company-credit-card/list?page=1&per_page=100&status=Ativo`)
          .then(({ data }) => {
            this.paymentMethodsList = data.data;
          });
    },
    onSelectSupplier(supplierId) {
      setTimeout(() => {
        let theSupplier = this.productSupplier.list.filter((supplier) => {
          return supplier.id == supplierId;
        });

        this.tempContract[`contract_finances_service_${this.id}_supplier`] =
          theSupplier[0].trading_name;
      }, 200);
    },
    confirmAuditory() {
      let values = {};

      values[`contract_finances_service_${this.id}_audit_status`] = "Realizado";
      values[`contract_finances_service_${this.id}_audit_user_id`] =
        this.$store.state.userData.id;

      values.id = this.tempContract.id;
      values.sale_id = this.sale.id;
      values.module = "sale";
      values.service = {
        id: this.id,
        name: this.tempContract[`service_${this.id}_name`],
      };
      values.action = "update-contract-service-status-in-sale";
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      values.sale_to_pay_active_accordeon = this.index;

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);
          this.edit = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    submitContract(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        values.id = this.tempContract.id;
        values.sale_id = this.sale.id;
        values.module = "sale";
        values.service = {
          id: this.id,
          name: this.tempContract[`service_${this.id}_name`],
        };
        values.action = "update-contract-service-in-sale";
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        values[`contract_finances_service_${this.id}_supplier`] =
          this.tempContract[`contract_finances_service_${this.id}_supplier`];

        values.sale_to_pay_active_accordeon = this.index;

        if (!err) {
          this.$http
            .post("/contract/update-multi-meta", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("updateTempContract", this.tempContract);
              this.edit = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        } else {
          this.$message.error("Prrencha todos os campos.");
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-row
  .calendar
    background: #f8bb03
    border-color: #f8bb03
    padding: 5px 10px
    &.disabled
      background: #ddd
      border-color: #ddd
  .edit
    background: #f27935
    border-color: #f27935
    padding: 5px 10px
  .cancel
    background: #59595b
    border-color: #59595b
    padding: 5px 10px
  .update
    background: #2ecc71
    border-color: #2ecc71
    padding: 5px 10px
  .save
    background: #00acec
    border-color: #00acec
    padding: 0 6px
</style>
