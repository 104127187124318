<template>
  <div>
    <a-row class="fields" :gutter="20">
      <a-col :span="6">
        <a-form-item class="travel-input-outer">
          <label
            :class="
              form.getFieldValue(`flight_${flight.id}_total_price`)
                ? 'filled'
                : 'filled'
            "
          >
            Valor total do aéreo (R$)
          </label>

          <a-input
            class="travel-input"
            placeholder="Valor total"
            v-currency
            :disabled="true"
            v-decorator="[
              `flight_${flight.id}_total_price`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="18">
        <a-form-item class="travel-input-outer">
          <label
            :class="
              form.getFieldValue(`flight_${flight.id}_total_price_written`)
                ? 'filled'
                : 'filled'
            "
          >
            Valor total por extenso
          </label>

          <a-input
            class="travel-input upper"
            placeholder="Valor total por extenso"
            :disabled="isSale"
            v-decorator="[`flight_${flight.id}_total_price_written`]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col class="flight-table" :span="24">
        <a-row>
          <a-col class="line" :span="14" style="padding: 0; border: 0">
            <a-row class="head">
              <a-col :span="9">Pax </a-col>
              <a-col :span="5"> Milhas </a-col>
              <a-col :span="5"> Ticket </a-col>
              <a-col :span="5"> Taxas </a-col>
            </a-row>

            <a-row>
              <a-col :span="9"
                >{{ flightTravellers(flight.id).adults.qtd }}x Adulto(s)</a-col
              >
              <a-col :span="5">
                {{ flightTravellers(flight.id).adults.totalMiles }}
              </a-col>
              <a-col :span="5">
                {{
                  formatPricePtBr(
                    flightTravellers(flight.id).adults.totalTicket
                  )
                }}
              </a-col>

              <a-col :span="5">
                {{
                  formatPricePtBr(flightTravellers(flight.id).adults.totalTaxes)
                }}
              </a-col>
            </a-row>

            <a-row
              class="line"
              v-if="flightTravellers(flight.id).children.qtd > 0"
            >
              <a-col :span="9"
                >{{ flightTravellers(flight.id).children.qtd }}x
                Criança(s)</a-col
              >
              <a-col :span="5">
                {{ flightTravellers(flight.id).children.totalMiles }}
              </a-col>
              <a-col :span="5"
                >{{
                  formatPricePtBr(
                    flightTravellers(flight.id).children.totalTicket
                  )
                }}
              </a-col>

              <a-col :span="5"
                >{{
                  formatPricePtBr(
                    flightTravellers(flight.id).children.totalTaxes
                  )
                }}
              </a-col>
            </a-row>
          </a-col>

          <a-col class="line" :span="10" style="padding: 0; border: 0">
            <a-row class="head">
              <a-col :span="6"> Custo </a-col>

              <a-col :span="12"> Fornecedores </a-col>
              <a-col :span="6"> Pedidos </a-col>
            </a-row>

            <a-col :span="6">
              <b
                :class="
                  tempContract[
                    `contract_finances_flight_${flight.id}_sale_value`
                  ] !=
                  sale.contract.meta[
                    `contract_finances_flight_${flight.id}_sale_value`
                  ]
                    ? 'corange'
                    : ''
                "
              >
                {{
                  formatPricePtBr(
                    tempContract[
                      `contract_finances_flight_${flight.id}_sale_value`
                    ]
                  )
                }}
              </b>
            </a-col>

            <a-col :span="12">
              {{
                tempContract[`contract_finances_flight_${flight.id}_suppliers`]
              }}
            </a-col>
            <a-col :span="6">
              {{
                tempContract[
                  `contract_finances_flight_${flight.id}_miles_order_ids`
                ]
              }}
            </a-col>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import _ from "lodash";

export default {
  name: "ContractFinancesMilesFlight",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    flight: Object,
    sale: Object,
    isSale: Boolean,
  },
  mixins: [formatThings, productSupplierMixins],
  data() {
    return {
      flightSectionsRows: [],
      totalMilesSale: 0,
    };
  },
  mounted() {
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    this.flightSectionsRows = this.tempContract.flight_sections_rows
      ? JSON.parse(this.tempContract.flight_sections_rows)
      : [];

    this.flightSectionsRows.forEach((flight) => {
      this.form.setFieldsValue({
        [`flight_${flight.id}_total_price`]:
          this.tempContract[`flight_${flight.id}_total_price`],
        [`flight_${flight.id}_total_price_written`]:
          this.tempContract[`flight_${flight.id}_total_price_written`],
      });
      flight.travellers.forEach((traveller) => {
        this.form.setFieldsValue({
          [`flight_${flight.id}_traveller_${traveller.id}_value`]:
            this.tempContract[
              `flight_${flight.id}_traveller_${traveller.id}_value`
            ],
          [`flight_${flight.id}_traveller_${traveller.id}_taxes`]:
            this.tempContract[
              `flight_${flight.id}_traveller_${traveller.id}_taxes`
            ],
          [`flight_${flight.id}_traveller_${traveller.id}_du_tax`]:
            this.tempContract[
              `flight_${flight.id}_traveller_${traveller.id}_du_tax`
            ],
          [`flight_${flight.id}_traveller_${traveller.id}_teb_agency`]:
            this.tempContract[
              `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
            ],
        });
      });

      this.generateMilesFlightTable();
    });
  },
  methods: {
    updateMileCostInContract() {
      let values = {};
      values[`contract_finances_flight_${this.flight.id}_sale_value`] =
        this.tempContract[
          `contract_finances_flight_${this.flight.id}_sale_value`
        ];

      values.id = this.tempContract.id;
      values.sale_id = this.sale.id;
      values.module = "sale";
      values.flight = {
        id: this.flight.id,
        name: `AÉREO ${this.tempContract[
          `flight_${this.flight.id}_type`
        ].toUpperCase()}`,
      };

      values.action = "update-contract-flight-status-in-sale";
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    getTravellerAge(travellerId) {
      let travellers = JSON.parse(this.tempContract[`travellers_rows`]);
      let theAge = "adult";

      travellers.forEach((traveller) => {
        if (traveller.id == travellerId) {
          theAge = this.tempContract[`traveller_${traveller.id}_age`];
        }
      });

      return theAge;
    },
    generateMilesFlightTable() {
      let totalSale = 0;

      // THIS WILL UPDATE SALE VALUE AUTO
      this.$http
        .get(
          `/miles-card-order/list?page=1&per_page=20&status=Concluído&contract_id=${this.sale.raw.contract_id}&flight_id=${this.flight.id}&order=desc&order-by=created`
        )
        .then(({ data }) => {
          data;
        });

      setTimeout(() => {
        // THIS WILL CALC NEW SALE VALUE AUTO
        this.$http
          .get(
            `/miles-card-order/list?page=1&per_page=50&status=Concluído&contract_id=${this.tempContract.id}&flight_id=${this.flight.id}&order=desc&order-by=created`
          )
          .then(({ data }) => {
            data.data.forEach((milesOrder) => {
              totalSale += parseFloat(milesOrder.raw.miles_cost);
            });

            this.tempContract[
              `contract_finances_flight_${this.flight.id}_sale_value`
            ] = totalSale;

            this.totalMilesSale = totalSale;

            this.$emit("milesOrdersList", true);
          });
      }, 200);
    },
    flightTravellers(flightId) {
      let allTravellers = [];

      this.flightSectionsRows.forEach((flight) => {
        if (flightId == flight.id) {
          flight.travellers.forEach((traveller) => {
            allTravellers.push({
              id: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}`
                ]
              }`,
              age: this.getTravellerAge(
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}`
                ]
              ),
              value: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_value`
                ]
              }`,
              miles: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_miles`
                ]
              }`,
              tax: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_taxes`
                ]
              }`,
              tax_currency: `${
                this.tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                ] != undefined
                  ? this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ]
                  : "BRL"
              }`,
            });
          });
        }
      });

      let all = _.groupBy(allTravellers, (item) => item.age);

      let totalTicketAdults = 0;
      let totalTaxesAdults = 0;
      let totalMilesAdults = 0;

      if (all.adult != undefined) {
        all.adult.forEach((adt) => {
          totalTicketAdults += parseFloat(adt.value.replace(",", "."));
          totalTaxesAdults += parseFloat(adt.tax.replace(",", "."));
          totalMilesAdults += parseInt(adt.miles);
        });
      }

      let qtdChds = 0;
      let Chds = [];
      let totalTicketChds = 0;
      let totalTaxesChds = 0;
      let totalMilesChds = 0;

      if (all.child != undefined) {
        all.child.forEach((chd) => {
          totalTicketChds += parseFloat(chd.value.replace(",", "."));
          totalTaxesChds += parseFloat(chd.tax.replace(",", "."));
          totalMilesChds += parseInt(chd.miles);
        });

        Chds = all.child;
        qtdChds = all.child.length;
      }

      return {
        adults: {
          qtd: all.adult != undefined ? all.adult.length : 0,
          data: all.adult,
          supplier: "",
          totalTicket: totalTicketAdults,
          totalTaxes: totalTaxesAdults,
          totalMiles: totalMilesAdults,
        },
        children: {
          qtd: qtdChds,
          data: Chds,
          supplier: "",
          totalTicket: totalTicketChds,
          totalTaxes: totalTaxesChds,
          totalMiles: totalMilesChds,
        },
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.add-button
  font-size: 11px
  font-weight: 600
  color: #bbb
  border-color: #ddd
  padding: 2px 5px !important
  line-height: 4px
  height: 22px
  top: -3px
  position: relative
.add-button.color
  border-color: #be4178
  color: #be4178
.remove-button
  font-size: 11px
  font-weight: 600
  color: red
  border-color: red
  padding: 2px 5px !important
  line-height: 4px
  height: 20px
  top: -3px
  position: relative
.flight-table
  border-width: 1px 0
  padding: 0 !important
  margin: 5px 10px 20px
  font-size: 12px
  .head
    font-weight: 600
    background: #f9f9f9
    color: #666
    border: 1px solid #ddd
    border-width: 1px 0
    .ant-col
      padding: 5px
      border-right: 1px solid #ddd
  .line
    border-top: 0px solid #ddd
    .ant-col
      padding: 5px
      border-right: 0px solid #ddd

.supplier-accordeon
  .name
    background: #6c7a89
    border-bottom: 1px solid #fff
    text-align: center
    color: #fff
    padding: 7px 10px 7px 25px
    font-size: 13px
    line-height: 28px
    cursor: pointer
    button
      color: #fff
      height: auto
    .anticon
      color: #fff
  .fields
    padding: 30px 25px
    .add-row
      position: relative
      top: 10px
      padding: 0
      width: 25px
      height: 25px
      line-height: 24px
      font-size: 12px
      color: #bbb
      text-align: center
      &:hover
        color: #be4178
    .subtitile
      text-transform: uppercase
      font-size: 11px
      padding: 5px 0
      border-bottom: 1px solid #ddd
      margin-bottom: 20px
  .content
    .infos
      font-size: 12px
      padding: 10px 25px
      border-bottom: 1px solid #ddd
      .line
        padding: 3px 0
        .label
          color: #808183
        .value
          font-weight: 500
          color: #59595b
</style>
