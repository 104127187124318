<template>
  <div>
    <aRow style="padding-right: 10px">
      <aCol
        v-for="(item, index) in this.moneyToReceiveList"
        :key="index"
        class="payment-row"
        :span="24"
      >
        <div @click="activeAccordeon(index)">
          <aRow
            class="head"
            :class="
              (tempContract.payment_methods_bank_transfer_audit_status ==
                'Realizado' &&
                item.type == 'bank-transfer') ||
              (tempContract[
                `payment_methods_bank_transfer_audit_status_${item.id}`
              ] == 'Realizado' &&
                item.type == 'bank-transfer') ||
              (tempContract[
                `payment_methods_custom_payment_audit_status_${item.id}`
              ] == 'Realizado' &&
                item.type == 'custom-payment') ||
              (tempContract.payment_methods_custom_payment_audit_status ==
                'Realizado' &&
                item.type == 'custom-payment') ||
              (tempContract.payment_methods_payment_card_audit_status ==
                'Realizado' &&
                item.type == 'payment-card') ||
              (tempContract.payment_methods_billing_ticket_audit_status ==
                'Realizado' &&
                item.type == 'billing-ticket') ||
              (tempContract[
                `payment_methods_credit_card_audit_status_${item.id}`
              ] == 'Realizado' &&
                item.type == 'card') ||
              (tempContract[
                `payment_methods_payment_link_audit_status_${item.id}`
              ] == 'Realizado' &&
                item.type == 'payment-link')
                ? 'done'
                : 'edit'
            "
            type="flex"
            justify="space-between"
          >
            <aCol
              >{{ index + 1 }} - {{ item.name }}
              {{ item.id ? "#" + item.id : "" }}
              <span v-if="item.number" class="ml-20 lower">
                {{ item.number ? "xxxx-" + item.number : "" }}
              </span>

              <span v-if="item.number" class="ml-20">
                <img
                  v-if="
                    tempContract[
                      `payment_methods_credit_card_client_flag_${item.id}`
                    ] == 'Mastercard'
                  "
                  src="@/assets/images/mastercard.png"
                  alt="img"
                  style="height: 15px"
                />
                <img
                  v-if="
                    tempContract[
                      `payment_methods_credit_card_client_flag_${item.id}`
                    ] == 'Visa'
                  "
                  src="@/assets/images/visa.png"
                  alt="img"
                  style="height: 15px"
                />
                <img
                  v-if="
                    tempContract[
                      `payment_methods_credit_card_client_flag_${item.id}`
                    ] == 'Diners'
                  "
                  src="@/assets/images/diners.png"
                  alt="img"
                  style="height: 15px"
                />
                <img
                  v-if="
                    tempContract[
                      `payment_methods_credit_card_client_flag_${item.id}`
                    ] == 'AMEX'
                  "
                  src="@/assets/images/american.png"
                  alt="img"
                  style="height: 15px"
                />

                <img
                  v-if="
                    tempContract[
                      `payment_methods_credit_card_client_flag_${item.id}`
                    ] == 'Elo'
                  "
                  src="@/assets/images/elo.png"
                  alt="img"
                  style="height: 15px"
                />

                <img
                  v-if="
                    tempContract[
                      `payment_methods_credit_card_client_flag_${item.id}`
                    ] == 'Hipercard'
                  "
                  src="@/assets/images/hipercard.png"
                  alt="img"
                  style="height: 15px"
                />
              </span>
            </aCol>
            <aCol>
              <img
                v-if="
                  (tempContract.payment_methods_bank_transfer_audit_status ==
                    'Realizado' &&
                    item.type == 'bank-transfer') ||
                  (tempContract.payment_methods_custom_payment_audit_status ==
                    'Realizado' &&
                    item.type == 'custom-payment') ||
                  (tempContract.payment_methods_payment_card_audit_status ==
                    'Realizado' &&
                    item.type == 'payment-card') ||
                  (tempContract.payment_methods_billing_ticket_audit_status ==
                    'Realizado' &&
                    item.type == 'billing-ticket') ||
                  (tempContract[
                    `payment_methods_credit_card_audit_status_${item.id}`
                  ] == 'Realizado' &&
                    item.type == 'card') ||
                  (tempContract[
                    `payment_methods_payment_link_audit_status_${item.id}`
                  ] == 'Realizado' &&
                    item.type == 'payment-link')
                "
                src="@/assets/images/dashboard/sales/check.png"
                alt="check"
                width="16"
              />
              <a-icon
                class="ml-10 relative"
                type="caret-down"
                style="top: 3px"
              />
            </aCol>
          </aRow>
        </div>

        <div v-show="active == index">
          <CreditCardPaymentDetails
            v-if="item.type == 'card'"
            :tempContract="tempContract"
            :sale="sale"
            :index="index"
            :form="form"
            :item="item"
            @updateTempContract="updateTempContract"
          />

          <ToPayBankTransferSection
            v-if="item.type === 'bank-transfer'"
            :tempContract="tempContract"
            :sale="sale"
            :form="form"
            :index="index"
            :item="item"
            @updateTempContract="updateTempContract"
          />

          <template v-if="item.type === 'custom-payment'">
            <CustomPaymentDetails
              v-if="!item.id"
              :tempContract="tempContract"
              :sale="sale"
              :form="form"
              :index="index"
              :item="item"
              @updateTempContract="updateTempContract"
            />
            <CustomPaymentDetailsCollapse
              v-if="item.id"
              :tempContract="tempContract"
              :sale="sale"
              :form="form"
              :index="index"
              :item="item"
              @updateTempContract="updateTempContract"
            />
          </template>

          <BillingTicketPaymentDetails
            v-if="item.type === 'billing-ticket'"
            :tempContract="tempContract"
            :sale="sale"
            :form="form"
            :index="index"
            :item="item"
            @updateTempContract="updateTempContract"
          />

          <PaymentCardDetails
            v-if="item.type == 'payment-card'"
            :tempContract="tempContract"
            :sale="sale"
            :form="form"
            :index="index"
            :item="item"
            @updateTempContract="updateTempContract"
          />

          <PaymentLinkDetails
            v-if="item.type == 'payment-link'"
            :tempContract="tempContract"
            :sale="sale"
            :form="form"
            :index="index"
            :item="item"
            @updateTempContract="updateTempContract"
          />
        </div>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

import CreditCardPaymentDetails from "../collapse/CreditCardPaymentDetails.vue";
import PaymentCardDetails from "../collapse/PaymentCardDetails.vue";
import CustomPaymentDetails from "../collapse/CustomPaymentDetails.vue";
import BillingTicketPaymentDetails from "../collapse/BillingTicketPaymentDetails.vue";
import ToPayBankTransferSection from "../sections/ToPayBankTransferSection.vue";
import CustomPaymentDetailsCollapse from "../collapse/CustomPaymentDetailsCollapse.vue";
import PaymentLinkDetails from "../collapse/PaymentLinkDetails.vue";

export default {
  components: {
    CreditCardPaymentDetails,
    PaymentCardDetails,
    CustomPaymentDetails,
    BillingTicketPaymentDetails,
    ToPayBankTransferSection,
    CustomPaymentDetailsCollapse,
    PaymentLinkDetails,
  },
  props: {
    tempContract: Object,
    sale: Object,
    form: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      active: 0,
      showPasswordModal: false,
      authCode: "",
      getCardDataLoading: false,
      userPassword: null,
      installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      moneyToReceiveList: [],
    };
  },
  created() {
    let payments = JSON.parse(this.tempContract.payment_methods);

    if (payments.includes("credit-card")) {
      if (this.tempContract["credit_card_rows"]) {
        JSON.parse(this.tempContract["credit_card_rows"]).forEach((card) => {
          this.moneyToReceiveList.push({
            type: "card",
            name: "Cartão de Crédito",
            id: card.id,
            number:
              this.tempContract[
                `payment_methods_credit_card_client_number_${card.id}`
              ] != undefined
                ? this.tempContract[
                    `payment_methods_credit_card_client_number_${card.id}`
                  ].substr(
                    this.tempContract[
                      `payment_methods_credit_card_client_number_${card.id}`
                    ].length - 4
                  )
                : "xxxx",
          });
        });
      }
    }

    if (payments.includes("billing-ticket")) {
      this.moneyToReceiveList.push({
        type: "billing-ticket",
        name: "Boleto Bancário",
        id: "",
      });
    }

    if (payments.includes("payment-link")) {
      if (this.tempContract["payment_link_rows"]) {
        JSON.parse(this.tempContract["payment_link_rows"]).forEach((link) => {
          this.moneyToReceiveList.push({
            type: "payment-link",
            name: "Link de Pagamento",
            id: link.id,
          });
        });
      }
    }

    if (payments.includes("payment-card")) {
      this.moneyToReceiveList.push({
        type: "payment-card",
        name: "Carta de Crédito",
        id: "",
      });
    }

    if (payments.includes("bank-transfer")) {
      if (this.tempContract["bank_transfer_rows"] !== undefined) {
        JSON.parse(this.tempContract["bank_transfer_rows"]).forEach(
          ({ id }) => {
            this.moneyToReceiveList.push({
              id,
              type: "bank-transfer",
              name: "Transferência Bancária",
              bank:
                this.tempContract[`payment_methods_bank_transfer_bank_${id}`] ??
                "",
            });
          }
        );
      } else {
        this.moneyToReceiveList.push({
          type: "bank-transfer",
          name: "Transferência Bancária",
          id: "",
        });
      }
    }

    if (payments.includes("custom-payment")) {
      if (this.tempContract["custom_payment_rows"] !== undefined) {
        JSON.parse(this.tempContract["custom_payment_rows"]).forEach(
          ({ id }) => {
            this.moneyToReceiveList.push({
              id,
              type: "custom-payment",
              name:
                this.tempContract[
                  `payment_methods_custom_payment_name_${id}`
                ] ?? "Personalizado",
            });
          }
        );
      } else {
        this.moneyToReceiveList.push({
          type: "custom-payment",
          name: `${this.tempContract.payment_methods_custom_payment_name}*`,
          id: "",
        });
      }
    }
  },
  mounted() {
    if (this.tempContract.sale_receive_active_accordeon != undefined)
      this.active = parseInt(this.tempContract.sale_receive_active_accordeon);
  },
  methods: {
    updateTempContract(newTempContract) {
      this.tempContract = newTempContract;
      setTimeout(() => {
        this.$emit("updateTempContract", newTempContract);
      }, 20);
    },
    activeAccordeon(current) {
      console.log(current);
      if (current == this.active) {
        this.active = -1;
      } else {
        this.active = current;
      }
    },
    editRow(index, type) {
      if (type == "edit") this.moneyToReceiveList[index].edit = true;
      if (type == "cancel") this.moneyToReceiveList[index].edit = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-row
  .content
    padding: 20px 20px 0
    .edit
      background: #f27935
      border-color: #f27935
      padding: 5px 10px
    .cancel
      background: #59595b
      border-color: #59595b
      padding: 5px 10px
    .update
      background: #2ecc71
      border-color: #2ecc71
      padding: 5px 10px
    .save
      background: #00acec
      border-color: #00acec
      padding: 0 6px
  .head
    text-transform: capitalize
    background: #929496
    padding: 5px 8px
    border-radius: 5px
    margin-bottom: 5px
    color: #FFF
    cursor: pointer
    &.done
      background: #2ecc71
</style>
