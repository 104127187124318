export default {
  data() {
    return {
      response: [],
      sale: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        tempContract: {},
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          users: {
            list: [],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Finalizado",
                value: "Finalizado",
              },
              {
                label: "Cancelado",
                value: "Cancelado",
              },
              {
                label: "Revisão",
                value: "Revisão",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          supplier: "",
          id: "",
          contractId: "",
          searchTerm: "",
          customer: "",
          available_miles: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "sale_date",
        },
        meta: {},
      },
      saleRate: 0,
      loadingNewSale: false,
      createSale: false,
      salesTableCollumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 66,
          sorter: true,
        },

        {
          title: "#",
          key: "contract",
          width: 70,
          scopedSlots: {
            customRender: "contract",
          },
        },
        {
          title: "Data Venda",
          dataIndex: "sale_date",
          key: "sale_date",
          width: 110,
          sorter: true,
        },
        {
          title: "Cliente",
          key: "customer",
          width: 170,
          scopedSlots: {
            customRender: "customer",
          },
        },

        {
          title: "Venda",
          key: "value",
          dataIndex: "value",
          width: 120,
          sorter: true,
          scopedSlots: {
            customRender: "value",
          },
        },
        {
          title: "A pagar",
          key: "value_to_pay",
          dataIndex: "value_to_pay",
          class: "red",
          sorter: true,
          width: 120,
          scopedSlots: {
            customRender: "value_to_pay",
          },
        },
        {
          title: "A receber",
          key: "value_to_receive",
          dataIndex: "value_to_receive",
          class: "cgreen",
          sorter: true,
          width: 120,
          scopedSlots: {
            customRender: "value_to_receive",
          },
        },
        {
          title: "Taxas",
          key: "taxes_value",
          dataIndex: "taxes_value",
          width: 110,
          sorter: true,
          class: "corange",
          scopedSlots: {
            customRender: "taxes_value",
          },
        },
        {
          title: "Embarque",
          key: "embark_taxes_value",
          dataIndex: "embark_taxes_value",
          width: 110,
          sorter: true,
          class: "",
          scopedSlots: {
            customRender: "embark_taxes_value",
          },
        },
        {
          title: "Lucratividade",
          key: "profit_value",
          class: "cgreen",
          sorter: true,
          dataIndex: "profit_value",
          width: 140,
          scopedSlots: {
            customRender: "profit_value",
          },
        },

        {
          title: "Filial",
          key: "company_branch",
          width: 120,
          scopedSlots: {
            customRender: "company_branch",
          },
        },

        {
          title: "",
          key: "user",
          width: 60,
          scopedSlots: {
            customRender: "user",
          },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          width: 220,
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  methods: {
    changeSalesPage(current) {
      this.sale.pagination.page = current;
      this.getSales(current);
    },
    changeSalesPageSize(current, pageSize) {
      this.sale.pagination.page = current;
      this.sale.pagination.perPage = pageSize;
      this.getSales();
    },
    saleTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.sale.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.sale.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getSales();
    },
    onSaleSelected(id) {
      this.sale.details = null;
      this.createSale = false;
      this.getSale(id);
    },
    async getSaleById(id) {
      try {
        const data = await this.$http.post(`/sale/details?id=${id}`);
        return data;
      } catch (e) {
        e;
      }
    },
    getSale(id) {
      this.$http.post(`/sale/details?id=${id}`).then(({ data }) => {
        this.loadingNewSale = false;
        this.sale.details = data;

        this.sale.details.rate = parseInt(this.sale.details.rate);

        document.title = `VENDA ${data.id} - ${data.customer.first_name}  ${data.customer.last_name} `;

        // CONTRATO TEMPORARIO
        this.sale.tempContract = {
          ...data.contract,
          package_type: data.contract.package_type
            ? data.contract.package_type
            : "Serviços",
          the_customer: data.customer,
        };

        let allFieldsSize = Object.values(data.contract.meta).length,
          values = Object.values(data.contract.meta),
          keys = Object.keys(data.contract.meta);

        for (let i = 0; i < allFieldsSize; i++) {
          this.sale.tempContract[keys[i]] = values[i];
        }

        delete this.sale.tempContract.meta;

        this.saleForm.setFieldsValue(data.raw);
      });
    },
    getSales(samePage) {
      this.sale.loading = true;

      let filters = "";
      let queryParams = new URLSearchParams("");

      history.pushState(null, null, "");

      if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.sale.filters.users.selected.length > 0) {
          filters += `&user_id=${this.sale.filters.users.selected}`;
        }

        if (!this.sale.filters.id) {
          if (this.sale.filters.status.selected.length > 0) {
            filters += `&status=${this.sale.filters.status.selected}`;

            queryParams.set("status", `${this.sale.filters.status.selected}`);
          }

          if (this.sale.filters.customer) {
            filters += `&customer-id=${this.sale.filters.customer}`;
          }

          if (this.sale.filters.period.selected.length > 0) {
            filters += `&period=${this.sale.filters.period.selected[0]}|${this.sale.filters.period.selected[1]}`;
            queryParams.set(
              "period",
              `${this.sale.filters.period.selected[0]}|${this.sale.filters.period.selected[1]}`
            );
          }
        } else {
          filters += `&id=${this.sale.filters.id}`;

          queryParams.set("id", `${this.sale.filters.id}`);
        }

        if (this.sale.filters.contractId) {
          filters += `&contract-id=${this.sale.filters.contractId}`;
          filters += `&period=`;
        }

        if (this.sale.filters.searchTerm) {
          filters += `&s=${this.sale.filters.searchTerm}`;
        }
      }

      filters += `&order=${this.sale.filters.order}&order-by=${this.sale.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      this.$http
        .get(
          `/sale/list?page=${this.sale.pagination.page}&per_page=${this.sale.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.sale.pagination.page = 1;
          }
          this.sale.pagination.total = data.meta.total;
          this.sale.pagination.totalPages = data.meta.total_pages;

          this.sale.list = data.data;
          this.sale.meta = data.meta;

          this.excelFile.data = data.data;
          this.sale.loading = false;
        })
        .catch(({ response }) => {
          this.sale.pagination.total = response.data.meta.total;
          this.sale.pagination.totalPages = response.data.meta.total_pages;

          this.sale.list = [];
          this.sale.loading = false;
        });
    },
  },
};
