<template>
  <div>
    <a-form :form="form" @submit="submitContract">
      <a-row v-if="item.type == 'custom-payment'" class="content" :gutter="20">
        <a-col :span="21">
          <a-row>
            <a-col :span="3">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Valor </label>

                <a-input
                  class="travel-input"
                  placeholder="Valor"
                  v-currency
                  :disabled="!item.edit"
                  v-decorator="[
                    `payment_methods_custom_payment_total_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha o valor.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>

        <a-col v-if="sale.status != 'Finalizado'" class="a-right" :span="3">
          <a-popconfirm
            placement="left"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="confirmAuditory()"
          >
            <template slot="title">
              <p>
                Você confirma todos os valores e dados
                <br />inseridos neste recebimento? Após esta ação
                <br />
                não será mais possível editar.
              </p>
            </template>
            <a-button
              v-if="!edit"
              html-type="submit"
              class="ml-10 update"
              type="primary"
            >
              <a-icon type="check" />
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
export default {
  props: {
    tempContract: Object,
    sale: Object,
    item: Object,
    index: Number,
  },
  mixins: [formatThings],
  data() {
    return {
      edit: false,
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
    };
  },
  mounted() {
    let payments = JSON.parse(this.tempContract.payment_methods);

    if (payments.includes("custom-payment")) {
      this.form.setFieldsValue({
        [`payment_methods_custom_payment_total_value`]:
          this.tempContract[`payment_methods_custom_payment_total_value`],
      });
    }
  },
  methods: {
    editRow(type) {
      if (type == "edit") {
        this.edit = true;
      }

      if (type == "cancel") {
        this.edit = false;
      }
    },
    confirmAuditory() {
      let values = {};
      values.payment_methods_custom_payment_audit_status = "Realizado";
      values.payment_methods_custom_payment_audit_user_id =
        this.$store.state.userData.id;
      values.id = this.tempContract.id;
      values.sale_id = this.sale.id;
      values.module = "sale";
      values.action = "update-contract-custom-payment-status-in-sale";
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.tempContract["payment_methods_custom_payment_audit_status"] =
        "Realizado";
      this.tempContract["payment_methods_custom_payment_audit_user_id"] =
        this.$store.state.userData.id;

      values["sale_receive_active_accordeon"] = this.index;

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);
          this.edit = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.content
  padding: 20px 20px 0
  .edit
    background: #f27935
    border-color: #f27935
    padding: 5px 10px
  .cancel
    background: #59595b
    border-color: #59595b
    padding: 5px 10px
  .update
    background: #2ecc71
    border-color: #2ecc71
    padding: 5px 10px
  .save
    background: #00acec
    border-color: #00acec
    padding: 0 6px
</style>
