<template>
  <div>
    <aRow class="content-row" :gutter="20">
      <aCol :span="8">
        <h3 class="t1">RECEBIMENTO</h3>
        <div class="list">
          <div v-for="(payment, index) in paymentsList().arr" :key="index">
            <aRow class="p-row" type="flex" justify="space-between">
              <aCol class="name">
                <span class="num">#{{ index + 1 }}</span>
                {{ payment.name }}
              </aCol>
              <aCol class="val">
                {{ formatPricePtBr(payment.value) }}
              </aCol>
            </aRow>

            <aRow
              class="p-row sub"
              type="flex"
              justify="space-between"
              v-for="(subPayment, index) in payment.sub"
              :key="index"
            >
              <aCol class="name">
                {{ subPayment.name }}
              </aCol>
              <aCol class="val">
                {{ formatPricePtBr(subPayment.value) }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>

      <aCol :span="8">
        <h3 class="t2">A PAGAR</h3>
        <div class="list">
          <div v-for="(payment, index) in toPayList()" :key="index">
            <aRow class="p-row" type="flex" justify="space-between">
              <aCol class="name">
                <span class="num">#{{ index + 1 }}</span>
                {{ payment.name }}
              </aCol>
              <aCol class="val">
                {{ formatPricePtBr(payment.value) }}
              </aCol>
            </aRow>

            <aRow
              class="p-row sub"
              type="flex"
              justify="space-between"
              v-for="(subPayment, index) in payment.sub"
              :key="index"
            >
              <aCol class="name">
                {{ subPayment.name }}
              </aCol>
              <aCol class="val">
                {{ formatPricePtBr(subPayment.value) }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>

      <aCol :span="8">
        <h3 class="t3">LUCRATIVIDADE</h3>
        <div class="list">
          <div v-for="(payment, index) in profitList()" :key="index">
            <aRow class="p-row" type="flex" justify="space-between">
              <aCol class="name">
                <span class="num">#{{ index + 1 }}</span>
                {{ payment.name }}
              </aCol>
              <aCol class="val">
                {{ formatPricePtBr(payment.value) }}
              </aCol>
            </aRow>

            <aRow
              class="p-row sub"
              type="flex"
              justify="space-between"
              v-for="(subPayment, index) in payment.sub"
              :key="index"
            >
              <aCol class="name">
                {{ subPayment.name }}
              </aCol>
              <aCol class="val">
                {{ formatPricePtBr(subPayment.value) }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>
    </aRow>

    <a-divider />

    <aRow
      v-if="tempContract.the_customer != undefined"
      class="content-row"
      :gutter="20"
    >
      <aCol :span="8">
        <h3>CONTRATANTE</h3>
        <div class="list mt-10">
          <aRow class="p-row no-dot">
            <aCol v-if="tempContract" :span="24">
              <span class="ico"
                ><img src="@/assets/images/dashboard/sales/user.png" alt="ico"
              /></span>
              <span
                v-if="tempContract.the_customer.person_type == 'Pessoa Física'"
              >
                {{ tempContract.the_customer.id }} -
                {{ tempContract.the_customer.first_name }}
                {{ tempContract.the_customer.last_name }}
              </span>
              <span v-else>
                {{ tempContract.the_customer.id }} -
                {{ tempContract.the_customer.trading_name }}
              </span>
            </aCol>

            <aCol :span="24">
              <span class="ico"
                ><img
                  src="@/assets/images/dashboard/sales/smartphone.png"
                  alt="ico"
              /></span>
              {{ tempContract.the_customer.mobile_phone }}
              {{
                tempContract.the_customer.phone
                  ? " - " + tempContract.the_customer.phone
                  : ""
              }}
            </aCol>

            <aCol :span="24">
              <span class="ico"
                ><img src="@/assets/images/dashboard/sales/email.png" alt="ico"
              /></span>
              {{ tempContract.the_customer.email }}
            </aCol>

            <aCol class="mt-10" :span="24">
              <div><b>Origem:</b> {{ tempContract.customer_origin }}</div>
              <div v-if="tempContract.customer_origin === 'Indicação'">
                <b>Indicado por:</b>
                {{ tempContract.customer_who_referred_name ?? "Não informado" }}
              </div>
            </aCol>
          </aRow>
          <h3 class="mt-40">EMPRESA / FILIAL</h3>

          <div class="f11 mb-5">
            <strong>Empresa:</strong>
            {{ sale.company.company_name }} (ID {{ sale.company.id }})
          </div>
          <div class="f11">
            <strong>Filial:</strong>
            {{ sale.company_branch.name }} (ID {{ sale.company_branch.id }})
          </div>
        </div>
      </aCol>

      <aCol :span="8">
        <h3>PASSAGEIROS</h3>
        <div class="list mt-10">
          <aRow
            v-for="(passenger, index) in passengersList()"
            :key="index"
            class="p-row no-dot"
          >
            <aCol class="upper" :span="24">
              <span class="ico"
                ><img src="@/assets/images/dashboard/sales/user.png" alt="ico"
              /></span>
              {{ passenger.name }}
            </aCol>
          </aRow>
        </div>
      </aCol>

      <aCol :span="8">
        <h3>PRODUTOS</h3>
        <div class="list">
          <div class="mt-5 f12">
            <b>Tipo de venda:</b>
            {{
              tempContract.package_type ? tempContract.package_type : "Serviços"
            }}
          </div>
          <div
            v-for="(item, index) in productsList()"
            :key="index"
            class="mb-10"
          >
            <aRow class="p-row no-dot" type="flex" justify="space-between">
              <aCol class="name" style="font-weight: 600">
                <span class="num">#{{ index + 1 }}</span>
                {{ item.name }}
              </aCol>
            </aRow>

            <aRow
              class="p-row sub no-dot"
              type="flex"
              justify="space-between"
              v-for="(subItem, index) in item.sub"
              :key="index"
            >
              <aCol class="name upper" :class="subItem.class">
                {{ subItem.name }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>
    </aRow>

    <!-- <pre>{{ tempContract }}</pre> -->
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings.js";

export default {
  props: {
    tempContract: Object,
    sale: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  methods: {
    paymentsList() {
      const paymentMethods =
        this.tempContract.payment_methods != undefined
          ? JSON.parse(this.tempContract.payment_methods)
          : [];
      let arr = [],
        totalTaxes = 0;

      if (paymentMethods.includes("billing-ticket")) {
        totalTaxes += parse(
          this.tempContract["payment_methods_billing_ticket_tax_value"]
        );
        arr.push({
          name: `Boleto Bancário ${this.tempContract["payment_methods_billing_ticket_installments"]}x`,
          value: parse(
            this.tempContract["payment_methods_billing_ticket_total_value"]
          ),
          sub: [
            {
              name: `Custo financeiro`,
              value: parse(
                this.tempContract["payment_methods_billing_ticket_tax_value"]
              ),
            },
          ],
        });
      }

      if (paymentMethods.includes("custom-payment")) {
        const customPaymentRows =
          this.tempContract.custom_payment_rows !== undefined
            ? JSON.parse(this.tempContract.custom_payment_rows)
            : [];

        if (customPaymentRows.length) {
          customPaymentRows.forEach(({ id }) => {
            arr.push({
              name: `${
                this.tempContract[`payment_methods_custom_payment_name_${id}`]
              }`.toUpperCase(),
              value: parse(
                this.tempContract[
                  `payment_methods_custom_payment_total_value_${id}`
                ]
              ),
            });
          });
        } else {
          arr.push({
            name: `${this.tempContract["payment_methods_custom_payment_name"]}*`,
            value: parse(
              this.tempContract["payment_methods_custom_payment_total_value"]
            ),
            sub: [],
          });
        }
      }

      if (paymentMethods.includes("payment-card")) {
        arr.push({
          name: `Carta de Crédito`,
          value: parse(
            this.tempContract["payment_methods_payment_card_total_value"]
          ),
          sub: [],
        });
      }

      if (paymentMethods.includes("bank-transfer")) {
        arr.push({
          name: `Transferência bancária`,
          value: parse(
            this.tempContract["payment_methods_bank_transfer_total_value"]
          ),
          sub: [],
        });
      }

      if (paymentMethods.includes("credit-card")) {
        JSON.parse(this.tempContract.credit_card_rows).forEach((card) => {
          let subArr = [];

          if (
            this.tempContract[
              "payment_methods_credit_card_machine_id_" + card.id
            ] == 14
          ) {
            subArr.push({
              name: `Direto com o fornecedor`,
              value: 0,
            });
          } else {
            subArr.push({
              name: `Custo financeiro`,
              value: parse(
                this.tempContract[
                  "payment_methods_credit_card_total_tax_discount_" + card.id
                ]
              ),
            });

            totalTaxes += parse(
              this.tempContract[
                "payment_methods_credit_card_total_tax_discount_" + card.id
              ]
            );
          }

          arr.push({
            name: `Cartão de Crédito ${
              this.tempContract[
                "payment_methods_credit_card_installments_" + card.id
              ]
                ? this.tempContract[
                    "payment_methods_credit_card_installments_" + card.id
                  ] + "x"
                : ""
            }`,
            value: parse(
              this.tempContract[
                "payment_methods_credit_card_total_value_" + card.id
              ]
            ),
            sub: subArr,
          });
        });
      }

      if (paymentMethods.includes("payment-link")) {
        JSON.parse(this.tempContract.payment_link_rows).forEach((link) => {
          let subArr = [];

          // if (
          //   this.tempContract[
          //     "payment_methods_credit_card_machine_id_" + card.id
          //   ] == 14
          // ) {
          //   subArr.push({
          //     name: `Direto com o fornecedor`,
          //     value: 0,
          //   });
          // } else {
          //   subArr.push({
          //     name: `Custo financeiro`,
          //     value: parse(
          //       this.tempContract[
          //         "payment_methods_credit_card_total_tax_discount_" + card.id
          //       ]
          //     ),
          //   });

          //   totalTaxes += parse(
          //     this.tempContract[
          //       "payment_methods_credit_card_total_tax_discount_" + card.id
          //     ]
          //   );
          // }

          arr.push({
            name: `Link de Pagamento ${
              this.tempContract[
                "payment_methods_payment_link_installments_" + link.id
              ]
                ? this.tempContract[
                    "payment_methods_payment_link_installments_" + link.id
                  ] + "x"
                : ""
            }`,
            value: parse(
              this.tempContract[
                "payment_methods_payment_link_total_value_" + link.id
              ]
            ),
            sub: subArr,
          });
        });
      }

      return { arr, totalTaxes };
    },
    passengersList() {
      let arr = [],
        travellers =
          this.tempContract.travellers_rows != undefined
            ? JSON.parse(this.tempContract.travellers_rows)
            : [];
      travellers.forEach((traveller) => {
        traveller;
        arr.push({
          name: `${this.tempContract[`traveller_${traveller.id}_first_name`]} ${
            this.tempContract[`traveller_${traveller.id}_last_name`]
          }`,
        });
      });

      return arr;
    },
    profitList() {
      let arr = [],
        totalTaxes = 0;
      const contractedServices =
          this.tempContract.contracted_services != undefined
            ? JSON.parse(this.tempContract.contracted_services)
            : [],
        packageType = this.tempContract.package_type,
        paymentMethods =
          this.tempContract.payment_methods != undefined
            ? JSON.parse(this.tempContract.payment_methods)
            : [],
        marketingTypes =
          this.tempContract[`marketing_types`] != undefined && ""
            ? JSON.parse(this.tempContract[`marketing_types`])
            : [];

      if (packageType == "Pacote Completo Terceiros") {
        arr.push({
          name: `Pacote Completo Terceiros`,
          value:
            parse(this.tempContract[`package_total_price`]) -
            parse(this.tempContract[`contract_finances_package_net_value`]),
          sub: [],
        });
      } else {
        if (contractedServices.includes("hotel")) {
          JSON.parse(this.tempContract["hotel_rows"]).forEach((hotel) => {
            arr.push({
              name: `Hospedagem ${hotel.id}`,
              value:
                parse(this.tempContract[`hotel_${hotel.id}_total_price`]) -
                parse(
                  this.tempContract[
                    `contract_finances_hotel_${hotel.id}_net_value`
                  ]
                ),
              sub: [],
            });
          });
        }

        if (contractedServices.includes("flight")) {
          JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
            (flight) => {
              let totalReceived = 0;
              let totalToPayFlight = 0;

              flight.travellers.forEach((traveller) => {
                if (
                  this.tempContract[`flight_${flight.id}_type`] == "Regular"
                ) {
                  totalReceived += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ]
                  );

                  totalToPayFlight += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ]
                  );

                  totalReceived += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes`
                    ]
                  );

                  totalToPayFlight += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes`
                    ]
                  );

                  totalReceived += this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                  ]
                    ? parse(
                        this.tempContract[
                          `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                        ]
                      )
                    : 0;

                  totalReceived += this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                  ]
                    ? parse(
                        this.tempContract[
                          `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                        ]
                      )
                    : 0;
                }

                if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
                  totalReceived += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ]
                  );

                  if (
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ] == "EUR" ||
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ] == "USD"
                  ) {
                    totalReceived +=
                      parse(
                        this.tempContract[
                          `flight_${flight.id}_traveller_${traveller.id}_taxes`
                        ]
                      ) * parse(this.tempContract["voucher_payments_exchange"]);

                    totalTaxes +=
                      parse(
                        this.tempContract[
                          `flight_${flight.id}_traveller_${traveller.id}_taxes`
                        ]
                      ) * parse(this.tempContract["voucher_payments_exchange"]);
                  } else {
                    totalReceived += parse(
                      this.tempContract[
                        `flight_${flight.id}_traveller_${traveller.id}_taxes`
                      ]
                    );
                  }
                }
              });

              if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
                totalToPayFlight +=
                  this.tempContract[
                    `contract_finances_flight_${flight.id}_sale_value`
                  ];

                //totalReceived
              }

              // if (
              //     this.tempContract[
              //         `contract_finances_flight_${flight.id}_incentives_rows`
              //     ] != undefined
              // ) {
              //     JSON.parse(
              //         this.tempContract[
              //             `contract_finances_flight_${flight.id}_incentives_rows`
              //         ]
              //     ).forEach((incentive) => {
              //         if (
              //             this.tempContract[
              //                 `contract_finances_flight_${flight.id}_incentive_${incentive.id}_type`
              //             ] == "%"
              //         ) {
              //             totalFlight += parseFloat(
              //                 this.tempContract[
              //                     `contract_finances_flight_${flight.id}_incentive_${incentive.id}_percentage_value`
              //                 ]
              //             );
              //         }

              //         totalFlight += parse(
              //             this.tempContract[
              //                 `contract_finances_flight_${flight.id}_incentive_${incentive.id}_value`
              //             ]
              //         );f
              //     });
              // }

              totalTaxes;

              arr.push({
                name: `Aéreo ${flight.id} ${
                  this.tempContract[`flight_${flight.id}_type`]
                }`,
                value: totalReceived - totalToPayFlight - totalTaxes,
                sub: [],
              });
            }
          );
        }

        if (contractedServices.includes("service")) {
          JSON.parse(this.tempContract["service_rows"]).forEach((service) => {
            arr.push({
              name: `Serviço ${service.id}`,
              value:
                parse(this.tempContract[`service_${service.id}_total_price`]) -
                parse(
                  this.tempContract[
                    `contract_finances_service_${service.id}_net_value`
                  ]
                ),
              sub: [],
            });
          });
        }
      }

      if (marketingTypes.length > 0) {
        if (marketingTypes.includes("Livelo")) {
          if (
            this.tempContract.livelo_subtract_fees_in_profit == 1 ||
            this.tempContract.livelo_subtract_fees_in_profit == true
          ) {
            arr.push({
              name: `Custo Livelo`,
              value: this.tempContract[`livelo_points_total_fee`],
              sub: [],
            });
          }
        }
      }

      if (paymentMethods.includes("payment-card")) {
        let paymentCard = this.tempContract
          .payment_methods_payment_card_contract_profit_discount_value
          ? this.tempContract
              .payment_methods_payment_card_contract_profit_discount_value
          : this.tempContract[
              `payment_methods_payment_card_total_profit_value`
            ];
        arr.push({
          name: `Lucro Carta de Crédito`,
          value: -parse(paymentCard),
          sub: [],
        });
      }

      if (this.paymentsList().totalTaxes > 0) {
        arr.push({
          name: `Custo Financeiro`,
          value: -this.paymentsList().totalTaxes,
          sub: [],
        });
      }

      return arr;
    },
    productsList() {
      let arr = [];
      let contractedServices =
        this.tempContract.contracted_services != undefined
          ? JSON.parse(this.tempContract.contracted_services)
          : [];

      if (contractedServices.includes("hotel")) {
        let allHotels = [];
        JSON.parse(this.tempContract["hotel_rows"]).forEach((hotel) => {
          allHotels.push({
            name: this.tempContract[`hotel_${hotel.id}_name`],
            class: "hotel",
          });
        });

        arr.push({
          name: `Hospedagem`,
          sub: allHotels,
        });
      }

      if (contractedServices.includes("flight")) {
        let allFlights = [];
        JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
          (flight) => {
            flight;

            flight.sections.forEach((section) => {
              allFlights.push({
                name: `Aéreo (${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_type`
                  ]
                }) - ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_class`
                  ]
                } - ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_origin`
                  ]
                } > ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_destination`
                  ]
                }`,
                class: "flight",
              });
            });
          }
        );

        arr.push({
          name: `Passagem Aérea`,
          sub: allFlights,
        });
      }

      if (contractedServices.includes("service")) {
        let allHotels = [];
        JSON.parse(this.tempContract["service_rows"]).forEach((service) => {
          allHotels.push({
            name: this.tempContract[`service_${service.id}_name`],
            class: "service",
          });
        });

        arr.push({
          name: `Serviço`,
          sub: allHotels,
        });
      }

      return arr;
    },
    toPayList() {
      let arr = [];
      const contractedServices =
          this.tempContract.contracted_services != undefined
            ? JSON.parse(this.tempContract.contracted_services)
            : [],
        packageType = this.tempContract.package_type;

      // paymentMethods = JSON.parse(this.tempContract.payment_methods);
      // paymentMethods;

      if (packageType == "Pacote Completo Terceiros") {
        arr.push({
          name: `Pacote Completo Terceiros`,
          value: this.tempContract[`contract_finances_package_net_value`],
          sub: [],
        });
      } else {
        if (contractedServices.includes("hotel")) {
          JSON.parse(this.tempContract["hotel_rows"]).forEach((hotel) => {
            arr.push({
              name: `Hospedagem ${hotel.id}`,
              value:
                this.tempContract[
                  `contract_finances_hotel_${hotel.id}_net_value`
                ],
              sub: [],
            });
          });
        }

        if (contractedServices.includes("flight")) {
          let totalFlight = 0;
          JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
            (flight) => {
              flight.travellers.forEach((traveller) => {
                //
                if (
                  this.tempContract[`flight_${flight.id}_type`] == "Regular"
                ) {
                  totalFlight += parse(
                    this.tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ]
                  );
                }
              });

              if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
                totalFlight += parseFloat(
                  this.tempContract[
                    `contract_finances_flight_${flight.id}_sale_value`
                  ]
                );
              }

              arr.push({
                name: `Aéreo ${flight.id} ${
                  this.tempContract[`flight_${flight.id}_type`]
                }`,
                value: totalFlight,
                sub: [],
              });
            }
          );
        }

        if (contractedServices.includes("service")) {
          JSON.parse(this.tempContract["service_rows"]).forEach((service) => {
            arr.push({
              name: `Serviço ${service.id}`,
              value:
                this.tempContract[
                  `contract_finances_service_${service.id}_net_value`
                ],
              sub: [],
            });
          });
        }
      }

      return arr;
    },
  },
};
</script>

<style lang="sass" scoped>

//.meta a
//	color: #333
.list
  max-height: 300px
  height: auto
  min-height: 140px
  overflow: auto
  padding-right: 5px

.content-row
  .p-row.sub
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 22px
  .p-row.no-dot
    border: 0
    .name
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name.hotel
      color: #00c100
      font-weight: 600
    .name.service
      color: #ff8000
      font-weight: 600
    .name.flight
      font-weight: 600
      color: #1890ff
  .p-row
    font-size: 12px
    border-bottom: 2px dotted #ccc
    margin: 4px
    .val
      top: 8px
      background: #FFF
      padding: 0 0px 0 5px
    .ico
      width: 25px
      text-align: center
      display: inline-block
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 0px
      max-width: 220px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      .num
        font-weight: 700
  padding: 10px
  .t1
    color: #00cd6b
  .t2
    color: #ff4545
  .t3
    color: #ff9a3e
  h3
    padding: 6px 0
    font-size: 12px
    font-weight: 600
    border-bottom: 1px solid #ececec
</style>
