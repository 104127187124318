var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.voucherPaymentsMethod),function(payment,index){return _c('a-row',{key:index,staticClass:"taxes-payment"},[(payment == 'credit-card')?_c('a-col',{staticClass:"credit-card tax-type",attrs:{"span":24}},[_c('a-row',[_c('a-col',{staticClass:"title",attrs:{"span":24}},[_c('a-icon',{staticClass:"mr-5",attrs:{"type":"credit-card"}}),_vm._v(" Cartão de Crédito\n        ")],1),_vm._v(" "),_c('a-col',{staticClass:"content",attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":10}},[_vm._v("\n              "+_vm._s(_vm.getCreditCardData(
                  _vm.tempContract["voucher_payments_credit_card_id"]
                ))+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_vm._v("\n              Valor ("+_vm._s(_vm.milesTaxesPayments.currency)+"):\n              "),_c('font',{staticClass:"txt"},[_vm._v("\n                "+_vm._s(_vm.formatCurrency(_vm.milesTaxesPayments.currency))+"\n                "+_vm._s(_vm.tempContract["voucher_payments_credit_card_value"]))])],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_vm._v("\n              Valor debitado em reais:\n              "),_c('font',{staticClass:"txt"},[_vm._v(_vm._s(_vm.formatAnyPricePtBr(
                  _vm.tempContract["voucher_payments_credit_card_value_brl"]
                )))])],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),(payment == 'voucher-payment')?_c('a-col',{staticClass:"voucher tax-type",attrs:{"span":24}},[_c('a-row',[_c('a-col',{staticClass:"title",attrs:{"span":24}},[_c('a-icon',{staticClass:"mr-5",attrs:{"type":"solution"}}),_vm._v(" Voucher\n        ")],1),_vm._v(" "),_c('a-col',{staticClass:"content",attrs:{"span":24}},_vm._l((_vm.voucherRows),function(voucher,index){return _c('a-row',{key:index},[_c('a-col',{attrs:{"span":6}},[_c('b',[_vm._v(_vm._s(voucher.id)+" - ")]),_vm._v("\n              "+_vm._s(_vm.tempContract[`voucher_payments_voucher_${voucher.id}_code`])+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_vm._v("\n              Valor utilizado:\n              "),_c('font',{staticClass:"txt"},[_vm._v("\n                "+_vm._s(_vm.formatCurrency(_vm.milesTaxesPayments.currency))+"\n                "+_vm._s(_vm.tempContract[`voucher_payments_voucher_${voucher.id}_value`])+"\n              ")])],1)],1)}),1)],1)],1):_vm._e()],1)}),_vm._v(" "),(
      _vm.tempContract[`contract_finances_flight_miles_taxes_audit_status`] !=
      'Realizado'
    )?_c('a-row',{staticClass:"payment-row"},[_c('a-col',{staticClass:"mt-20 a-right"},[_c('a-popconfirm',{attrs:{"placement":"left","ok-text":"Sim","cancel-text":"Não"},on:{"confirm":function($event){return _vm.confirmAuditory()}}},[_c('template',{slot:"title"},[_c('p',[_vm._v("\n            Você confirma todos os valores e dados\n            "),_c('br'),_vm._v("inseridos neste pagamento? Após esta ação\n            "),_c('br'),_vm._v("\n            não será mais possível editar.\n          ")])]),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 update",attrs:{"html-type":"submit","type":"primary"}},[_c('a-icon',{attrs:{"type":"check"}})],1):_vm._e()],2)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }